import { selectFilter, textFilter } from "react-bootstrap-table2-filter";

export const realtorMatchFields = [
  { name: "agent_id", label: "Agent Id" },
  { name: "match_email_address", label: "Match Email Address" },
  { name: "domain", label: "Domain", disable: false },
  { name: "provider_company_name", label: "Company Name*" },
];

export const realtorDetaifields = [
  { name: "contact_person_name", label: "Contact Person Name*" },
  { name: "total_offer", label: "Total Offers", disable: true },
  { name: "active_offer", label: "Active Offers", disable: true },
  { name: "email_address", label: "Email Address*" },
  { name: "phone", label: "Phone*" },
  { name: "postal_code", label: "Postal Code*" },
  { name: "realtor_city", label: "Realtor City*" },
  { name: "street", label: "Street*" },
  { name: "website_url", label: "Website*" },
  { name: "privacy_link", label: "Privacy*" },
  { name: "imprint", label: "Imprint*" },
  // { name: "adr_city", label: "City*" },
];

export const headerStyle = () => ({
  textAlign: "center",
  verticalAlign: "top",
});

export const realtorType = {
  SINGLE: "SINGLE",
  MULTIPLE: "MULTIPLE",
};

// const cityFilter = [
//   { value: "Hamburg", label: "Hamburg" },
//   { value: "Berlin", label: "Berlin" },
//   { value: "Hannover", label: "Hannover" },
//   { value: "Leipzig", label: "Leipzig" },
// ];

export const stateFilter = [
  { value: "Enabled", label: "Enabled" },
  { value: "Disabled", label: "Disabled" },
];

// const realtortypeFilter = [
//   { value: realtorType.SINGLE, label: "SINGLE" },
//   { value: realtorType.MULTIPLE, label: "MULTIPLE" },
// ];

export const dataSourceFilter = [
  { value: "OTHER", label: "Other" },
  { value: "OPENIMMO", label: "Openimmo" },
  { value: "API", label: "API" },
];

export const conatctFilter = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const partnerFilter = [
  { value: "NEW", label: "New" },
  { value: "NO_PARTNER", label: "No Partner" },
  { value: "PERMISSION", label: "Permission" },
  { value: "CONTRACT", label: "Contract" },
];

export const realtorMenuItems = [
  { name: "data_source", label: "Data Source", options: dataSourceFilter },
  { name: "partner_level", label: "Partner Level", options: partnerFilter },
  // { name: "adr_city", label: "City", options: cityFilter },
];

export const realtorTableData = [
  {
    dataField: "match_email_address",
    text: "Match Mail Address",
    headerStyle: headerStyle,
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "domain",
    text: "Domain",
    headerStyle: headerStyle,
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "provider_company_name",
    text: "Company Name",
    sort: true,
    headerStyle: headerStyle,
  },

  // {
  //   dataField: "adr_city",
  //   text: "Realtor City",
  //   sort: true,
  //   headerStyle: headerStyle,
  //   filter: selectFilter({
  //     className: "my-custom-select-filter",
  //     options: cityFilter,
  //   }),
  // },
  // {
  //   dataField: "data_source",
  //   text: "Data Source",
  //   sort: true,
  //   headerStyle: headerStyle,
  //   filter: selectFilter({
  //     className: "my-custom-select-filter",
  //     options: dataSourceFilter,
  //   }),
  // },

  // {
  //   dataField: "createddate",
  //   text: "Created Date",
  //   sort: true,
  //   headerStyle: headerStyle,
  // },
  // {
  //   dataField: "updateddate",
  //   text: "Updated Date",
  //   sort: true,
  //   headerStyle: headerStyle,
  // },
  {
    dataField: "active",
    text: "Enabled Status",
    sort: true,
    headerStyle: headerStyle,
    filter: selectFilter({
      className: "my-custom-select-filter",
      options: stateFilter,
    }),
  },
  {
    dataField: "partner_level",
    text: "Partner Level",
    sort: true,
    headerStyle: headerStyle,
    filter: selectFilter({
      className: "my-custom-select-filter",
      options: partnerFilter,
    }),
  },
];

export const sizePerPageListRealtor = [
  {
    text: "5",
    value: 5,
  },
  {
    text: "10",
    value: 10,
  },
  {
    text: "20",
    value: 20,
  },
  {
    text: "50",
    value: 50,
  },
];
