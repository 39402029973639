/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { callRequestAPI } from "../../../services/callApi";
import { getDate } from "../../../utils/tableData";
import backArrow from "../../../assets/images/whitearrow.png";
import ReactHtmlParser from "react-html-parser";

let module_id = "";

export default function GeneralMessagesList(props) {
  const [isLoading, setisLoading] = useState(true);
  const [msgList, setmsgList] = useState([]);
  const [editId, seteditId] = useState("");
  const [oldValue, setoldValue] = useState("");

  useEffect(() => {
    const { match } = props;
    module_id = match.params.id;

    msgList.length === 0 && getMessageList();
    // eslint-disable-next-line
  }, [msgList.length]);

  const getMessageList = async () => {
    const response = await callRequestAPI(
      `v1/generalmessages/modules/${module_id}`,
      null,
      "get"
    );

    if (response.status === 200 && response.data) {
      setmsgList(response.data.data);
      setisLoading(false);
    } else {
      setisLoading(false);
    }
  };

  const toggleStatus = async (list, changeType) => {
    setisLoading(true);

    const url =
      changeType === "urgent"
        ? `v1/generalmessages/status/${changeType}/${!list.urgent}/${list.id}`
        : `v1/generalmessages/status/${changeType}/${!list.active}/${list.id}`;

    const response = await callRequestAPI(url, null, "putHeader");

    if (response.status === 200) {
      // eslint-disable-next-line
      msgList.map((listItem) => {
        if (listItem.id === list.id) {
          if (changeType === "urgent") {
            listItem.urgent = !list.urgent;
          }
          if (changeType === "active") {
            listItem.active = !list.active;
          }
        }
      });
      setisLoading(false);
    } else {
      setisLoading(false);
    }
  };

  const deleteMessage = async (id) => {
    setisLoading(true);
    const response = await callRequestAPI(
      `v1/generalmessages/${id}`,
      null,
      "delete"
    );
    if (response.status === 200) {
      getMessageList();
    } else {
      setisLoading(false);
    }
  };

  const handleChange = (event) => {
    const index = event.target.id;
    const value = event.target.value;

    setmsgList(
      msgList.map((item) =>
        item.id === index ? { ...item, orderId: value } : item
      )
    );
  };

  const cancelValue = (value, ind) => {
    if (value !== oldValue) {
      setmsgList(
        msgList.map((item, index) =>
          index === ind ? { ...item, orderId: oldValue } : item
        )
      );
    }
    seteditId("");
  };

  const savePriority = async (id, value) => {
    setisLoading(true);

    const response = await callRequestAPI(
      `v1/generalmessages/order/${value}/${id}`,
      null,
      "putHeader"
    );

    if (response.status === 200) {
      getMessageList();
      setisLoading(false);
      seteditId("");
      setoldValue("");
    } else {
      setisLoading(false);
      seteditId("");
      setoldValue("");
    }
  };

  const setData = (list) => {
    seteditId(list.id);
    setoldValue(list.orderId);
  };

  return (
    <div className='pcoded-content fade-in'>
      <div className='pcoded-main-container' style={{ marginTop: "none" }}>
        <div className='pcoded-wrapper'>
          <div className='page-header card' style={{ border: "none" }}>
            <div className='row align-items-end'>
              <div className='col-lg-5'>
                <div className='page-header-title'>
                  <Link to='/immozy-center'>
                    <img src={backArrow} className='back-arrow' alt='img'></img>
                  </Link>
                  <div>
                    <h5
                      className='header-style'
                      style={{ display: "inline-block" }}
                    >
                      General Messages
                    </h5>
                  </div>

                  {isLoading ? (
                    <i
                      className='fa fa-cog fa-spin fa-3x fa-fw spinner-gear'
                      title='Loading data...'
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className='col-lg-7'>
                <div className='page-header-breadcrumb'>
                  <Link
                    className='btn btn-primary btn-sm'
                    to={`/msg-list/message/new/${module_id}`}
                  >
                    Add New Message
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='pcoded-inner-content'>
          <div className='main-body'>
            <div className='page-wrapper'>
              <div className='page-body'>
                <div className='card'>
                  <div className='card-block'>
                    <table className='module-table'>
                      <th>Message Title</th>
                      <th>Order Id</th>
                      <th>Start Date</th>
                      <th>Expire Date</th>
                      <th>Is Urgent</th>
                      <th>Activated</th>
                      <th>Module Type</th>
                      <th>Edit</th>
                      <tbody>
                        {msgList.length > 0 &&
                          msgList.map((list, index) => (
                            <tr key={list.id}>
                              <td
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                <Link
                                  to={`message/${list.id}/${module_id}`}
                                  style={{
                                    color: "#000",
                                  }}
                                  title={"View message details"}
                                >
                                  {ReactHtmlParser(list.title['en'])}
                                </Link>
                              </td>
                              <td
                                style={{
                                  width: "20%",
                                }}
                              >
                                {editId === list.id ? (
                                  <input
                                    type={"number"}
                                    value={list.orderId}
                                    id={list.id}
                                    onChange={handleChange}
                                    step={"any"}
                                    style={{
                                      width: "50%",
                                    }}
                                  />
                                ) : (
                                  list.orderId
                                )}
                                <i
                                  className={
                                    editId !== list.id
                                      ? "fa fa-pencil ml-3"
                                      : "fa fa-times ml-2"
                                  }
                                  onClick={() => {
                                    editId !== list.id
                                      ? setData(list)
                                      : cancelValue(list.orderId, index);
                                  }}
                                ></i>
                                {editId === list.id && (
                                  <i
                                    onClick={() =>
                                      savePriority(list.id, list.orderId)
                                    }
                                    className='fa fa-check ml-2'
                                  />
                                )}
                              </td>
                              <td>{getDate(list.startDate)}</td>
                              <td>{getDate(list.expireDate)}</td>
                              <td>
                                <input
                                  type='checkbox'
                                  checked={list.urgent}
                                  onChange={() => toggleStatus(list, "urgent")}
                                  disabled={isLoading}
                                ></input>
                              </td>
                              <td
                                style={{
                                  fontSize: "25px",
                                  cursor: isLoading ? "not-allowed" : "pointer",
                                }}
                              >
                                <i
                                  className={
                                    list.active
                                      ? "fa fa-toggle-on"
                                      : "fa fa-toggle-off"
                                  }
                                  onClick={() => toggleStatus(list, "active")}
                                ></i>
                              </td>
                              <td
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                <p className={`module-width ${list.type}`}>
                                  {list.type}
                                </p>
                              </td>
                              <td
                                style={{
                                  width: "10%",
                                }}
                              >
                                <Link
                                  to={`message/${list.id}/${module_id}`}
                                  style={{
                                    color: "#000",
                                  }}
                                  title={"Edit message"}
                                >
                                  <i className={"fa fa-pencil ml-3"}></i>
                                </Link>
                                <i
                                  className='feather icon-trash ml-2'
                                  onClick={() => deleteMessage(list.id)}
                                  title={"Delete this message"}
                                ></i>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {!isLoading && msgList.length === 0 && (
                      <div className='text-center mt-2'>No messages found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
