import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { callRequestAPI } from "../../services/callApi";
import { getDateWithTime } from "../../utils/tableData";
import TableComponent from "../common/tableComponent";
import { headerStyle } from "./realtorUtils";

let realtorId = "";

export default function RealtorMailLog() {
  const [isLoading, setisLoading] = useState(true);
  const [mailList, setmailList] = useState([]);

  useEffect(() => {
    realtorId = window.location.pathname.includes("/realtor-mail/")
      ? window.location.pathname.replace("/realtor-mail/", "")
      : "";
    isLoading && getDetails();
  });

  const getDetails = async () => {
    const response = await callRequestAPI(
      realtorId === ""
        ? `v1/profile/realtorloglist`
        : `v1/profile/realtorloglist?realtorid=${realtorId}`,
      {},
      "get"
    );
    if (response.status === 200 && response.data && response.data.data) {
      let list = [];
      console.log("  response.data.data", response.data.data);
      response.data.data.forEach((prop) => {
        const data = {
          created_at: getDateWithTime(prop.created_at),
          id: prop.id,
          objectid: prop.objectid,
          first_name: prop.firstname,
          last_name: prop.lastname,
          provider_email_address: prop.provider_email_address,
          realtorid: prop.realtorid,
        };
        list.push(data);
      });
      setmailList(list);
      setisLoading(false);
    } else {
      setisLoading(false);
    }
  };

  const redirectComponent = (cell, row) => (
    <Link
      to={`/object-detail/${row.objectid}`}
      style={{ cursor: "pointer", color: "#000" }}
    >
      {cell}
      <i
        style={{ marginLeft: "5px" }}
        className="fa fa-link"
        aria-hidden="true"
      ></i>
    </Link>
  );

  const renderFirstNameAndLastName = (cell) => <div>{cell || "-"}</div>;

  // const redirectRealtorComponent = (cell, row) => (
  //   <Link
  //     to={`/realtor/${row.realtorid}`}
  //     style={{ cursor: "pointer", color: "#000" }}
  //   >
  //     {cell}
  //     <i
  //       style={{ marginLeft: "5px" }}
  //       className="fa fa-link"
  //       aria-hidden="true"
  //     ></i>
  //   </Link>
  // );

  const columns = [
    {
      dataField: "first_name",
      text: "First Name",
      headerStyle: {
        textAlign: "center",
      },
      formatter: renderFirstNameAndLastName,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      headerStyle: {
        textAlign: "center",
      },
      formatter: renderFirstNameAndLastName,
    },
    {
      dataField: "provider_email_address",
      text: "Mail Sent to",
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      dataField: "objectid",
      text: "For Object Id",
      sort: true,
      headerStyle: headerStyle,
      formatter: redirectComponent,
    },
    // {
    //   dataField: "realtorid",
    //   text: "Realtor Id",
    //   sort: true,
    //   headerStyle: headerStyle,
    //   formatter: redirectRealtorComponent,
    // },
    {
      dataField: "created_at",
      text: "Mail Sent time",
      headerStyle: headerStyle,
      sort: true,
    },
  ];

  return (
    <div className="pcoded-content fade-in">
      <div className="pcoded-main-container" style={{ marginTop: "none" }}>
        <div className="pcoded-wrapper">
          <div className="page-header card" style={{ border: "none" }}>
            <div className="row align-items-end">
              <div className="col-lg-5">
                <div className="page-header-title">
                  <i
                    className="fa fa-paperclip"
                    aria-hidden="true"
                    style={{ background: "#932849" }}
                  ></i>
                  <div>
                    <h5
                      className="header-style"
                      style={{
                        display: "inline-block",
                      }}
                    >
                      Realtor Mail log
                    </h5>
                  </div>

                  {/* {isLoading ? (
                    <i
                      className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                      title="Loading data..."
                    />
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-body">
                <div className="card">
                  <div className="card-block">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12">
                        <TableComponent
                          data={mailList}
                          isLoading={isLoading}
                          columns={columns}
                          hidePagination
                          tableHeading={"Realtor List"}
                          tableSearch
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
