import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { callRequestAPI } from "../../services/callApi";
import ModalComponent from "../../utils/modalComponent";
import Skeleton from "react-loading-skeleton";

export default function OutliersSettings(props) {
  const [price, setPrice] = useState({ min: "not set", max: 0 });
  const [perSqmPrice, setPerSqmPrice] = useState({ min: 0, max: 0 });
  const [errorText, seterrorText] = useState("");
  const [confirmDialog, showConfirmDialog] = useState(false);

  const { isLoading, setisLoading, showToasterSuccess } = props;

  useEffect(() => {
    price.min === "not set" && !isLoading && getFilterValues();
  });

  const getFilterValues = async () => {
    setisLoading(true);
    const response = await callRequestAPI("v1/configurations", null, "get");
    if (response.status === 200 && response.data) {
      const data = response.data.data;
      setPrice({
        min: data.min_price ? parseInt(data.min_price) : 0,
        max: data.max_price ? parseInt(data.max_price) : 0,
      });
      setPerSqmPrice({
        min: data.min_square_meter_price
          ? parseInt(data.min_square_meter_price)
          : 0,
        max: data.max_square_meter_price
          ? parseInt(data.max_square_meter_price)
          : 0,
      });
      setisLoading(false);
    } else {
      setisLoading(false);
    }
  };

  const updateFilters = async () => {
    showConfirmDialog(false);
    setisLoading(true);

    const response = await callRequestAPI(
      "v1/configurations",
      {
        min_square_meter_price: perSqmPrice.min ? parseInt(perSqmPrice.min) : 0,
        max_square_meter_price: perSqmPrice.max ? parseInt(perSqmPrice.max) : 0,
        min_price: price.min ? parseInt(price.min) : 0,
        max_price: price.max ? parseInt(price.max) : 0,
      },
      "putHeader"
    );

    if (response.status === 200 && response.data) {
      setisLoading(false);
      showToasterSuccess("Changes applied successfully.");
    } else {
      setisLoading(false);
      seterrorText("Please add all proper values to save changes");
    }
  };

  const submitChanges = () => {
    // if (price.max < price.min || perSqmPrice.max < perSqmPrice.min) {
    //   seterrorText("Max value can not be more than min value");
    // } else {
    // seterrorText("");
    showConfirmDialog(true);
    // }
  };

  return (
    <div>
      {isLoading
        ? (
          <Skeleton
            height={"300px"}
            baseColor='#ddd4d7'
          />
        ) : (
          <React.Fragment>
            <div>
              <span className="font-weight-bold">Price</span>
              <div className="d-flex mt-2">
                {[
                  {
                    name: "min",
                    value: price.min,
                    placeholder: "min price",
                  },
                  {
                    name: "max",
                    value: price.max,
                    placeholder: "max price",
                  },
                ].map((field) => (
                  <input
                    key={field.name}
                    className="form-control mr-4"
                    type="number"
                    name={field.name}
                    value={field.value}
                    style={{ width: "250px" }}
                    placeholder={field.placeholder}
                    onChange={(e) =>
                      setPrice({
                        ...price,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                ))}
              </div>
            </div>
            <div className="mt-4">
              <span className="font-weight-bold">{"Price Per m² (%)"}</span>
              <div className="d-flex align-center-center mt-2">
                {[
                  {
                    name: "min",
                    value: perSqmPrice.min,
                    placeholder: "min price per m²",
                  },
                  {
                    name: "max",
                    value: perSqmPrice.max,
                    placeholder: "max price per m²",
                  },
                ].map((field) => (
                  <React.Fragment key={field.name}>
                    <input
                      type="number"
                      name={field.name}
                      placeholder={field.placeholder}
                      value={field.value}
                      style={{ width: "235px" }}
                      className="form-control"
                      onChange={(e) =>
                        setPerSqmPrice({
                          ...perSqmPrice,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                    <span className="ml-1 mr-4">%</span>
                  </React.Fragment>
                ))}
              </div>
            </div>
            {
              errorText !== "" && (
                <p className="mt-1 mb-2" style={{ color: "red" }}>
                  {errorText}
                </p>
              )
            }
            <div>
              <button className="btn btn-primary mt-5" onClick={submitChanges}>
                Apply Changes
              </button>
              <button
                className="btn btn-primary mt-5 ml-3"
                onClick={() => {
                  setisLoading(true);
                  getFilterValues();
                }}
              >
                Reset to old value
              </button>
              <p className="mt-3" style={{ fontStyle: "italic" }}>
                Note: This will directly affect the number of results shown in
                finder.
              </p>
            </div>
            {
              confirmDialog && (
                <ModalComponent
                  title={"Change filters"}
                  bodyMsg={
                    "Are you sure you want to change the filter values that will be applied to all results shown in finder?"
                  }
                  closeModal={() => showConfirmDialog(false)}
                  handleYes={updateFilters}
                  btnText="Cancel"
                />
              )
            }
          </React.Fragment >
        )
      }
    </div >
  );
}

OutliersSettings.propTypes = {
  isLoading: PropTypes.bool,
  setisLoading: PropTypes.func,
  showToasterSuccess: PropTypes.func,
};
