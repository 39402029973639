import React from "react";
import { Link } from "react-router-dom";

const headerStyle = () => ({
  textAlign: "center",
});

const redirectComponent = (cell, row) => (
  <Link
    to={`/question-detail/${row.questionId}`}
    style={{ cursor: "pointer", color: "#000" }}
  >
    {cell}
    <i
      style={{ marginLeft: "5px" }}
      className="fa fa-link"
      aria-hidden="true"
    ></i>
  </Link>
);

const deleteFormatter = () => (
  <div>
    <span
      className="waves-effect waves-dark pcoded-micon"
      // onClick={() => this.toggleModal(true, row.questionId)}
      style={{ fontSize: "20px", cursor: "pointer" }}
      title="This action is not allowed at the moment."
    >
      <i style={{ color: "#b3aeae" }} className="feather icon-trash"></i>
    </span>
  </div>
);

export const columns = [
  {
    dataField: "orderId",
    text: "Order Id",
    sort: true,
    headerStyle: headerStyle,
  },
  {
    dataField: "question",
    text: "Question",
    sort: true,
    formatter: redirectComponent,
    headerStyle: () => ({
      width: "50%",
      textAlign: "center",
      whiteSpace: "normal",
    }),
  },
  {
    dataField: "category",
    text: "Category",
    sort: true,
    headerStyle: headerStyle,
  },
  {
    dataField: "stage",
    text: "Stage",
    sort: true,
    headerStyle: headerStyle,
  },
  {
    dataField: "actions",
    text: "Actions",
    formatter: deleteFormatter,
    headerStyle: headerStyle,
  },
];
