/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import { callRequestAPI } from "../../services/callApi";
import {
  getDateWithTime,
} from "../../utils/tableData";
import { DateRangePicker } from "react-dates";
import ModalComponent from "../../utils/modalComponent";
import backArrow from "../../assets/images/whitearrow.png";
import "./mail-template.css";
import { BASE_URL } from "../../services/baseUrl";
import {
  createFilterUrl,
  headerStyle,
  languages,
  mailSenderListcolumns,
  options,
  verifyOptions,
} from "./mailUtils";
import { toast, ToastContainer } from "react-toastify";
import TableComponent from "../common/tableComponent";

let id = "";
let emailId = "";
let mailData = {};
let recordPerPage = 2500;

function MailSender(props) {
  const [isLoading, setisLoading] = useState(false);
  const [firstLoad, setfirstLoad] = useState(true);
  const [userList, setUserList] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [showUserModal, setshowUserModal] = useState(false);
  const [city, setcity] = useState(null);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [focusedInput, setfocusedInput] = useState(null);
  const [language, setlanguage] = useState(null);
  const [verifyStatus, setverifyStatus] = useState(null);
  const [tabNames, setTabNames] = useState({ IMMOZY_USERS: "Immozy Users" });
  const tabs = Object.values(tabNames);
  const [activetab, setactiveTab] = useState(tabs[0]);
  const [fileData, setFileData] = useState(null);
  const [fileDataColumns, setFileDataColumns] = useState([]);
  const [mailToUsersOptions, setMailToUsersOptions] = useState("immozyUsers");
  const [totalRecipients, setTotalRecipients] = useState(0);

  useEffect(() => {
    emailId = window.location.pathname.replace("/mail-users/", "");
    const params = props.location.state;
    if (params && params.templateName) {
      mailData.name = params.templateName || "";
      mailData.sender = params.sender || "";
      mailData.subject = params.subject || "";
      mailData.senderlabel = params.senderlabel || "";
    }
    firstLoad && getUserList();
  });

  const getUserList = async (applyFilter = false) => {
    setisLoading(true);
    setfirstLoad(false);

    let url = `v1/mailtemplates/mailusers?currentPage=1&recordPerPage=${recordPerPage}`;
    url = createFilterUrl(
      applyFilter,
      url,
      city,
      language,
      verifyStatus,
      startDate,
      endDate
    );

    const response = await callRequestAPI(url, null, "get");

    if (response.status === 200 && response.data && response.data.data) {
      let list = [];

      response.data.data.data.forEach((prop) => {
        if (
          prop.tmpusertype === "NORMAL" &&
          prop.issubscribed &&
          !prop.is_blacklist
        ) {
          const a = {
            userid: prop.userid,
            email: prop.email,
            firstName: prop.firstname || "-",
            isEmailVerified: prop.isemailverified ? "Completed" : "Pending",
            lastName: prop.lastname || "-",
            tmpusertype: prop.tmpusertype,
            createdDate: getDateWithTime(prop.createddate),
            language: prop.language === "de" ? "German" : "English",
          };

          list.push(a);
        }
      });
      setUserList(list);
      setisLoading(false);
      setTotalRecipients(list.length);
    } else {
      window.alert(response.message);
      setisLoading(false);
    }
  };

  const removeUserFromList = async () => {
    setisLoading(true);
    const response = await callRequestAPI(
      `v1/mailtemplates/blackuser/${id}`,
      null,
      "putHeader"
    );

    if (response.status === 200 && response.data) {
      setshowUserModal(false);
      getUserList();
    } else {
      setshowUserModal(false);
      setisLoading(false);
    }
  };

  const sendNewsLetterToUsers = async () => {
    if (BASE_URL.includes("app")) {
      setshowModal(false);
      setisLoading(true);
      if (mailToUsersOptions === "importedUsers") {
        sendMailToCsvUsers();
      } else if (mailToUsersOptions === "immozyUsers") {
        sendMailToImmozyUsers();
      } else if (mailToUsersOptions === "combined") {
        sendMailToCsvUsers();
        sendMailToImmozyUsers();
      }
    } else {
      toast.error("Mails can only be sent from production environment.");
      setshowModal(false);
    }
  };

  const sendMailToImmozyUsers = async () => {
    let emailList = [];
    userList.forEach((user) => emailList.push(user.email));

    if (emailId !== "") {
      const response = await callRequestAPI(
        `v1/mailtemplates/sendmail/${emailId}`,
        { email: emailList },
        "putHeader"
      );
      if (response.status === 200 && response.data) {
        setisLoading(false);
        if (
          mailToUsersOptions === "immozyUsers" ||
          mailToUsersOptions === "combined"
        ) {
          addHistory();
        }
        toast.success(
          "Immozy Users : Email sending process has been started. All mails will be sent out in some time."
        );
      } else {
        toast.error(
          response.message +
          " Something went wrong in sending emails to Immozy users."
        );
        setisLoading(false);
      }
    }
  };

  const sendMailToCsvUsers = async () => {
    let emailList = [];

    fileData.forEach((user) => {
      const email = user.email || user.Email;
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!email) {
        emailList.push(email);
      }
    });

    const response = await callRequestAPI(
      `v1/mailtemplates/sendmailbyemails/${emailId}`,
      { emails: emailList },
      "postHeader"
    );
    if (response.status === 201 && response.data) {
      toast.success(
        "CSV Users : Email sending process has been started. All mails will be sent out in some time."
      );
      if (mailToUsersOptions === "importedUsers") {
        addHistory();
      }
    } else {
      setisLoading(false);
      toast.error(
        response.message +
        " Something went wrong in sending emails to imported users."
      );
    }
  };

  const addHistory = async () => {
    const mailToUsersList =
      mailToUsersOptions === "immozyUsers"
        ? userList
        : mailToUsersOptions === "importedUsers"
          ? fileData
          : [...userList, ...fileData];

    const response = await callRequestAPI(
      `v1/mailtemplates/mailhistory/${emailId}`,
      {
        datetime: new Date(),
        city: city || "All",
        recipient_count: mailToUsersList.length,
      },
      "postHeader"
    );

    if (response.status === 201 && response.data) {
      toast.success("History log has been added for this newsletter");
      setisLoading(false);
      console.log(response.data);
    } else {
      setisLoading(false);
      toast.error(
        response.message + " Something went wrong in adding history log."
      );
    }
  };

  const resetFilters = () => {
    setcity("All");
    setstartDate(null);
    setendDate(null);
    setverifyStatus(null);
    setlanguage(null);
    getUserList();
  };

  const toggleModal = (userId) => {
    if (userId) {
      id = userId;
      setshowUserModal(true);
    }
  };

  const actionFormatter = (cell, row) => (
    <div>
      <span
        className="waves-effect waves-dark pcoded-micon"
        onClick={() => toggleModal(row.userid)}
        style={{ fontSize: "20px", cursor: "pointer" }}
      >
        <i
          className="fa fa-ban ml-3"
          style={
            row.tmpusertype === "NORMAL"
              ? { color: "#000" }
              : { color: "#932849" }
          }
          title={"Blacklist user"}
        ></i>
      </span>
    </div>
  );

  useEffect(
    () => {
      const tabs = Object.values(tabNames);
      if (tabs.length === 2) {
        setactiveTab(tabs[1]);
        setMailToUsersOptions("importedUsers");
        setTotalRecipients(fileData.length);
      }
    },
    // eslint-disable-next-line
    [tabNames]
  );

  const handleFileUpload = ({ target }) => {
    const file = target.files[0];
    // var name = file.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      setFileData(data);
      setTabNames({ ...tabNames, IMPORTED_USERS: "Imported Users" });
      const columnsData = Object.keys(data[0]);
      setFileDataColumns(
        columnsData.map((col) => ({
          dataField: col,
          text: col,
          headerStyle: {
            textAlign: "center",
            verticalAlign: "middle",
          },
        }))
      );
    };
    reader.readAsBinaryString(file);
  };

  const handleRadioChange = ({ target }) => {
    setMailToUsersOptions(target.name);
    switch (target.name) {
      case "importedUsers":
        setTotalRecipients(fileData.length);
        break;
      case "combined":
        setTotalRecipients([...userList, ...fileData].length);
        break;

      default:
        setTotalRecipients(userList.length);
        break;
    }
  };

  const columns = [
    ...mailSenderListcolumns,
    {
      dataField: "actions",
      text: "Actions",
      formatter: actionFormatter,
      headerStyle: headerStyle,
    },
  ];

  const tableDataMapper = {
    [tabNames.IMMOZY_USERS]: {
      keyField: "userid",
      data: userList,
      columns,
    },
    [tabNames.IMPORTED_USERS]: {
      keyField: "Email",
      data: fileData,
      columns: fileDataColumns,
    }
  }

  return (
    <div className="pcoded-content fade-in">
      <div className="pcoded-main-container" style={{ marginTop: "none" }}>
        <div className="pcoded-wrapper">
          <div className="page-header card" style={{ border: "none" }}>
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="page-header-title d-flex justify-content-between full-width">
                  <div className="d-flex">
                    <Link to={`/mail-template/${emailId}`}>
                      <img
                        src={backArrow}
                        className="back-arrow"
                        alt="img"
                      ></img>
                    </Link>
                    <div>
                      <h5
                        className="header-style"
                        style={{ display: "inline-block" }}
                      >
                        Newsletter Sender
                      </h5>
                    </div>
                    {/* {isLoading && (
                      <i
                        className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                        title="Loading data..."
                      />
                    )} */}
                  </div>
                  <div>
                    <label className="header-style custom-input mb-0">
                      <input
                        type="file"
                        hidden
                        onChange={handleFileUpload}
                        value=""
                      />
                      Import users from CSV
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-body" style={{ marginBottom: "0" }}>
                <div className="card">
                  <div className="card-block">
                    <div className="row">
                      <div className="mail-filter-div col-xs-12 ">
                        <p
                          className="mb-4"
                          style={{ fontSize: "22px", fontWeight: "bold" }}
                        >
                          Filter recipients list
                        </p>
                        <div className="d-flex align-items-center w-100 mb-5 flex-wrap">
                          <div className="d-flex align-items-center  filter-margin">
                            <p className="font-weight-bold mt-2 mb-2">
                              City :{" "}
                            </p>
                            <select
                              className="form-control col-md-6 ml-3"
                              value={city}
                              defaultValue={
                                options.filter((op) => op.value === city).value
                              }
                              onChange={(event) => setcity(event.target.value)}
                            >
                              {options.map((op, i) => (
                                <option
                                  value={op}
                                  key={i}
                                  selected={op.value === city}
                                >
                                  {op}
                                </option>
                              ))}
                            </select>{" "}
                          </div>

                          <div className="d-flex align-items-center filter-margin">
                            <p className="font-weight-bold mt-2 mb-2">
                              Language :{" "}
                            </p>
                            <select
                              className="form-control col-md-6 ml-3"
                              // value={language}
                              defaultValue={
                                languages.filter((op) => op.value === language)
                                  .value
                              }
                              onChange={(event) =>
                                setlanguage(event.target.value)
                              }
                            >
                              {languages.map((op, i) => (
                                <option
                                  value={op.value}
                                  key={i}
                                  selected={op.value === language}
                                >
                                  {op.label}
                                </option>
                              ))}
                            </select>{" "}
                          </div>

                          <div className="d-flex align-items-center filter-margin">
                            <p className="font-weight-bold mt-2 mb-2">
                              User status :{" "}
                            </p>
                            <select
                              className="form-control col-md-6 ml-3"
                              // value={language}
                              defaultValue={
                                verifyOptions.filter(
                                  (op) => op.value === verifyStatus
                                ).value
                              }
                              onChange={(event) =>
                                setverifyStatus(event.target.value)
                              }
                            >
                              {verifyOptions.map((op, i) => (
                                <option
                                  value={op.value}
                                  key={i}
                                  selected={op.value === verifyStatus}
                                >
                                  {op.label}
                                </option>
                              ))}
                            </select>{" "}
                          </div>

                          <div className="d-flex align-items-center mr-2 date-picker-span filter-margin">
                            <label className="font-weight-bold mt-2 mb-2 mr-3">
                              Signup Date range:
                            </label>
                            <DateRangePicker
                              startDate={startDate}
                              startDateId="12345"
                              endDate={endDate}
                              endDateId="7896"
                              onDatesChange={({ startDate, endDate }) => {
                                setstartDate(startDate);
                                setendDate(endDate);
                              }}
                              focusedInput={focusedInput}
                              onFocusChange={(focusedInput) =>
                                setfocusedInput(focusedInput)
                              }
                              isOutsideRange={() => false}
                              displayFormat="DD-MM-YYYY"
                            />
                          </div>
                        </div>
                        <button
                          className="btn btn-primary"
                          style={{ width: "150px" }}
                          onClick={() => getUserList(true)}
                        >
                          Apply
                        </button>
                        <button
                          className="btn btn-primary ml-3"
                          style={{ width: "150px" }}
                          onClick={() => resetFilters()}
                        >
                          Reset
                        </button>
                      </div>
                      <div className="col-xs-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        <div className="nav-tabs-wrapper d-flex flex-column flex-md-row justify-content-between align-items-md-center align-items-start mb-4">
                          <ul
                            className="nav nav-tabs order-1 order-md-0"
                            id="myTab"
                            role="tablist"
                          >
                            {tabs.map((tab, index) => (
                              <li className="nav-item" key={index}>
                                <span
                                  className={`cursor-pointer ${
                                    activetab === tab
                                      ? "nav-link active"
                                      : "nav-link"
                                  }`}
                                  id="home-tab"
                                  role="tab"
                                  name={tab}
                                  onClick={() => {
                                    setactiveTab(tab);
                                    localStorage.setItem(
                                      "lastTabOfSettingPage",
                                      tab
                                    );
                                  }}
                                >
                                  {tab}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <TableComponent
                          showTotalNearHeading
                          isLoading={isLoading}
                          tableHeading={"Total Recipients"}
                          {...tableDataMapper[activetab]}
                          tableSearch
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-block">
                    <div className="row">
                      <div className="col-xs-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        <h4>Send mail to all users</h4>
                        {fileData && fileData.length > 0 && (
                          <div className="d-flex">
                            <div className="form-check mr-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={mailToUsersOptions === "immozyUsers"}
                                name="immozyUsers"
                                id="flexRadioDefault1"
                                onChange={handleRadioChange}
                              />
                              <label
                                className="form-check-label cursor-pointer"
                                htmlFor="flexRadioDefault1"
                              >
                                Immozy Users
                              </label>
                            </div>
                            <div className="form-check mr-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={mailToUsersOptions === "importedUsers"}
                                name="importedUsers"
                                id="flexRadioDefault2"
                                onChange={handleRadioChange}
                              />
                              <label
                                className="form-check-label cursor-pointer"
                                htmlFor="flexRadioDefault2"
                              >
                                Imported Users
                              </label>
                            </div>
                            <div className="form-check mr-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={mailToUsersOptions === "combined"}
                                name="combined"
                                id="flexRadioDefault3"
                                onChange={handleRadioChange}
                              />
                              <label
                                className="form-check-label cursor-pointer"
                                htmlFor="flexRadioDefault3"
                              >
                                Both
                              </label>
                            </div>
                          </div>
                        )}
                        <div style={{ marginTop: "2%" }}>
                          <ul>
                            <p>
                              - <b>Total Recipients </b>: {totalRecipients}
                            </p>
                            {mailData && (
                              <>
                                <p>
                                  -<b> Template name: </b>
                                  {mailData.name}
                                </p>
                                <p>
                                  - <b>Sender:</b> {mailData.sender}
                                </p>
                                <p>
                                  - <b>Subject: </b>
                                  {mailData.subject}
                                </p>
                                <p>
                                  - <b>Sender Label: </b>
                                  {mailData.senderlabel}
                                </p>
                              </>
                            )}
                          </ul>
                        </div>
                        <span className="d-flex justify-content-start align-items-center mt-4">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => setshowModal(true)}
                          >
                            Send Newsletter
                          </button>
                        </span>

                        <p className="mt-3" style={{ fontStyle: "italic" }}>
                          Note: Mail will be sent to all the users that are
                          listed above. Please make sure the list is correct
                          before sending the email.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
      {showModal && (
        <ModalComponent
          title={"Send Newsletter"}
          bodyMsg={`Are you sure you want send news letter to all the users? Total recipient count: ${totalRecipients}`}
          closeModal={() => setshowModal(false)}
          handleYes={sendNewsLetterToUsers}
          btnText="No"
        />
      )}
      {showUserModal && (
        <ModalComponent
          title={"Remove User"}
          bodyMsg={"Are you sure you want to blacklist this user? "}
          closeModal={() => setshowUserModal(false)}
          handleYes={removeUserFromList}
          btnText="No"
        />
      )}
    </div>
  );
}

export default MailSender;
