import React from "react";
import { DateRangePicker } from "react-dates";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function ObjectFilters(props) {
  return (
    <div className="row filter-section">
      <div
        style={{
          alignItems: "center",
          display: "inline-flex",
        }}
        className="mt-2"
      >
        <span style={{ display: "inline-grid" }}>
          <label className="filter-title">Object Id</label>
          <input
            className="form-control id-input mr-2"
            placeholder="e.g. 12efe-ax3ef-41ff4"
            onChange={props.handleChange("id")}
            type="text"
            value={props.objectId}
          ></input>
        </span>
        {/* <span style={{ display: "inline-grid" }}> */}
        <span style={{ display: "none" }}>
          <label className="filter-title">Original Id</label>
          <input
            className="form-control pirce-input mr-2"
            placeholder="e.g. 547457"
            onChange={props.handleChange("platformId")}
            type="text"
            value={props.platformId}
          ></input>
        </span>
        <span className="mr-2 date-picker-span">
          <label className="filter-title">Listing Date</label>
          <DateRangePicker
            startDate={props.startDate}
            startDateId="12345"
            endDate={props.endDate}
            endDateId="7896"
            onDatesChange={({ startDate, endDate }) =>
              props.setDate(startDate, endDate)
            }
            focusedInput={props.focusedInput}
            onFocusChange={(focusedInput) =>
              props.setFocusedInput(focusedInput)
            }
            isOutsideRange={() => false}
            displayFormat="YYYY-MM-DD"
          />
        </span>

        <span style={{ display: "grid" }}>
          <label className="filter-title">Price</label>
          <span className="price-span">
            <input
              className="form-control pirce-input mr-2"
              placeholder="From"
              onChange={props.handleChange("fromPrice")}
              type="number"
              value={props.fromPrice}
            ></input>

            <svg
              className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1"
              focusable="false"
              viewBox="0 0 1000 1000"
              style={{ marginTop: "8px" }}
            >
              <path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path>
            </svg>
            <input
              className="form-control pirce-input mr-2 ml-2"
              placeholder="To"
              onChange={props.handleChange("toPrice")}
              type="number"
              value={props.toPrice}
            ></input>
          </span>
        </span>
      </div>
      <div
        style={{
          alignItems: "center",
          display: "inline-flex",
        }}
        className="mt-2"
      >
        <span style={{ display: "inline-grid" }}>
          <label className="filter-title">Realtor Id</label>
          <input
            className="form-control id-input mr-2"
            placeholder="realtor id "
            onChange={props.handleChange("realtorid")}
            type="text"
            value={props.realtorid}
          ></input>
        </span>
        <span style={{ display: "inline-grid" }}>
          <label className="filter-title">Realtor Company</label>
          <input
            className="form-control id-input mr-2"
            placeholder=""
            onChange={props.handleChange("realtorName")}
            type="text"
            value={props.realtorName}
          ></input>
        </span>
        <span style={{ display: "inline-grid" }}>
          <label className="filter-title">Platform Name</label>
          <input
            className="form-control id-input mr-2"
            placeholder="e.g. immowelt, ebayKA, immonet"
            onChange={props.handleChange("platformType")}
            type="text"
            value={props.platformType}
          ></input>
        </span>
      </div>
      <div className="mt-2">
        {(parseInt(props.fromPrice) > 0 ||
          props.platformId !== "" ||
          parseInt(props.toPrice) > 0 ||
          props.objectId !== "" ||
          props.startDate !== null ||
          props.endDate !== null ||
          props.realtorid !== "" ||
          props.realtorName !== "" ||
          props.platformType !== "") && (
          <button
            id="search-btn"
            type="button"
            className="btn mr-3"
            title="Clear filters"
            onClick={() => props.clearSearch()}
          >
            Clear Filters
          </button>
        )}
        <button
          id="search-btn"
          type="button"
          className="btn"
          onClick={() => props.handleSearchClick()}
        >
          Search
          <i
            className="fa fa-search"
            aria-hidden="true"
            style={{ marginLeft: "10px" }}
          ></i>
        </button>
        <Link
          id="search-btn"
          type="button"
          className="btn  ml-3"
          to={"/objects/duplicates"}
        >
          Show duplicates
        </Link>
      </div>
    </div>
  );
}

ObjectFilters.propTypes = {
  handleSearchClick: PropTypes.func,
  clearSearch: PropTypes.func,
  handleChange: PropTypes.func,
  setFocusedInput: PropTypes.func,
  focusedInput: PropTypes.object,
  setDate: PropTypes.func,
  toPrice: PropTypes.string,
  fromPrice: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  platformId: PropTypes.string,
  objectId: PropTypes.string,
  redirectToDuplicates: PropTypes.func,
  realtorid: PropTypes.string,
  realtorName: PropTypes.string,
  platformType: PropTypes.string,
};
