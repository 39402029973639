import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { callRequestAPI } from "../../services/callApi";
import ModalComponent from "../../utils/modalComponent";
import { ConvertToSnakeCase } from "../../utils/utility";
import Skeleton from "react-loading-skeleton";

const DynamicScoreManagement = (props) => {
  const [scoring, setScoring] = useState({});
  const [weighting, setWeighting] = useState({});
  const [previousData, setPreviousData] = useState({});
  const [id, setId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { isLoading, setisLoading, showToasterSuccess, showToasterError } =
    props;

  useEffect(
    () => {
      getWeightingData();
    },
    // eslint-disable-next-line
    []
  );

  const getWeightingData = async () => {
    setisLoading(true);
    const response = await callRequestAPI(`v1/scoreweighting`, {}, "get");

    if (response.status === 200 && response.data) {
      const data = response.data.data[0];
      const {
        id,
        financial,
        location,
        requirements,
        financial_scoring,
        location_scoring,
        requirement_scoring,
      } = data;

      setisLoading(false);

      setId(id);
      setScoring({ location, requirements, financial });
      setWeighting({
        financial_scoring,
        location_scoring,
        requirement_scoring,
      });

      setPreviousData({
        scoring: { location, requirements, financial },
        weighting: { financial_scoring, location_scoring, requirement_scoring },
      });
    } else {
      console.log("error : ", response);
      setisLoading(false);
    }
  };
  const updateWeightingData = async (data) => {
    setisLoading(true);
    const response = await callRequestAPI(
      `v1/scoreweighting/${id}`,
      data,
      "putHeader"
    );

    if (response.status === 200 && response.data) {
      setisLoading(false);
      setShowModal(false);
      showToasterSuccess("Score weighting updated successfully");
    } else {
      setisLoading(false);
      setShowModal(false);

      setScoring(previousData.scoring);
      setWeighting(previousData.weighting);

      if (response.isError && !!response.message) {
        setTimeout(() => {
          showToasterError(response.message);
        }, 100);
      }
    }
  };

  const handleChange = (scoreLabel, field, key, value) => {
    setScoring({
      ...scoring,
      [scoreLabel]: {
        ...scoring[scoreLabel],
        [field]: {
          ...scoring[scoreLabel][field],
          [key]: parseInt(value),
        },
      },
    });
  };
  const weightingValuesArray = Object.values(weighting) || [];
  const totalWeighting =
    weightingValuesArray.length > 0
      ? weightingValuesArray.reduce((total, value) => total + value)
      : 0;

  return (
    <>
      {showModal && (
        <ModalComponent
          title={"Confirmation"}
          bodyMsg={
            "This will effect all score calculations for user portal. Are you sure you want to update?"
          }
          closeModal={() => setShowModal(false)}
          handleYes={() => updateWeightingData({ ...scoring, ...weighting })}
        />
      )}
      {isLoading ? (
        <Skeleton
          height={"300px"}
          baseColor='#ddd4d7'
        />
      ) : (
        <>
          <div className="w-100 d-flex justify-content-end mb-3">
            <button
              className="btn btn-primary mt-3"
              onClick={() =>
                totalWeighting <= 100 ? setShowModal(true) : false
              }
              disabled={totalWeighting > 100}
            >
              Apply Changes
            </button>
          </div>
          <div className="d-flex justify-content-start align-items-stretch row">
            {Object.keys(scoring).map((scoreLabel) => (
              <div
                key={scoreLabel}
                className="col-xl-6 col-lg-12 col-md-12 col-sm-12  col-xs-12 mb-3"
              >
                <div
                  style={{
                    border: "1px solid #dddddd",
                    borderRadius: "10px",
                    padding: "15px",
                    height: "100%",
                  }}
                >
                  <div
                    className="row"
                    style={{
                      borderBottom: "1px solid #dddddd",
                      paddingBottom: "12px",
                    }}
                  >
                    <div className="col-md-6 col-sm-6 col-6">
                      <span className="font-weight-bold">
                        {ConvertToSnakeCase(scoreLabel)}
                      </span>
                    </div>
                    <div
                      className={
                        scoreLabel === "financial"
                          ? "col-md-6  col-sm-6 col-6"
                          : "col-md-3  col-sm-3 col-3"
                      }
                    >
                      <span className="font-weight-bold">Max</span>
                    </div>
                    {scoreLabel !== "financial" && (
                      <div className="col-md-3 col-sm-3 col-3">
                        <span className="font-weight-bold">Not relevant</span>
                      </div>
                    )}
                  </div>
                  <br />
                  {Object.keys(scoring[scoreLabel]).map((field, index) => (
                    <div className="row mb-2" key={index}>
                      <div className="col-md-6 col-sm-6 col-6">
                        {ConvertToSnakeCase(field)}
                      </div>
                      <div
                        className={
                          scoreLabel === "financial"
                            ? "col-md-6  col-sm-6 col-6"
                            : "col-md-3  col-sm-3 col-3"
                        }
                      >
                        {scoring[scoreLabel][field].hasOwnProperty("max") && (
                          <input
                            onChange={({ target }) =>
                              handleChange(
                                scoreLabel,
                                field,
                                "max",
                                target.value
                              )
                            }
                            type="number"
                            className="full-width form-control"
                            value={scoring[scoreLabel][field].max}
                          />
                        )}
                      </div>
                      {scoreLabel !== "financial" && (
                        <div className="col-md-3 col-sm-3 col-3">
                          {scoring[scoreLabel][field].hasOwnProperty(
                            "not_relevant"
                          ) && (
                              <input
                                onChange={({ target }) =>
                                  handleChange(
                                    scoreLabel,
                                    field,
                                    "not_relevant",
                                    target.value
                                  )
                                }
                                type="number"
                                className="full-width form-control"
                                value={scoring[scoreLabel][field].not_relevant}
                              />
                            )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12  col-xs-12 mb-3">
              <div
                style={{
                  border: "1px solid #dddddd",
                  borderRadius: "10px",
                  padding: "15px",
                  height: "100%",
                }}
              >
                <div
                  className="row"
                  style={{
                    borderBottom: "1px solid #dddddd",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-6 col-sm-6 col-6 font-weight-bold">
                    <span>Score Type</span>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 font-weight-bold">
                    <span>Weighting</span>
                  </div>
                </div>
                <br />
                {Object.keys(weighting).map((weightingKey) => (
                  <div className="row mb-2" key={weightingKey}>
                    <div className="col-md-6 col-sm-6 col-6">
                      {ConvertToSnakeCase(weightingKey)}
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <input
                        onChange={({ target }) =>
                          setWeighting({
                            ...weighting,
                            [weightingKey]: parseInt(target.value),
                          })
                        }
                        type="number"
                        className="full-width form-control"
                        value={weighting[weightingKey]}
                      />
                    </div>
                  </div>
                ))}
                <br />
                <div className="row mb-2">
                  <div className="col-md-6 col-sm-6 col-6">Total</div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <input
                      onChange={() => false}
                      style={{ pointerEvents: "none" }}
                      readOnly
                      type="number"
                      className="full-width form-control mb-2"
                      value={totalWeighting || 0}
                    />
                    <label
                      style={{
                        color: totalWeighting > 100 ? "red" : "green",
                      }}
                    >
                      Note : Weightings need to be total 100!
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DynamicScoreManagement;

DynamicScoreManagement.propTypes = {
  isLoading: PropTypes.bool,
  setisLoading: PropTypes.func,
  showToasterSuccess: PropTypes.func,
  showToasterError: PropTypes.func,
};