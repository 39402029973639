export const lang = {
  EN: "en",
  GR: "de",
};

export const inputTypes = [
  "CHECKBOX",
  "SELECTBOX",
  "SLIDER",
  "DOUBLE_RANGE",
  "RADIO",
  "INPUT",
];

export const questionCategories = [
  "General",
  "Requirements",
  "Finances",
  "Required space	",
];

export const stages = [
  { key: "46be22e5-2e6b-461e-ad6d-8f269ec79d70", value: "Stage 1" },
  { key: "4d61d000-a7ef-4c97-9442-4e008d0347e4", value: "Stage 2" },
];

export const objStructure = {
  en: "",
  de: "",
};

export const optionStructure = [
  { option: { ...objStructure }, type: inputTypes[0], tag: "" },
  { option: { ...objStructure }, type: inputTypes[0], tag: "" },
];


export const realtorStatsTabsOperationMapper = {
  Details: "listLogs",
  "Property list stats": "listLogs",
  "Property click stats": "detailLogs",
  "Property Interest stats": "interestLogs",
  "Mail log": "mailLog",
};