import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getQuestionsList,
  deleteQuestion,
} from "../../actions/onboardingActions";
import PropTypes from "prop-types";
import { tableOptions } from "../../utils/tableData";
import ModalComponent from "../../utils/modalComponent";
import { columns } from "./questioncolumns";
import TableComponent from "../common/tableComponent";
import "../../assets/css/tableStyle.css";

let deletedId = "";

class Questions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questionsList: [],
      pageSize: 10,
      isLoading: true,
      showModal: false,
    };
  }

  componentDidMount() {
    const { getQuestionsList } = this.props;
    getQuestionsList();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const { questionListReducer, deleteQuestionReducer } = this.props;

    if (
      prevProps.questionListReducer !== questionListReducer &&
      questionListReducer.status === "success" &&
      questionListReducer.data
    ) {
      const result = this.setProperties(questionListReducer.data);
      this.setState({ questionsList: result, isLoading: false });
    }

    if (
      prevProps.deleteQuestionReducer !== deleteQuestionReducer &&
      deleteQuestionReducer.status === "success" &&
      deletedId !== ""
    ) {
      const { questionsList } = this.state;
      const updatedList = questionsList.filter(
        (q) => q.questionId !== deletedId
      );

      this.setState({ questionsList: updatedList, isLoading: false });
      deletedId = "";
    }

    if (
      prevProps.deleteQuestionReducer !== deleteQuestionReducer &&
      deleteQuestionReducer.status === "fail"
    ) {
      this.setState({ isLoading: false });
      alert(
        "An error has occured while deleting the question. Please try again later."
      );
    }
  }

  setProperties = (data) => {
    let list = [];
    data.forEach((prop) => {
      const a = {
        questionId: prop.questionId,
        orderId: prop.orderId,
        category: prop.category,
        question: prop.question["en"],
        stage:
          prop.questionStageId === "46be22e5-2e6b-461e-ad6d-8f269ec79d70"
            ? "stage 1"
            : "stage 2",
      };

      list.push(a);
    });

    return list;
  };

  handleDeleteQuestion = () => {
    const { deleteQuestion } = this.props;
    deleteQuestion(deletedId);
    this.setState({ isLoading: true, showModal: false });
  };

  toggleModal = (value, id = "") => {
    deletedId = id;
    this.setState({ showModal: value });
  };

  render() {
    const { questionsList, pageSize, isLoading, showModal } = this.state;

    const options = {
      ...tableOptions,
      sizePerPage: pageSize,
    };

    return (
      <div className="pcoded-content fade-in">
        <div className="pcoded-main-container" style={{ marginTop: "none" }}>
          <div className="pcoded-wrapper">
            <div className="page-header card" style={{ border: "none" }}>
              <div className="row align-items-end">
                <div className="col-lg-5">
                  <div className="page-header-title">
                    <i
                      className="fa fa-question"
                      aria-hidden="true"
                      style={{ background: "#932849" }}
                    ></i>
                    <div>
                      <h5
                        className="header-style"
                        style={{ display: "inline-block" }}
                      >
                        Question Management
                      </h5>
                    </div>

                    {/* {questionsList.length === 0 || isLoading ? (
                      <i
                        className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                        title="Loading data..."
                      />
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="page-header-breadcrumb">
                    <Link className="btn btn-primary btn-sm" to="/add-question">
                      Add New Question
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-body">
                  <div className="card">
                    <div className="card-block">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12">
                          <TableComponent
                            data={questionsList}
                            columns={columns}
                            keyField={"questionId"}
                            tableHeading={"Questions List"}
                            isLoading={isLoading}
                            tableSearch
                            paginationOptions={options}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <ModalComponent
            title={"Confirmation"}
            bodyMsg={"Are you sure you want to delete the question?"}
            closeModal={() => this.toggleModal(false)}
            handleYes={this.handleDeleteQuestion}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getQuestionsList: () => {
    dispatch(getQuestionsList());
  },
  deleteQuestion: (id) => {
    dispatch(deleteQuestion(id));
  },
});

const mapStateToProps = (state) => ({
  questionListReducer: state.questionListReducer,
  deleteQuestionReducer: state.deleteQuestionReducer,
});

Questions.propTypes = {
  getQuestionsList: PropTypes.func,
  deleteQuestion: PropTypes.func,
  questionListReducer: PropTypes.object,
  deleteQuestionReducer: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
