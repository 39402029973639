import React from "react";
import { Link } from "react-router-dom";

export const mailHeaders = [
  {
    dataField: "title",
    text: "Name",
    headerStyle: {
      textAlign: "center",
      verticalAlign: "middle",
    },
  },
  {
    dataField: "sender_email",
    text: "Sender Email",
    headerStyle: {
      textAlign: "center",
      verticalAlign: "middle",
    },
  },
  {
    dataField: "createddate",
    text: "Created Date",
    headerStyle: {
      textAlign: "center",
      verticalAlign: "middle",
    },
  },
  {
    dataField: "updateddate",
    text: "Updated Date",
    headerStyle: {
      textAlign: "center",
      verticalAlign: "middle",
    },
  },
];

export const headerStyle = () => ({
  textAlign: "center",
  verticalAlign: "top",
});

const redirectComponent = (cell, row) => (
  <Link
    to={`/user-detail/${row.userId}`}
    style={{ cursor: "pointer", color: "#000" }}
  >
    {cell}
    <i
      style={{ marginLeft: "5px" }}
      className="fa fa-link"
      aria-hidden="true"
    ></i>
  </Link>
);

const statusComponent = (cell) => {
  let color = "orange";
  let bgColor = "rgb(255, 165, 0, .7)";

  if (cell === "Completed") {
    color = "green";
    bgColor = "rgba(51, 170, 51, .7)";
  }

  return (
    <div>
      <label
        className="status-label status-label-doc"
        style={{
          border: `1px solid ${color}`,
          background: bgColor,
        }}
      >
        {cell}
      </label>
    </div>
  );
};

export const mailSenderListcolumns = [
  {
    dataField: "firstName",
    text: "First Name",
    sort: true,
    headerStyle: headerStyle,
  },
  {
    dataField: "lastName",
    text: "Last Name",
    sort: true,
    headerStyle: headerStyle,
  },
  {
    dataField: "email",
    text: "Email",
    headerStyle: headerStyle,
    formatter: redirectComponent,
  },
  {
    dataField: "isEmailVerified",
    text: "Email verify Status",
    sort: true,
    headerStyle: headerStyle,
    formatter: statusComponent,
  },
  {
    dataField: "language",
    text: "Language",
    sort: true,
    headerStyle: headerStyle,
  },
  {
    dataField: "createdDate",
    text: "SignUp Date",
    sort: true,
    headerStyle: headerStyle,
  },
];

const cities = {
  All: "All",
  HAMBURG: "Hamburg",
  BERLIN: "Berlin",
  HANNOVER: "Hannover",
};
export const options = Object.values(cities);

export const languages = [
  { label: "All", value: null },
  { label: "German", value: "de" },
  { label: "English", value: "en" },
];

export const verifyOptions = [
  { label: "All", value: null },
  { label: "Email Verfied", value: true },
  { label: "Email Not verified", value: false },
];

export const createFilterUrl = (
  applyFilter,
  url,
  city,
  language,
  verifyStatus,
  startDate,
  endDate
) => {
  if (applyFilter) {
    let filters = "";

    if (city && city !== "" && city !== "All") {
      filters = `&&city=${city}`;
    }
    if (language && language !== "" && language !== "All") {
      filters = `${filters}&&language=${language}`;
    }
    if (verifyStatus && verifyStatus !== "" && verifyStatus !== "All") {
      filters = `${filters}&&status=${verifyStatus}`;
    }
    if (startDate && startDate !== "") {
      const date = startDate.format("MM-DD-YYYY");
      filters = `${filters}&&startDate=${date}`;
    }
    if (endDate && endDate !== "") {
      const date = endDate.format("MM-DD-YYYY");
      filters = `${filters}&&endDate=${date}`;
    }
    url = `${url}${filters}`;
  }

  return url;
};

export const mailHistoryColumns = [
  {
    dataField: "title",
    text: "Template Name",
    headerStyle: {
      textAlign: "center",
      verticalAlign: "middle",
    },
  },
  {
    dataField: "city",
    text: "City",
    headerStyle: {
      textAlign: "center",
      verticalAlign: "middle",
    },
  },
  {
    dataField: "recipient_count",
    text: "Recipient Count",
    headerStyle: {
      textAlign: "center",
      verticalAlign: "middle",
    },
  },
  {
    dataField: "datetime",
    text: "Sent Time",
    headerStyle: {
      textAlign: "center",
      verticalAlign: "middle",
    },
  },
];
