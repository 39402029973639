import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ChangePassword from "./changePassword";
import { getProfile, editProfile } from "../../actions/profileActions";
import { changePassword } from "../../actions/authActions";
import { clearReducer } from "../../actions/clearAction";
import user from "../../assets/images/user-icon.png";

const initialPasswordState = {
  password: "",
  newPassword: "",
  confirmPassword: "",
  showchangePassword: false,
};

const initialProfileState = {
  showEditProfile: false,
};

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      avatar: "",
      userName: "",
      email: "",
      firstName: "",
      lastName: "",
      fNameEdited: "",
      lNameEdited: "",
      showLoading: false,
      errorMessageProfile: "",
      errorMessage: "",
      show: [],
      ...initialProfileState,
      ...initialPasswordState,
      showSpinner: true,
    };
  }

  componentDidMount() {
    const { getProfile } = this.props;
    getProfile();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const {
      profileInfo,
      updateProfileReducer,
      changePasswordReducer,
      history,
      clearReducer,
    } = this.props;
    const { showSpinner } = this.state;

    if (
      prevProps.profileInfo !== profileInfo &&
      profileInfo.status === "success" &&
      showSpinner
    ) {
      this.setProfileDetails(profileInfo.data);
      clearReducer("CLEAR_PROFILE_INFO");
    }

    if (
      prevProps.updateProfileReducer !== updateProfileReducer &&
      updateProfileReducer.status === "success"
    ) {
      this.setState({
        showEditProfile: false,
        showLoading: false,
      });
    }

    if (
      prevProps.changePasswordReducer !== changePasswordReducer &&
      changePasswordReducer.status === "success"
    ) {
      this.setState({ showSpinner: false });
      clearReducer("CLEAR_RESET_PASSWORD");
      if (
        window.confirm(
          "your password has been changed successfully. Please login again with new password."
        )
      ) {
        localStorage.removeItem("token");
        history.push("/login");
      }
    }

    if (
      prevProps.changePasswordReducer !== changePasswordReducer &&
      changePasswordReducer.status === "fail"
    ) {
      const msg = changePasswordReducer.data
        ? changePasswordReducer.data.data.message
        : "Something went wrong";
      this.setState({ errorMessage: msg, showSpinner: false });
      clearReducer("CLEAR_RESET_PASSWORD");
    }
  }

  render() {
    const {
      userName,
      email,
      firstName,
      lastName,
      errorMessage,
      errorMessageProfile,
      avatar,
      showchangePassword,
      showEditProfile,
      showSpinner,
      fNameEdited,
      lNameEdited,
    } = this.state;

    return (
      <div className="pcoded-content fade-in">
        <div className="pcoded-inner-content">
          <div className="page-header card" style={{ border: "none" }}>
            <div className="row align-items-end">
              <div className="col-lg-4">
                <div className="page-header-title">
                  <i className="feather icon-user bg-c-blue"></i>
                  <div className="d-inline">
                    <h5 className="header-style">Profile</h5>
                  </div>
                  {userName === "" && (
                    <i
                      className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                      title="Loading data..."
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-8">
                <div className="page-header-breadcrumb">
                  <Link
                    className="btn btn-primary btn-sm"
                    onClick={() => this.deleteToken()}
                    to="/login"
                  >
                    Sign out
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-body">
                  <div className="card">
                    <div className="card-header-right cusdetail">
                      <div className="text-right">
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#932849",
                            textDecoration: "none",
                          }}
                          title="Edit ptofile"
                          onClick={() => this.toggleMode("showEditProfile")}
                        >
                          <i className="feather icon-edit-2">
                            <label className="ml-1">Edit Profile</label>
                          </i>
                        </span>
                      </div>
                    </div>
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-sm-12 col-md-2 customerdetailcard">
                          <img
                            src={avatar}
                            alt="User"
                            style={{
                              height: "104px",
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-5 customerdetailcard">
                          <div>
                            <label>Role</label>
                            <span>Admin</span>
                          </div>
                          <div>
                            <label>User Name</label>
                            <span>{userName}</span>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-5 customerdetailcard">
                          <div>
                            <label>Email ID</label>
                            <span>{email}</span>
                          </div>
                          <div>
                            <label>Name</label>
                            <span>{firstName + " " + lastName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {showEditProfile && (
                    <div className="card">
                      <div className="card-header">
                        <h5>Edit Profile</h5>
                        <div>
                          <input
                            placeholder="First Name"
                            name="fNameEdited"
                            value={fNameEdited}
                            style={{ padding: "5px" }}
                            onChange={this.handleInputChange}
                          />
                          <input
                            placeholder="Last Name"
                            name="lNameEdited"
                            value={lNameEdited}
                            style={{ margin: "15px", padding: "5px" }}
                            onChange={this.handleInputChange}
                          />

                          <Link
                            className="btn btn-primary btn-sm"
                            style={{
                              minWidth: "100px",
                              marginTop: "-7px",
                            }}
                            onClick={() => this.saveProfileChanges()}
                          >
                            Save
                          </Link>
                          <Link
                            className="btn btn-primary btn-sm"
                            onClick={() => this.cancelEditProfile()}
                            style={{
                              minWidth: "100px",
                              marginLeft: "15px",
                              marginTop: "-7px",
                            }}
                          >
                            Cancel
                          </Link>
                          {errorMessageProfile !== "" && (
                            <label
                              className="col-sm-12"
                              style={{
                                marginLeft: "-15px",
                                color: "red",
                                fontSize: "12px",
                                fontStyle: "italic",
                              }}
                            >
                              {errorMessageProfile}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="card">
                    <div className="card-header">
                      {showchangePassword ? (
                        <>
                          <h5>Change password</h5>
                          {showSpinner && (
                            <i
                              className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                              title="Loading data..."
                            />
                          )}
                        </>
                      ) : (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => this.toggleMode("showchangePassword")}
                          style={{
                            minWidth: "100px",
                            margin: "10px",
                          }}
                        >
                          Change password
                        </button>
                      )}
                      {errorMessage !== "" && (
                        <label
                          className="col-sm-12"
                          style={{
                            marginLeft: "-15px",
                            color: "red",
                            fontSize: "12px",
                            fontStyle: "italic",
                          }}
                        >
                          {errorMessage}
                        </label>
                      )}
                    </div>
                    {showchangePassword && (
                      <ChangePassword
                        handleInputChange={this.handleInputChange}
                        onKeyDown={this.onKeyDown}
                        cancelPassword={this.cancelPassword}
                        submitPassword={this.submitPassword}
                        show={this.state.show}
                        setShowPwd={this.setShowPwd}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  setShowPwd = (field) => {
    const { show } = this.state;
    let updateShow = show;

    if (show.includes(field)) {
      updateShow = show.filter((i) => i !== field);
    } else {
      updateShow.push(field);
    }
    this.setState({ show: updateShow });
  };

  setProfileDetails = (data) => {
    this.setState({
      email: data.email,
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      status: data.status,
      userName: data.userName,
      userId: data.userId,
      avatar: data.avatar || user,
      showSpinner: false,
    });
  };

  saveProfileChanges = () => {
    const { fNameEdited, lNameEdited, userName } = this.state;
    const { editProfile } = this.props;

    if (fNameEdited === "" || lNameEdited === "") {
      this.setState({ errorMessageProfile: "Please fill details to save" });
    } else {
      editProfile(userName, fNameEdited, lNameEdited);

      this.setState({
        firstName: fNameEdited,
        lastName: lNameEdited,
      });
    }
  };

  handleInputChange = (event) => {
    const field = event.target.name;
    this.setState({
      [field]: event.target.value,
    });

    if (field === "fNameEdited" || field === "lNameEdited") {
      this.setState({ errorMessageProfile: "" });
    } else {
      this.setState({ errorMessage: "" });
    }
  };

  submitPassword = () => {
    const { password, confirmPassword, newPassword } = this.state;
    const { changePassword } = this.props;

    if (password === "" || newPassword === "" || confirmPassword === "") {
      this.setState({ errorMessage: "Please fill details to save" });
    } else if (newPassword !== confirmPassword) {
      this.setState({
        errorMessage: "New password and confirm password doesn't match",
      });
    } else {
      this.setState({ showSpinner: true });
      changePassword(password, newPassword);
    }
  };

  toggleMode = (modeName) => {
    this.setState({ [modeName]: !this.state[modeName] });
    if (modeName === "showEditProfile") {
      const { firstName, lastName } = this.state;
      this.setState({ fNameEdited: firstName, lNameEdited: lastName });
    }
  };

  cancelPassword = () => {
    this.setState({ ...initialPasswordState, errorMessage: "" });
  };

  cancelEditProfile = () => {
    this.setState({ ...initialProfileState, errorMessageProfile: "" });
  };

  deleteToken = () => {
    localStorage.removeItem("token");
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.submitPassword();
    }
  };
}

const mapStateToProps = (state) => ({
  profileInfo: state.profileInfoReducer,
  updateProfileReducer: state.updateProfileReducer,
  changePasswordReducer: state.changePasswordReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getProfile: () => {
    dispatch(getProfile());
  },
  editProfile: (userName, firstName, lastName) => {
    dispatch(editProfile(userName, firstName, lastName));
  },
  changePassword: (password, newPassword) => {
    dispatch(changePassword(password, newPassword));
  },
  clearReducer: (name) => {
    dispatch(clearReducer(name));
  },
});

Profile.propTypes = {
  profileInfo: PropTypes.object,
  getProfile: PropTypes.func,
  editProfile: PropTypes.func,
  changePassword: PropTypes.func,
  updateProfileReducer: PropTypes.object,
  changePasswordReducer: PropTypes.object,
  history: PropTypes.object,
  clearReducer: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
