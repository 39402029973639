import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { callRequestAPI } from "../../services/callApi";
import Skeleton from "react-loading-skeleton";

export default function ImmozyqualifySettings(props) {
  const [graphData, setgraphData] = useState(null);
  const [sectionData, setsectionData] = useState(null);

  const { setisLoading, showToasterSuccess, showToasterError, isLoading } = props;

  useEffect(
    () => {
      !graphData && getGraphData();
      !sectionData && getSectionData();
    },
    // eslint-disable-next-line
    []
  );

  const getGraphData = async (stopLoader) => {
    setisLoading(true);
    const response = await callRequestAPI(`v1/qualify/graph`, null, "get");

    if (response.status === 200 && response.data) {
      setgraphData(response.data.data[0]);
      stopLoader && setisLoading(false);
    } else {
      setisLoading(false);
    }
  };

  const getSectionData = async () => {
    setisLoading(true);
    const response = await callRequestAPI(`v1/qualify/section`, null, "get");

    if (response.status === 200 && response.data) {
      setisLoading(false);
      setsectionData(response.data.data[0]);
    } else {
      setisLoading(false);
      showToasterError("something went wrong");
    }
  };

  const updateGraphData = async () => {
    setisLoading(true);

    const id = graphData.Id;

    let payload = graphData;
    delete payload.createddate;
    delete payload.updateddate;
    delete payload.type;
    delete payload.Id;

    const response = await callRequestAPI(
      `v1/qualify/${id}/graph`,
      payload,
      "putHeader"
    );

    if (response.status === 200) {
      setisLoading(false);
      showToasterSuccess("Changes applied successfully.");
      getGraphData(true);
    } else {
      setisLoading(false);
      showToasterError("something went wrong");
    }
  };

  const updateSectionData = async () => {
    setisLoading(true);
    const id = sectionData.Id;
    const payload = {
      document: sectionData.document,
      finance: sectionData.finance,
      onboarding: sectionData.onboarding,
      profile: sectionData.profile,
    };

    const response = await callRequestAPI(
      `v1/qualify/${id}/section`,
      payload,
      "putHeader"
    );

    if (response.status === 200) {
      setisLoading(false);
      showToasterSuccess("Changes applied successfully.");
      getSectionData();
    } else {
      setisLoading(false);
      showToasterError("something went wrong");
    }
  };

  const handleChange = (event) => {
    setgraphData({
      ...graphData,
      [event.target.id]: {
        ...graphData[event.target.id],
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleChangeMsg = (event) => {
    setgraphData({
      ...graphData,
      [event.target.id]: {
        ...graphData[event.target.id],
        message: {
          ...graphData[event.target.id].message,
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  const handleSectionChange = (event) => {
    if (event.target.name === "en" || event.target.name === "de") {
      // sectionData[event.target.id].hint.description[event.target.name] =
      //   event.target.value;
      // console.log(sectionData[event.target.id].hint.description);
      setsectionData({
        ...sectionData,
        [event.target.id]: {
          ...sectionData[event.target.id],
          hint: {
            description: {
              ...sectionData[event.target.id].hint.description,
              [event.target.name]: event.target.value,
            },
          },
        },
      });
    } else {
      setsectionData({
        ...sectionData,
        [event.target.id]: {
          ...sectionData[event.target.id],
          [event.target.name]: event.target.value,
        },
      });
    }
  };

  const getGraphSection = (range, index) => {
    const data = graphData[range.name];

    return (
      <div key={index} style={{ width: "32%" }}>
        <h5 className="input-title mt-3 mb-4">Range Category: {range.label}</h5>
        <div className="d-flex align-items-center section">
          <div>
            <div className="d-flex align-items-center mb-3">
              <label className="input-label">Range</label>
              <div className="w-100 d-flex align-items-center justify-content-between mr-3">
                <input
                  style={{ width: "43%" }}
                  type="text"
                  value={data.min_range}
                  className="form-control"
                  name="min_range"
                  onChange={handleChange}
                  id={range.name}
                />
                -
                <input
                  style={{ width: "43%" }}
                  type="text"
                  value={data.max_range}
                  className="form-control"
                  name="max_range"
                  id={range.name}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="d-flex align-items-center mb-3">
              <label className="input-label">Message: DE</label>
              <input
                type="text"
                value={data.message.de}
                className="mr-3 form-control"
                name="de"
                onChange={handleChangeMsg}
                id={range.name}
              />
            </div>
            <div className="d-flex align-items-center mb-3">
              <label className="input-label">Message: EN</label>
              <input
                type="text"
                value={data.message.en}
                className="mr-3 form-control"
                name="en"
                onChange={handleChangeMsg}
                id={range.name}
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <label className="input-label">color</label>
              <input
                type="color"
                value={data.color}
                onChange={handleChange}
                className="mr-3 form-control"
                name={"color"}
                id={range.name}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const rangearray = [
    { label: "Low", name: "low_category" },
    { label: "Medium", name: "medium_category" },
    { label: "High", name: "high_category" },
  ];

  return (
    <div className="immozy-qualify-container">
      {isLoading
        ? (
          <Skeleton
            height={"300px"}
            baseColor='#ddd4d7'
          />
        ) : (
          <>
            <p className="title">Progress Graph</p>

            <div className="d-flex align-items-center">
              {graphData &&
                rangearray.map((range, index) => getGraphSection(range, index))}
            </div>
            <div>
              <button onClick={updateGraphData} className="btn btn-primary mt-3 mb-3">
                Update Graph
              </button>
            </div>
            <hr />
            <div>
              {sectionData &&
                sections.map((section) => (
                  <div key={section.id} className="mt-3">
                    <h5 className="input-title mb-3">{section.label}</h5>

                    <div className="d-flex align-items-start">
                      <label className="input-label">Hint Text: DE</label>
                      <textarea
                        className="form-control"
                        style={{
                          width: "350px",
                          minHeight: "150px",
                          marginRight: "2%",
                        }}
                        value={sectionData[section.name].hint.description.de}
                        id={section.name}
                        name={"de"}
                        onChange={handleSectionChange}
                      />
                      <label className="input-label">Hint Text: EN</label>
                      <textarea
                        className="form-control"
                        style={{ width: "350px", minHeight: "150px" }}
                        value={sectionData[section.name].hint.description.en}
                        id={section.name}
                        name={"en"}
                        onChange={handleSectionChange}
                      />
                    </div>
                    <div className="d-flex align-items-center mb-3 mt-3">
                      <label className="input-label">Order</label>
                      <div className="d-flex" style={{ width: "350px" }}>
                        <input
                          style={{ width: "45%", marginRight: "2%" }}
                          type="number"
                          value={sectionData[section.name].order_number}
                          className="mr-2 form-control"
                          name={"order_number"}
                          id={section.name}
                          onChange={handleSectionChange}
                        />
                        <label className="input-label ml-3">Weightage</label>
                        <input
                          style={{ width: "45%", marginLeft: "2%" }}
                          type="number"
                          value={sectionData[section.name].weightage}
                          className="ml-2 form-control"
                          name={"weightage"}
                          id={section.name}
                          onChange={handleSectionChange}
                        />
                        &nbsp;%
                      </div>
                    </div>
                    <div className="d-flex align-items-start mt-3">
                      <label className="input-label">Badge</label>
                      <select
                        className="form-control"
                        style={{ width: "333px", height: "35px" }}
                        id={section.name}
                        name={"badge_name"}
                        onChange={handleSectionChange}
                        value={sectionData[section.name].badge_name}
                      >
                        {importanceOptions.map((imp, index) => (
                          <option key={index} value={imp.vlue}>
                            {imp.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <hr />
                  </div>
                ))}
              <div>
                <button
                  onClick={() => updateSectionData()}
                  className="btn btn-primary mt-5"
                >
                  Update Sections Details
                </button>
              </div>
            </div>
          </>
        )}
    </div>
  );
}

const sections = [
  {
    label: "Personal Information",
    hintText: "Personal Information",
    importance: "wichtig",
    name: "profile",
    id: 1,
    order: 1,
  },
  {
    label: "Onboarding",
    hintText: "Personal Information",
    importance: "wichtig",
    id: 2,
    order: 2,
    name: "onboarding",
  },
  {
    label: "Upload Documents",
    hintText: "Personal Information",
    importance: "wichtig",
    id: 3,
    order: 3,
    name: "finance",
  },
];

const importanceOptions = [
  { label: "Wichtig", value: "wichtig" },
  { label: "Weniger wichtig", value: "weniger wichtig" },
  { label: "Sehr wichtig", value: "Sehr wichtig" },
  { label: "No Badge (Not visible on screen)", value: "No badge" },
];

ImmozyqualifySettings.propTypes = {
  isLoading: PropTypes.bool,
  setisLoading: PropTypes.func,
  showToasterSuccess: PropTypes.func,
  showToasterError: PropTypes.func,
};
