import callAPI from "../services/callApi";

export const getProfile = () => ({
  type: "FETCH_RPROFILE_INFO",
  payload: callAPI(`v1/profile`, "GET"),
});

export const editProfile = (userName, firstName, lastName, avatar = "") => ({
  type: "EDIT_RPROFILE_INFO",
  payload: callAPI(`v1/profile`, "PUT", {
    userName,
    firstName,
    lastName,
    avatar,
  }),
});
