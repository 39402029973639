import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getUsersList,
  changeUserStatus,
  changeUserType,
} from "../../actions/userActions";
import { clearReducer } from "../../actions/clearAction";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ModalComponent from "../../utils/modalComponent";
import { selectFilter } from "react-bootstrap-table2-filter";
import { tableOptions, getDateWithTime } from "../../utils/tableData";
import "../../assets/css/tableStyle.css";
import TableComponent from "../common/tableComponent";

class UserManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userList: [],
      pageSize: 10,
      isLoading: true,
      showModal: false,
    };
  }

  componentDidMount() {
    const { getUsersList } = this.props;
    getUsersList();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const { userListReducer, userStatusReducer, clearReducer } = this.props;

    const { isLoading } = this.state;

    if (
      prevProps.userListReducer !== userListReducer &&
      userListReducer.status === "success" &&
      userListReducer.data &&
      isLoading
    ) {
      const result = this.setProperties(userListReducer.data.data);
      this.setState({ userList: result, isLoading: false });
      clearReducer("CLEAR_USER_LIST");
    }

    if (
      prevProps.userStatusReducer !== userStatusReducer &&
      userStatusReducer.status === "success" &&
      userStatusReducer.data &&
      isLoading
    ) {
      const { getUsersList } = this.props;
      getUsersList();
      clearReducer("CLEAR_USER_STATUS");
    }
  }

  setProperties = (data) => {
    let list = [];
    data.forEach((prop, index) => {
      const a = {
        sr: index + 1,
        userId: prop.userId,
        email: prop.email,
        firstName: prop.firstName || "-",
        isEmailVerified: prop.isEmailVerified ? "Completed" : "Pending",
        lastName: prop.lastName || "-",
        newStatus: prop.newStatus,
        tmpusertype: prop.tmpusertype,
        lastLoginDate: prop.lastLoginDate ? getDateWithTime(prop.lastLoginDate) : "-",
        createdDate: getDateWithTime(prop.createdDate),
        lastActiveDate: getDateWithTime(prop.lastActiveDate),
      };

      list.push(a);
    });

    return list;
  };

  headerStyle = () => ({
    textAlign: "center",
    verticalAlign: "top",
  });

  redirectComponent = (cell, row) => (
    <Link
      to={`/user-detail/${row.userId}`}
      style={{ cursor: "pointer", color: "#000" }}
    >
      {cell}
      <i
        style={{ marginLeft: "5px" }}
        className="fa fa-link"
        aria-hidden="true"
      ></i>
    </Link>
  );

  actionFormatter = (cell, row) => {
    const status =
      row.newStatus === "BLOCKED" ? "activate the user" : "deactivate the user";
    const typeTitle =
      row.tmpusertype === "NORMAL"
        ? "Change the user to Tester"
        : "Change the user to Normal";

    return (
      <div>
        <span
          className="waves-effect waves-dark pcoded-micon"
          onClick={() => this.toggleModal(row.userId, status)}
          style={{ fontSize: "20px", cursor: "pointer" }}
          title={status}
        >
          <i
            className={
              row.newStatus === "BLOCKED"
                ? "fa fa-user-circle"
                : "fa fa-power-off"
            }
          ></i>
        </span>
        <span
          className="waves-effect waves-dark pcoded-micon"
          onClick={() => this.handleUSertypeChange(row)}
          style={{ fontSize: "20px", cursor: "pointer" }}
        >
          <i
            className="fa fa-retweet ml-3"
            style={
              row.tmpusertype === "NORMAL"
                ? { color: "#000" }
                : { color: "#932849" }
            }
            title={typeTitle}
          ></i>
        </span>
      </div>
    );
  };

  handleUSertypeChange = (row) => {
    const { changeUserType } = this.props;
    changeUserType(
      row.userId,
      row.tmpusertype === "NORMAL" ? "TESTER" : "NORMAL"
    );
    this.setState({ isLoading: true });
  };

  toggleModal = (userId, status) => {
    if (userId) {
      this.setState({
        userId,
        showModal: true,
        titleMsg: `Are you sure you want to ${status}?`,
      });
    } else {
      this.setState({ showModal: false });
    }
  };

  handleDeactivate = () => {
    const { userId } = this.state;
    const { changeUserStatus } = this.props;
    changeUserStatus(userId);
    this.setState({ isLoading: true, showModal: false });
  };

  render() {
    const { userList, pageSize, isLoading, showModal } = this.state;

    const columns = [
      {
        dataField: "sr",
        text: "Sr. No",
        sort: true,
        headerStyle: {
          textAlign: "center",
          verticalAlign: "top",
          width: "5%",
        },
      },
      {
        dataField: "firstName",
        text: "First Name",
        sort: true,
        headerStyle: this.headerStyle,
      },
      {
        dataField: "lastName",
        text: "Last Name",
        sort: true,
        headerStyle: this.headerStyle,
      },
      {
        dataField: "email",
        text: "Email",
        headerStyle: this.headerStyle,
        formatter: this.redirectComponent,
      },
      {
        dataField: "newStatus",
        text: "Status",
        sort: true,
        headerStyle: this.headerStyle,
        formatter: this.statusComponent,
        filter: selectFilter({
          className: "my-custom-select-filter",
          options: statusFilter,
        }),
      },
      {
        dataField: "createdDate",
        text: "SignUp Date",
        sort: true,
        headerStyle: this.headerStyle,
      },
      {
        dataField: "lastActiveDate",
        text: "LastActive Date",
        sort: true,
        headerStyle: this.headerStyle,
      },
      {
        dataField: "lastLoginDate",
        text: "Last Login",
        sort: true,
        headerStyle: this.headerStyle,
      },
      {
        dataField: "actions",
        text: "Actions",
        formatter: this.actionFormatter,
        headerStyle: this.headerStyle,
      },
    ];

    const options = {
      ...tableOptions,
      sizePerPage: pageSize,
    };

    return (
      <div className="pcoded-content fade-in">
        <div className="pcoded-main-container" style={{ marginTop: "none" }}>
          <div className="pcoded-wrapper">
            <div className="page-header card" style={{ border: "none" }}>
              <div className="row align-items-end">
                <div className="col-lg-4">
                  <div className="page-header-title">
                    <i
                      className="feather icon-users"
                      aria-hidden="true"
                      style={{ background: "#932849" }}
                    ></i>
                    <div>
                      <h5
                        className="header-style"
                        style={{ display: "inline-block" }}
                      >
                        User Management
                      </h5>
                    </div>
                    {/* {(userList.length === 0 || isLoading) && (
                      <i
                        className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                        title="Loading data..."
                      />
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-body">
                  <div className="card">
                    <div className="card-block">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12">
                          <TableComponent
                            data={userList}
                            columns={columns}
                            paginationOptions={options}
                            isLoading={isLoading}
                            tableSearch
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <ModalComponent
            title={"Confirmation"}
            bodyMsg={this.state.titleMsg}
            closeModal={() => this.toggleModal()}
            handleYes={this.handleDeactivate}
            btnText="No"
          />
        )}
      </div>
    );
  }

  statusComponent = (cell) => {
    let color = "orange";
    let bgColor = "rgb(255, 165, 0, .7)";

    if (cell === "ACTIVE") {
      color = "green";
      bgColor = "rgba(51, 170, 51, .7)";
    }
    if (cell === "BLOCKED") {
      color = "red";
      bgColor = "#d88888";
    }
    return (
      <div>
        <label
          className="status-label status-label-doc"
          style={{
            border: `1px solid ${color}`,
            background: bgColor,
          }}
        >
          {cell}
        </label>
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  getUsersList: (page) => {
    dispatch(getUsersList(page));
  },
  changeUserStatus: (id) => {
    dispatch(changeUserStatus(id));
  },
  changeUserType: (id, type) => {
    dispatch(changeUserType(id, type));
  },
  clearReducer: (name) => {
    dispatch(clearReducer(name));
  },
});

const mapStateToProps = (state) => ({
  userListReducer: state.userListReducer,
  userStatusReducer: state.userStatusReducer,
});

UserManagement.propTypes = {
  getUsersList: PropTypes.func,
  userListReducer: PropTypes.object,
  changeUserStatus: PropTypes.func,
  userStatusReducer: PropTypes.object,
  changeUserType: PropTypes.func,
  clearReducer: PropTypes.func,
};

// const stateFilter = [
//   { value: "NORMAL", label: "NORMAL" },
//   { value: "TESTER", label: "TESTER" },
// ];

const statusFilter = [
  { value: "ACTIVE", label: "ACTIVE" },
  { value: "PENDING", label: "PENDING" },
  { value: "BLOCKED", label: "BLOCKED" },
];

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
