import React, { Component } from "react";
import PropTypes from "prop-types";
import backArrow from "../../assets/images/whitearrow.png";
import { Link } from "react-router-dom";
import { getObjectDetails } from "../../actions/objectActions";
import { connect } from "react-redux";
import { createDetailsObject } from "./objectListColumns";
import ImageGallery from "react-image-gallery";
import { BASE_URL } from "../../services/baseUrl";
import "react-image-gallery/styles/css/image-gallery.css";

let objId = "";
let fromUser = false;

class ObjectDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imgList: [],
      objectDetails: {},
      contactDetails: {},
      showSpinner: true,
      activetab: "Details",
      gereneralArray: [],
      locationArray: [],
      noObjectError: false,
      realtorInfo: [],
    };

    this.getObjectId();
  }

  componentDidMount() {
    const { getObjectDetails } = this.props;
    getObjectDetails(objId);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    fromUser = false;
  }

  componentDidUpdate(prevProps) {
    const { objectDetailReducer } = this.props;

    if (
      objectDetailReducer !== prevProps.objectDetailReducer &&
      objectDetailReducer.status === "success" &&
      objectDetailReducer.data
    ) {
      this.getDetailsArray(objectDetailReducer.data);
      objectDetailReducer.data.new_realtorid &&
        this.createRealtorObject(objectDetailReducer.data);

      if (
        objectDetailReducer.data.misc &&
        objectDetailReducer.data.misc.obj_data
      ) {
        const objectDetails = this.getDetail(
          objectDetailReducer.data.misc.obj_data
        );
        this.setState({ objectDetails });
      }
      let imgList = this.setImage(objectDetailReducer);
      this.setState({
        imgList,
        showSpinner: false,
      });
    }

    if (
      objectDetailReducer !== prevProps.objectDetailReducer &&
      objectDetailReducer.status === "fail"
    ) {
      this.setState({ showSpinner: false, noObjectError: true });
    }
  }

  render() {
    const {
      showSpinner,
      activetab,
      detailsArray,
      imgList,
      noObjectError,
      objectDetails,
    } = this.state;
    const { match, closeModal } = this.props;
    const tabs = [
      "Details",
      "Realtor Connection Data",
      //   "Documents",
      //   "Statistics",
      //   "Update-History",
    ];

    return (
      <div className={match && "pcoded-content fade-in"}>
        {!match && (
          <div className="cross-div">
            <Link
              className="new-link"
              to={`/object-detail/${objId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open link in new tab
              <i className="fa fa-link" aria-hidden="true"></i>
            </Link>
            <button type="button" onClick={() => closeModal()}>
              <i className="fa fa-close"></i>
            </button>
          </div>
        )}

        <div
          className={match ? "pcoded-main-container" : "popup-div"}
          style={{ marginTop: "none" }}
        >
          <div className="page-header card" style={{ border: "none" }}>
            <div className="row align-items-end">
              <div className="col-lg-4">
                <div className="page-header-title">
                  {match && (
                    <Link to={fromUser ? "/user" : "/objects"}>
                      <img
                        src={backArrow}
                        className="back-arrow"
                        alt="img"
                      ></img>
                    </Link>
                  )}
                  <div>
                    <h5
                      className="header-style"
                      style={{ display: "inline-block" }}
                    >
                      Objects Details
                    </h5>
                  </div>

                  {showSpinner && (
                    <i
                      className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                      title="Loading data..."
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="pcoded-inner-content">
            <div className="main-body innerpage">
              <div className="page-wrapper">
                <div className="page-body">
                  <div className="card">
                    <div className="card-block">
                      <div className="nav-tabs-wrapper d-flex flex-column flex-md-row justify-content-between align-items-md-center align-items-start">
                        <ul
                          className="nav nav-tabs order-1 order-md-0"
                          id="myTab"
                          role="tablist"
                        >
                          {tabs.map((tab, index) => (
                            <li className="nav-item" key={index}>
                              <Link
                                className={
                                  activetab === tab
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                id="home-tab"
                                role="tab"
                                name={tab}
                                onClick={() => this.changeActiveTab(tab)}
                              >
                                {tab}
                              </Link>
                            </li>
                          ))}
                        </ul>
                        <a
                          className="search-btn"
                          href={
                            BASE_URL.includes("staging")
                              ? `http://staging.immozy.de/object-detail/${objId}`
                              : `https://app.immozy.de/object-detail/${objId}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ fontSize: "16px" }}
                        >
                          Go to Site Detail Page{" "}
                        </a>
                      </div>
                      {activetab === "Details" && (
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="mt-2 tab-pane fade show active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            {noObjectError && (
                              <h5
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                No Information found for this object
                              </h5>
                            )}
                            {imgList.length > 0 && (
                              <div className="img-div">
                                <label className="mt-3 mb-3 label-style">
                                  Picture Gallery
                                </label>
                                <ImageGallery
                                  className="search-img"
                                  items={imgList}
                                  showPlayButton={false}
                                  showThumbnails={false}
                                  showFullscreenButton={false}
                                  lazyLoad={true}
                                  disableKeyDown={true}
                                  showBullets={imgList.length > 1}
                                />
                              </div>
                            )}

                            {objectDetails && objectDetails.length > 0 && (
                              <table className="mt-5">
                                <tbody>
                                  <label className="mt-3 mb-3 label-style">
                                    Object Information
                                  </label>
                                  {objectDetails.map((property, index) => (
                                    <tr key={index}>
                                      <td>
                                        <label className="mb-0 key-label">
                                          {property.label}
                                        </label>
                                      </td>
                                      <td>
                                        <label className="mb-0 value-label">
                                          {property.value}
                                        </label>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                            {detailsArray &&
                              detailsArray.map((element, index) => (
                                <table key={index}>
                                  <tbody>
                                    <tr>
                                      <th>
                                        <label className="mt-3 mb-3 label-style">
                                          {element.label}
                                        </label>
                                      </th>
                                    </tr>
                                    {element.value.length > 0 &&
                                      element.value.map((property, index) => (
                                        <tr key={index}>
                                          <td>
                                            <label className="mb-0 key-label">
                                              {property.key}
                                            </label>
                                          </td>
                                          {/* //here */}
                                          <td>
                                            {property.key === "Ad Platform" ? (
                                              property.value.length > 0 &&
                                              property.value.map(
                                                (platformData, ind) => (
                                                  <li key={ind}>
                                                    <a
                                                      href={platformData.url}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      <label className="mb-0">
                                                        {platformData.platform}
                                                      </label>
                                                    </a>
                                                  </li>
                                                )
                                              )
                                            ) : (
                                              <label className="mb-0 value-label">
                                                {property.value}
                                              </label>
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              ))}
                          </div>
                        </div>
                      )}
                      {activetab === "Realtor Connection Data" && (
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="mt-2 tab-pane fade show active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <div>
                              <div>
                                <table>
                                  <tbody>
                                    {this.state.realtorInfo.length > 0 && (
                                      <tr>
                                        <th>
                                          <label className="mt-3 mb-3 label-style">
                                            Match Param
                                          </label>
                                        </th>
                                        <th>
                                          <label className="mt-3 mb-3 label-style">
                                            Object Data{" "}
                                          </label>
                                        </th>
                                        <th>
                                          <label className="mt-3 mb-3 label-style">
                                            Connected Realtor Data{" "}
                                          </label>
                                        </th>
                                      </tr>
                                    )}
                                    {this.state.realtorInfo.length > 0 ? (
                                      this.state.realtorInfo.map(
                                        (realtor, index) => (
                                          <tr key={index}>
                                            <td>
                                              <label className="mb-0 key-label">
                                                {realtor.label}
                                              </label>
                                            </td>
                                            <td
                                              style={
                                                realtor.match
                                                  ? { background: "#69f19354" }
                                                  : {}
                                              }
                                            >
                                              <label className="mb-0 value-label">
                                                {realtor.value || "N/a"}
                                              </label>
                                            </td>

                                            <td
                                              style={
                                                realtor.match
                                                  ? { background: "#69f19354" }
                                                  : {}
                                              }
                                            >
                                              <label className="mb-0 value-label">
                                                {realtor.valueRealtor || "N/a"}
                                              </label>
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr>
                                        <div className="no-realtor-div">
                                          No Realtor connected to this objects
                                        </div>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  createRealtorObject = (data) => {
    const realtorData = data.new_realtor;
    let realtorInfoArray = [];
    // let isMatched = false;

    const fields = [
      { name: "agent_id", r_name: "agent_id", label: "Agent Id" },
      {
        name: "provider_email_address",
        r_name: "match_email_address",
        label: "Match Email Address",
      },
      { name: "provider_email_address", r_name: "domain", label: "Domain" },
      {
        name: "provider_company_name",
        r_name: "provider_company_name",
        label: "Company Name",
      },
    ];

    fields.forEach((field) => {
      realtorInfoArray.push({
        label: field.label,
        value:
          field.r_name === "domain" && data[field.name]
            ? data[field.name].substring(
              data[field.name].lastIndexOf("@") + 1,
              data[field.name].length
            )
            : data[field.name],
        valueRealtor: realtorData[field.r_name],
        // match: isMatched ? false : this.checkMatch(data, realtorData, field),
        match: this.checkMatch(data, realtorData, field),
      });
    });

    this.setState({
      realtorInfo: realtorInfoArray,
    });
  };

  checkMatch = (data, realtorData, field) => {
    const objFieldValue = data[field.name] || "";
    const realtorFieldValue = realtorData[field.r_name] || "";
    let isSame = false;
    if (
      field.label === "Company Name" ||
      field.label === "Match Email Address"
    ) {
      isSame =
        objFieldValue !== "N/a" &&
        objFieldValue !== "" &&
        // eslint-disable-next-line
        objFieldValue.toLowerCase() == realtorFieldValue.toLowerCase();
    } else if (field.label === "Domain") {
      isSame =
        objFieldValue !== "N/a" &&
        objFieldValue !== "" &&
        objFieldValue.toLowerCase().includes(realtorFieldValue.toLowerCase());
    } else if (
      field.label === "Agent Id" &&
      objFieldValue &&
      objFieldValue !== "N/a" &&
      realtorFieldValue
    ) {
      // eslint-disable-next-line
      isSame = parseInt(objFieldValue) == parseInt(realtorFieldValue);
    }
    return isSame;
  };

  getDetail = (objectData) => {
    let data = [];
    objectData.title && data.push({ label: "Title", value: objectData.title });

    objectData.address1 &&
      data.push({ label: "Address line 1", value: objectData.address1 });

    objectData.address2 &&
      data.push({ label: "Address line 2", value: objectData.address2 });

    return data;
  };

  setImage = (objectDetailReducer) => {
    let imgList = [];
    const list = objectDetailReducer.data.ad_pictures_urls;
    list &&
      list.forEach((im) => {
        const images = {
          original: im,
        };
        imgList.push(images);
      });
    return imgList;
  };

  getObjectId = () => {
    const { match, objectId } = this.props;
    objId = match && match.params ? match.params.id : objectId;

    if (match && match.params.from && match.params.from === "user") {
      fromUser = true;
    }
  };

  getDetailsArray = (propertyInfo) => {
    let detailsArray = createDetailsObject(propertyInfo);
    this.setState({ detailsArray });
  };

  changeActiveTab = (name) => {
    this.setState({ activetab: name });
  };
}

const mapDispatchToProps = (dispatch) => ({
  getObjectDetails: (id) => {
    dispatch(getObjectDetails(id));
  },
});

const mapStateToProps = (state) => ({
  objectDetailReducer: state.objectDetailReducer,
});

ObjectDetail.propTypes = {
  match: PropTypes.object,
  getObjectDetails: PropTypes.func,
  objectDetailReducer: PropTypes.object,
  objectId: PropTypes.string,
  closeModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectDetail);
