import React, { Component } from "react";
import PropTypes from "prop-types";
import { resetPassword } from "../../actions/authActions";
import { connect } from "react-redux";
import "./auth.css";

let token = "";
class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      confirmPassword: "",
      isError: false,
      errorMessage: "",
      showPwd: false,
      showPwdConfirm: false,
    };

    this.getToken();
  }

  getToken = () => {
    const { match } = this.props;
    token = match.params.token;
  };

  componentDidUpdate(prevProps) {
    const { changePasswordReducer } = this.props;

    if (
      prevProps.changePasswordReducer !== changePasswordReducer &&
      changePasswordReducer.status === "success"
    ) {
      alert(
        "Your password has been changed successfully. Please login with new password to continue"
      );
      window.location = "/login";
    }
    if (
      prevProps.changePasswordReducer !== changePasswordReducer &&
      changePasswordReducer.status === "fail"
    ) {
      let msg = "";

      msg = changePasswordReducer.data
        ? changePasswordReducer.data.data.message
        : "Something went wrong";

      this.setState({
        errorMessage: msg,
        isError: true,
      });
    }
  }

  resetPasswordClick = () => {
    const { password, confirmPassword } = this.state;
    const { resetPassword } = this.props;

    if (password === "" || confirmPassword === "") {
      this.setState({
        errorMessage: "Please enter password",
        isError: true,
      });
    } else if (password !== confirmPassword) {
      this.setState({
        errorMessage: "Password and confirm password doesn't match",
        isError: true,
      });
    } else {
      resetPassword(token, password);
    }
  };

  handlePassword = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ isError: false, errorMessage: "" });
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.resetPasswordClick();
    }
  };

  render() {
    const {
      password,
      confirmPassword,
      isError,
      errorMessage,
      showPwd,
      showPwdConfirm,
    } = this.state;

    return (
      <div>
        <section className="login-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <form className="md-float-material form-material">
                  <div className="auth-box card">
                    <div className="card-block">
                      <p className="p-b-5 p-t-5" style={{ fontSize: "19px" }}>
                        Enter a new password to reset the password
                      </p>
                      <div className="form-group form-primary">
                        <input
                          type={showPwd ? "text" : "password"}
                          name="password"
                          className="form-control input-login-style"
                          value={password}
                          onChange={this.handlePassword}
                          placeholder="New password"
                          onKeyDown={(event) => this.onKeyDown(event)}
                        />
                        <span
                          onClick={() => this.setState({ showPwd: !showPwd })}
                        >
                          {showPwd ? (
                            <i className="pwd-icon fa fa-eye-slash"></i>
                          ) : (
                            <i className="pwd-icon fa fa-eye"></i>
                          )}
                        </span>
                        <input
                          type={showPwdConfirm ? "text" : "password"}
                          name="confirmPassword"
                          className="form-control input-login-style"
                          value={confirmPassword}
                          onChange={this.handlePassword}
                          placeholder="Confirm password"
                          style={{
                            marginTop: "10px",
                          }}
                          onKeyDown={(event) => this.onKeyDown(event)}
                        />
                        <span
                          onClick={() =>
                            this.setState({ showPwdConfirm: !showPwdConfirm })
                          }
                        >
                          {showPwdConfirm ? (
                            <i className="eye-con fa fa-eye-slash"></i>
                          ) : (
                            <i className="eye-con fa fa-eye"></i>
                          )}
                        </span>
                        {isError && (
                          <div className="col-sm-12">
                            <label className="error-msg-label">
                              {errorMessage}
                            </label>
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <button
                            type="button"
                            className="btn btn-primary btn-md btn-block waves-effect text-center m-b-20"
                            onClick={(event) => this.resetPasswordClick(event)}
                          >
                            Reset password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  changePasswordReducer: state.changePasswordReducer,
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (token, password) => {
    dispatch(resetPassword(token, password));
  },
});

ResetPassword.propTypes = {
  match: PropTypes.object,
  changePasswordReducer: PropTypes.object,
  resetPassword: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
