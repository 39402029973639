export const INITIAL_STATE = {
  response: [],
};

export const profileInfoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_RPROFILE_INFO_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : null,
      };

    case "FETCH_RPROFILE_INFO_PENDING":
      return {
        status: "pending",
      };

    default:
      return {
        ...state,
      };
  }
};

export const updateProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "EDIT_RPROFILE_INFO_FULFILLED":
      return {
        status: "success",
        data: action.payload,
      };

    case "EDIT_RPROFILE_INFO_PENDING":
      return {
        status: "pending",
      };

    case "CLEAR_PROFILE_INFO":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};
