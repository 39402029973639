import React, { Component } from "react";
import { callRequestAPI } from "../../services/callApi";
import AnimatedNumber from "animated-number-react";
import Skeleton from "react-loading-skeleton";

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: 0,
            admin: 0,
            question: 0,
            isLoading: true
        };
    }

    async componentDidMount() {
        const response = await callRequestAPI(`v1/dashboard`, null, "get");

        if (response.status === 200 && response.data) {
            const data = response.data.data;
            this.setState({
                user: data.userCount,
                admin: data.adminCount,
                question: data.questionCount,
                isLoading: false,
            });
        } else {
            this.setState({ isLoading: false });
        }
    }

    formatValue = (value) => value.toFixed(0);

    render() {
        const { user, admin, question, isLoading } = this.state;
        const dashboardArray = [
            {
                label: "Total User",
                value: user,
                icon: "fa feather icon-users bg-c-blue",
            },
            { label: "Total Questions", value: question, icon: "fa fa-question" },
            {
                label: "Total Admin",
                value: admin,
                icon: "fa fa-user-circle",
            },
        ];

        return (
            <div className="pcoded-content fade-in">
                <div
                    className="pcoded-main-container"
                    style={{ marginTop: "none" }}
                >
                    <div className="pcoded-wrapper">
                        <div
                            className="page-header card"
                            style={{ border: "none" }}
                        >
                            <div className="row align-items-end">
                                <div className="col-lg-12">
                                    <div className="page-header-title">
                                        <i className="feather icon-home bg-c-blue"></i>
                                        <div className="d-flex">
                                            <h5 className="header-style">
                                                Dashboard
                                            </h5>
                                            {/* {this.state.isLoading && (
                                                <i className="ml-2 fa fa-cog fa-spin fa-3x fa-fw spinner-gear" />
                                            )} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="page-body">
                                    <div
                                        className="row"
                                        style={{ marginLeft: "-5px" }}
                                    >
                                        {dashboardArray.map((item, index) => (
                                            <div
                                                className="col-xl-4 col-md-4"
                                                key={index}
                                            >
                                                <div className="card prod-p-card">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col">
                                                                {isLoading ? (
                                                                    <Skeleton
                                                                        width={"100px"}
                                                                        height={"20px"}
                                                                        baseColor='#ddd4d7'
                                                                    />
                                                                ) : (
                                                                    <h6 className="m-b-5 fade-in">
                                                                        {item.label}
                                                                    </h6>
                                                                )}

                                                                {isLoading ? (
                                                                    <Skeleton
                                                                        width={"70px"}
                                                                        height={"29px"}
                                                                        baseColor='#ddd4d7'
                                                                    />
                                                                ) : (
                                                                    <h3 className="m-b-0 f-w-700 dashboard-div fade-in">
                                                                        <AnimatedNumber
                                                                            value={[item.value]}
                                                                            formatValue={this.formatValue}
                                                                        />
                                                                    </h3>
                                                                )}
                                                            </div>
                                                            <div className="col-auto">
                                                                {isLoading ? (
                                                                    <Skeleton
                                                                        width={"40px"}
                                                                        height={"40px"}
                                                                        baseColor='#ddd4d7'
                                                                        circle
                                                                    />
                                                                ) : (
                                                                    <i
                                                                        className={`fade-in ${item.icon}`}
                                                                        aria-hidden="true"
                                                                        style={{ background: "#932849" }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default Dashboard;
