import React from "react";
import PropTypes from "prop-types";
import { tabConstants } from "./userFunctions";
import user from "../../assets/images/user-icon.png";
import { ConvertToSnakeCase } from "../../utils/utility";

function UserData(props) {
  const { detailArray, utmObject } = props;
  return (
    <div className="tab-content" id="myTabContent">
      <div
        className="mt-2 tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div className="card">
          <div className="card-header">
            <h5>{tabConstants.USER_DATA}</h5>
          </div>

          <div className="ml-4 tab-content mb-3" id="myTabContent">
            {detailArray &&
              detailArray.length > 0 &&
              detailArray.map((property, index) => (
                <tr key={index}>
                  <td>
                    <label className="mb-0 key-label">
                      {ConvertToSnakeCase(property[0]) || "-"}
                    </label>
                  </td>
                  <td>
                    <label className="mb-0 value-label">
                      {property[0] === "avatar" ? (
                        <img
                          className="detail-avatar"
                          src={
                            property[1] && property[1] !== ""
                              ? property[1]
                              : user
                          }
                          alt="avatar"
                        />
                      ) : (
                        property[1]
                      )}
                    </label>
                  </td>
                </tr>
              ))}
          </div>

          {utmObject && utmObject.length > 0 && (
            <>
              <div className="card-header">
                <h5>UTM Data</h5>
              </div>
              <div className="ml-4 tab-content mb-3" id="myTabContent">
                {utmObject.map((property, index) => (
                  <tr key={index}>
                    <td>
                      <label className="mb-0 key-label">{property[0]}</label>
                    </td>
                    <td>
                      <label className="mb-0 value-label">{property[1]}</label>
                    </td>
                  </tr>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

UserData.propTypes = {
  detailArray: PropTypes.array,
  utmObject: PropTypes.object,
};

export default UserData;
