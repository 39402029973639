import callAPI from "../services/callApi";

export const getObjectList = (data) => ({
  type: "FETCH_OBJECT_LIST",
  payload: callAPI(`v4/objects`, "POST", data),
});

export const getDuplicateObjectList = (data) => ({
    type: "FETCH_DUPLICATE_LIST",
    payload: callAPI(`v4/objects/duplicates`, "GET", data),
});


export const getObjectDetails = (id) => ({
  type: "FETCH_OBJECT_DETAILS",
  payload: callAPI(`v4/objects/details/${id}`, "GET"),
});

export const changeObjectStatus = (id) => ({
  type: "CHANGE_OBJ_STATUS",
  payload: callAPI(`v4/objects/changestatus/${id}`, "put"),
});

export const fetchQuarter = () => ({
  type: "FETCH_QUARTER",
  payload: callAPI(`v4/objects/dropdown/quarter`, "GET"),
});
