import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  getQuestionDetails,
  updateQuestion,
} from "../../actions/onboardingActions";
import { clearReducer } from "../../actions/clearAction";
import backArrow from "../../assets/images/whitearrow.png";
import {
  lang,
  inputTypes,
  questionCategories,
  stages,
  optionStructure,
  objStructure,
} from "../../utils/constants";
import ModalComponent from "../../utils/modalComponent";

let questionId = "";

class QuestionDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questionDetails: null,
      isEdit: false,
      currentLang: lang.EN,
      options: optionStructure,
      modalMsg: "",
      showModal: false,
      showSpinner: true,
    };

    this.getObjectId();
  }

  getObjectId = () => {
    const { match } = this.props;
    questionId = match.params.id;
  };

  componentDidMount() {
    const { getQuestionDetails } = this.props;
    getQuestionDetails(questionId);
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const {
      questionDetailReducer,
      updateQuestionReducer,
      clearReducer,
    } = this.props;
    const { isEdit } = this.state;

    if (
      prevProps.questionDetailReducer !== questionDetailReducer &&
      questionDetailReducer.status === "success"
    ) {
      this.setState({
        questionDetails: questionDetailReducer.data,
        showSpinner: false,
      });
      clearReducer("CLEAR_FETCH_QUESTION_DETAILS");
    }

    if (
      prevProps.updateQuestionReducer !== updateQuestionReducer &&
      updateQuestionReducer.status === "success" &&
      isEdit
    ) {
      this.setState({
        isEdit: false,
        showModal: true,
        modalMsg: "Question updated successfully",
      });
      questionId = updateQuestionReducer.data.questionId;
    }

    if (
      prevProps.updateQuestionReducer !== updateQuestionReducer &&
      updateQuestionReducer.status === "fail"
    ) {
      this.setState({ isEdit: false });
    }
  }

  closeModal = () => {
    this.setState({ showModal: false });
    const { history } = this.props;
    history.push(`/question-detail/${questionId}`);
  };

  toggleEditMode = () => {
    const { isEdit } = this.state;
    this.setState({ isEdit: !isEdit });
  };

  switchLang = () => {
    const { currentLang } = this.state;
    const newLang = currentLang === lang.EN ? lang.GR : lang.EN;
    this.setState({ currentLang: newLang });
  };

  removeOption = (index) => {
    const { questionDetails } = this.state;
    questionDetails.options.splice(index, 1);
    this.setState({ questionDetails });
  };

  addOption = () => {
    const { questionDetails } = this.state;
    let obj = { option: { ...objStructure }, type: inputTypes[0], tag: "" };
    questionDetails.options.push(obj);
    this.setState({ questionDetails });
  };

  handleQuestionChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => {
      let questionDetails = { ...prevState.questionDetails };
      questionDetails.question[key] = value;
      return { questionDetails };
    });
  };

  handleOptionChange = (index, lang) => (event) => {
    const { questionDetails } = this.state;
    questionDetails.options[index].option[lang] = event.target.value;
    this.setState({ questionDetails });
  };

  // handling for input type for options
  handleOptionDropdown = (index) => (event) => {
    const { questionDetails } = this.state;
    questionDetails.options[index].type = event.target.value;
    this.setState({ questionDetails });
  };

  // for category and stage of question
  handleDropdown = (event) => {
    const { questionDetails } = this.state;
    questionDetails[event.target.name] = event.target.value;
    this.setState({ questionDetails });
  };

  validateFields = (questionDetails) => {
    let errMsg = "";

    //Check for empty question
    if (
      questionDetails.question.de.trim() === "" ||
      questionDetails.question.en.trim() === ""
    ) {
      errMsg = "Please fill the question in both languages";
      return errMsg;
    }

    //Check for empty options
    const emptyOptions = questionDetails.options.filter(
      (o) => o.option.en.trim() === "" || o.option.de.trim() === ""
    );
    if (emptyOptions.length > 0) {
      errMsg = "Please fill all options in both languages";
    }

    return errMsg;
  };

  submitQuestion = () => {
    const { questionDetails } = this.state;

    const errorMsg = this.validateFields(questionDetails);

    if (errorMsg !== "") {
      alert(errorMsg);
    } else {
      const { updateQuestion } = this.props;
      const questObject = {
        category: questionDetails.category,
        questionstageid: questionDetails.questionStageId,
        question: questionDetails.question,
        options: questionDetails.options,
        type: "Inputfield",
      };
      updateQuestion(questionDetails.questionId, questObject);
    }
  };

  render() {
    const {
      questionDetails,
      isEdit,
      currentLang,
      showModal,
      modalMsg,
      showSpinner,
    } = this.state;

    return (
      <div className="pcoded-content fade-in">
        <div className="page-header card" style={{ border: "none" }}>
          <div className="row align-items-end">
            <div className="col-lg-4">
              <div className="page-header-title">
                <Link to="/question">
                  <img src={backArrow} className="back-arrow" alt="img"></img>
                </Link>
                <div style={{ display: "inline-flex" }}>
                  <h5 className="header-style">Question Details</h5>
                  {showSpinner && (
                    <i
                      className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                      title="Loading data..."
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="page-header-breadcrumb">
                {isEdit && (
                  <button
                    id="add_question_div1"
                    type="button"
                    className="btn add_option_div btn-add-cst"
                    // onClick={() => this.submitQuestion()}
                    title="This action is not allowed at the moment."
                    disabled={true}
                  >
                    Save Changes
                  </button>
                )}
                <button
                  id="add_question_div1"
                  type="button"
                  className="btn add_option_div btn-add-cst"
                  onClick={() => this.toggleEditMode()}
                  disabled={true}
                  style={{ marginLeft: "15px" }}
                >
                  {!isEdit ? "Edit Question" : "Cancel"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="pcoded-inner-content">
          <div className="main-body innerpage">
            <div className="page-wrapper">
              <div className="page-body">
                <div className="card">
                  <div className="card-block">
                    {questionDetails && !isEdit && (
                      <div>
                        <div>
                          <h5
                            style={{
                              marginRight: "15px",
                            }}
                          >
                            <label>Question:</label>
                          </h5>
                          {questionDetails.question[currentLang]}
                          <button
                            id="add_question_div1"
                            type="button"
                            className="btn add_option_div btn-add-cst lang-switch"
                            onClick={() => this.switchLang()}
                            style={{}}
                          >
                            Switch language
                          </button>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <h5> Options:</h5>
                          <ul>
                            {questionDetails.options.map((op, index) => (
                              <li key={index}>
                                {`${index + 1}. `}
                                {op.option[currentLang]}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <div style={{ marginTop: "15px" }}>
                            <h5> Stage details:</h5>{" "}
                            {questionDetails.questionStageId ===
                              "46be22e5-2e6b-461e-ad6d-8f269ec79d70"
                              ? "Stage 1"
                              : "Stage 2"}
                          </div>
                          <div style={{ marginTop: "15px" }}>
                            <h5> Question Category:</h5>{" "}
                            {questionDetails.category}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Edit question section */}
                    {isEdit && questionDetails && (
                      <div>
                        <div className="col-sm-12">
                          <span
                            className="input-group-prepend"
                            style={{ width: "75%" }}
                          >
                            <label className="input-group-text1">
                              {lang.EN}
                            </label>
                          </span>

                          <textarea
                            id="userName-2c-1"
                            name="en"
                            placeholder="Enter the question in English language"
                            value={questionDetails.question[lang.EN]}
                            className="required form-control question-text"
                            onChange={this.handleQuestionChange}
                          //  onKeyDown={this.onKeyDown}
                          ></textarea>

                          <span
                            className="input-group-prepend"
                            style={{ width: "75%", marginTop: "10px" }}
                          >
                            <label className="input-group-text1">de</label>
                          </span>

                          <textarea
                            id="userName-2c-2"
                            placeholder="Enter the question in German language"
                            name={lang.GR}
                            value={questionDetails.question[lang.GR]}
                            className="required form-control question-text"
                            onChange={this.handleQuestionChange}
                          //  onKeyDown={this.onKeyDown}
                          ></textarea>
                        </div>
                        <div style={{ width: "100%" }}>
                          <hr style={{ width: "100%" }} />
                          <div className="col-sm-12 margin-10 title-style">
                            Options
                          </div>

                          <div className="col-sm-12">
                            {questionDetails.options &&
                              questionDetails.options.map((op, i) => (
                                <div
                                  className="input-group option_subdiv"
                                  style={{ width: "75%" }}
                                  key={i}
                                >
                                  <span className="input-group-prepend">
                                    <label className="input-group-text1">
                                      {lang.EN}
                                    </label>
                                  </span>
                                  <input
                                    type="text"
                                    name="en"
                                    className="form-control"
                                    placeholder={`Option ${i + 1
                                      } in English language`}
                                    value={op.option.en}
                                    onChange={this.handleOptionChange(
                                      i,
                                      lang.EN
                                    )}
                                  // onKeyDown={this.onKeyDown}
                                  />
                                  <div className="col-12 margin-5"></div>
                                  <span className="input-group-prepend">
                                    <label className="input-group-text1">
                                      de
                                    </label>
                                  </span>
                                  <input
                                    type="text"
                                    name="ge"
                                    className="form-control"
                                    placeholder={`Option ${i + 1
                                      } in German language`}
                                    value={op.option.de}
                                    onChange={this.handleOptionChange(
                                      i,
                                      lang.GR
                                    )}
                                  // onKeyDown={this.onKeyDown}
                                  />
                                  <span>
                                    <i
                                      className="fa fa-times"
                                      aria-hidden="true"
                                      onClick={() => this.removeOption(i)}
                                    ></i>
                                  </span>

                                  <div className="row col-sm-12 margin-10">
                                    <span
                                      style={{
                                        marginRight: "10px",
                                        marginTop: "3px",
                                      }}
                                    >
                                      {`Answer input type for option ${i + 1}`}
                                    </span>
                                    <div className="col-sm-5">
                                      <select
                                        style={{
                                          width: "100%",
                                          padding: "5px",
                                          textTransform: "lowercase",
                                        }}
                                        name={"type"}
                                        onChange={this.handleOptionDropdown(i)}
                                      >
                                        {inputTypes.map((type, index) => (
                                          <option
                                            key={index}
                                            value={type}
                                            selected={op.type === type}
                                          >
                                            {type}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            <button
                              id="add_question_div"
                              type="button"
                              className="btn add_option_div btn-add-cst"
                              onClick={() => this.addOption()}
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                              Add option
                            </button>

                            <hr style={{ width: "100%" }} />

                            <div className="row col-sm-12 margin-10">
                              <span
                                className="title-style"
                                style={{
                                  marginRight: "10px",
                                  marginTop: "3px",
                                }}
                              >
                                Question Category
                              </span>
                              <div className="col-sm-5">
                                <select
                                  style={{ width: "100%", padding: "5px" }}
                                  name="category"
                                  onChange={this.handleDropdown}
                                >
                                  {questionCategories.map((type, index) => (
                                    <option
                                      key={index}
                                      value={type}
                                      selected={
                                        type === questionDetails.category
                                      }
                                    >
                                      {type}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="row col-sm-12 margin-10">
                              <span
                                className="title-style"
                                style={{
                                  marginRight: "10px",
                                  marginTop: "3px",
                                }}
                              >
                                Stage for Question
                              </span>
                              <div className="col-sm-5">
                                <select
                                  style={{ width: "100%", padding: "5px" }}
                                  name="questionStageId"
                                  onChange={this.handleDropdown}
                                >
                                  {stages.map((stage, index) => (
                                    <option
                                      value={stage.key}
                                      key={index}
                                      selected={
                                        questionDetails.questionStageId ===
                                        stage.key
                                      }
                                    >
                                      {stage.value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <button
                              id="add_question_div1"
                              type="button"
                              className="btn add_option_div btn-add-cst"
                              onClick={() => this.submitQuestion()}
                              disabled={true}
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <ModalComponent
            title={"Confirmation"}
            bodyMsg={modalMsg}
            closeModal={() => this.closeModal()}
            btnText="OK"
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getQuestionDetails: (questionId) => {
    dispatch(getQuestionDetails(questionId));
  },
  updateQuestion: (questionId, questionObj) => {
    dispatch(updateQuestion(questionId, questionObj));
  },
  clearReducer: (name) => {
    dispatch(clearReducer(name));
  },
});

const mapStateToProps = (state) => ({
  questionDetailReducer: state.questionDetailReducer,
  updateQuestionReducer: state.updateQuestionReducer,
});

QuestionDetails.propTypes = {
  questionDetailReducer: PropTypes.object,
  getQuestionDetails: PropTypes.func,
  updateQuestion: PropTypes.func,
  updateQuestionReducer: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  clearReducer: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionDetails);
