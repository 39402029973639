import React from "react";

import { store } from "./store/store.js";
import { Provider } from "react-redux";
import Routers from "./router";
import "react-dates/initialize";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Routers />
      </Provider>
    </div>
  );
}

export default App;
