import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getObjectList,
  changeObjectStatus,
  fetchQuarter,
} from "../../actions/objectActions";
import { clearReducer } from "../../actions/clearAction";
import {
  tableOptions,
  getDateWithTime,
  compareDate,
} from "../../utils/tableData";
import { objColumns1, objColumns2, headerStyle } from "./objectListColumns";
import { customFilter } from "react-bootstrap-table2-filter";
import ModalComponent from "../../utils/modalComponent";
import ObjectFilters from "./objectFilters";
import LargeModalComponent from "./largeModal";
import "react-dates/lib/css/_datepicker.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "../../assets/css/tableStyle.css";
import SuggestionInputSearch from "suggestion-react-input-search";
import TableComponent from "../common/tableComponent";

const filterStates = {
  fromPrice: "",
  toPrice: "",
  platformId: "",
  id: "",
  realtorid: "",
  realtorName: "",
  platformType: "",
  fromListingDate: null,
  toListingDate: null,
  searchClicked: false,
};

const recordPerPage = 25000;
let realtorid = "";
let onFilterFunc = null;
let clearUrl = false;

class Objects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      objectList: [],
      pageSize: 100,
      showModal: false,
      showSpinner: true,
      selectedId: "",
      quarterList: [],
      ...filterStates,
    };
  }

  componentDidMount() {
    const { getObjectList, fetchQuarter } = this.props;
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.search
    ) {
      realtorid = this.props.history.location.search.replace("?realtor=", "");
    }
    if (realtorid !== "") {
      this.setState({ realtorid, searchClicked: true });
      getObjectList({ recordPerPage, realtorid });
    } else {
      getObjectList({ recordPerPage });
    }
    window.scrollTo(0, 0);
    fetchQuarter();
    document.addEventListener("change", this.detectChange);
  }

  componentDidUpdate(prevProps) {
    const {
      objectListReducer,
      changeObjectStatusReducer,
      getObjectList,
      clearReducer,
      quarterReducer,
    } = this.props;

    const { showSpinner } = this.state;

    if (
      prevProps.objectListReducer !== objectListReducer &&
      objectListReducer.status === "success" &&
      objectListReducer.data &&
      showSpinner
    ) {
      const result = this.setProperties(objectListReducer.data.data);
      this.setState({
        objectList: result,
        showSpinner: false,
      });
      clearReducer("CLEAR_OBJECT_LIST");
      this.forceUpdate();
      if (clearUrl) {
        clearUrl = false;
        this.props.history.push("objects");
      }
    }

    if (
      prevProps.changeObjectStatusReducer !== changeObjectStatusReducer &&
      changeObjectStatusReducer.status === "success" &&
      showSpinner
    ) {
      clearReducer("CLEAR_CHANGE_STATUS");
      setTimeout(() => {
        getObjectList({ recordPerPage });
      }, 500);
    }

    if (
      prevProps.changeObjectStatusReducer !== changeObjectStatusReducer &&
      changeObjectStatusReducer.status === "fail"
    ) {
      this.setState({ showSpinner: false });
      alert(
        "An error has occured while updating status. Please try again later."
      );
    }

    if (
      quarterReducer !== prevProps.quarterReducer &&
      quarterReducer.status === "success"
    ) {
      this.setState({ quarterList: quarterReducer.data });
      clearReducer("CLEAR_FETCH_QUARTER");
    }
  }

  componentWillUnmount() {
    document.removeEventListener("change", this.detectChange);
  }

  render() {
    const {
      objectList,
      pageSize,
      showModal,
      showSpinner,
      selectedId,
      quarterList,
      errorText,
    } = this.state;

    const options = {
      ...tableOptions,
      sizePerPage: pageSize,
    };

    const objColumnsForTable = [
      ...objColumns1,
      {
        dataField: "quarter",
        text: "Quarter",
        headerStyle: headerStyle,
        filter: customFilter(),
        filterRenderer: (onFilter) =>
          quarterList.length > 0 && this.quarterfilter(onFilter),
      },
      ...objColumns2,
      {
        dataField: "actions",
        text: "Actions",
        formatter: this.actionFormatter,
        headerStyle: headerStyle,
      },
    ];

    return (
      <div className="pcoded-content fade-in">
        <div>
          <div className="pcoded-main-container" style={{ marginTop: "none" }}>
            <div className="pcoded-wrapper">
              <div className="page-header card" style={{ border: "none" }}>
                <div className="row align-items-end">
                  <div className="col-lg-8">
                    <div className="page-header-title">
                      <i
                        className="feather icon-command"
                        aria-hidden="true"
                        style={{ background: "#932849" }}
                      ></i>
                      <div>
                        <h5
                          className="header-style"
                          style={{ display: "inline-block" }}
                        >
                          Objects Management
                        </h5>
                      </div>
                      {/* {showSpinner && (
                        <i
                          className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                          title="Loading data..."
                        />
                      )} */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="pcoded-inner-content">
                <div className="main-body">
                  <div className="page-wrapper">
                    <div className="page-body">
                      <div className="card">
                        <div className="card-block">
                          <div className="row">
                            <div className="col-xs-12 col-sm-12">
                              <ObjectFilters
                                fromPrice={this.state.fromPrice}
                                toPrice={this.state.toPrice}
                                platformId={this.state.platformId}
                                objectId={this.state.id}
                                clearSearch={this.clearSearch}
                                handleSearchClick={this.handleSearchClick}
                                handleChange={this.handleChange}
                                startDate={this.state.fromListingDate}
                                endDate={this.state.toListingDate}
                                setDate={this.setDate}
                                focusedInput={this.state.focusedInput}
                                setFocusedInput={this.setFocusedInput}
                                realtorid={this.state.realtorid}
                                realtorName={this.state.realtorName}
                                platformType={this.state.platformType}
                              />
                              <label className="error-filter">
                                {errorText !== "" && errorText}
                              </label>

                              <TableComponent
                                data={objectList}
                                isLoading={showSpinner}
                                columns={objColumnsForTable}
                                hidePagination={objectList.length === 0}
                                paginationOptions={options}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <ModalComponent
            title={"disable object"}
            bodyMsg={"Are you sure you want to disable this object?"}
            closeModal={() => this.closeModal()}
            handleYes={this.disableObject}
            btnText="No"
          />
        )}

        {selectedId !== "" && (
          <LargeModalComponent
            objectId={selectedId}
            closeModal={() => this.toggleObjectPopup("")}
          />
        )}
      </div>
    );
  }

  setProperties = (propertyList) => {
    let list = [];
    propertyList.forEach((prop, index) => {
      const obj = {
        id: prop.id,
        platformId: prop.platformId,
        srNo: index + 1,
        adrCity: prop.adrCity,
        postalCode: prop.postalCode,
        quarter: prop.quarter && prop.quarter.replace("_", " "),
        price: prop.formatPrice,
        areaLiving: prop.areaLiving,
        createdAt: prop.createdAt,
        createdFormattedDate: getDateWithTime(prop.createdAt),
        updatedAt: compareDate(prop.createdAt, prop.updatedAt),
        state: !prop.isEnable ? "Disabled" : prop.status ? "Active" : "Expired",
        isEnable: prop.isEnable,
        realtor: prop.realtor ? prop.realtor.provider_company_name : "N/a",
      };

      list.push(obj);
    });

    return list;
  };

  toggleModal = (id, isEnable) => {
    if (!this.state.showSpinner) {
      if (!isEnable) {
        const { changeObjectStatus } = this.props;
        changeObjectStatus(id);
        this.setState({ showSpinner: true });
      } else {
        this.setState({ disbaleId: id, showModal: true });
      }
    }
  };

  closeModal = () => {
    this.setState({ showModal: false, disbaleId: "" });
  };

  disableObject = () => {
    const { disbaleId } = this.state;
    const { changeObjectStatus } = this.props;
    changeObjectStatus(disbaleId);
    this.setState({ showModal: false, showSpinner: true });
  };

  actionFormatter = (cell, row) => {
    let iconClassName = "";
    if (!row.isEnable) {
      iconClassName = "fa fa-toggle-off";
    } else if (row.state === "Active" || row.state === "Expired") {
      iconClassName = "fa fa-toggle-on";
    }
    return (
      <div>
        <span className="waves-effect waves-dark pcoded-micon">
          <i
            style={{
              fontSize: "22px",
              marginRight: "5px",
              cursor: this.state.showSpinner ? "not-allowed" : "pointer",
            }}
            className={iconClassName}
            onClick={() => this.toggleModal(row.id, row.isEnable)}
            title={row.state === "Active" ? "Disable object" : "Enable object"}
          ></i>
          <i
            title="Go to object details"
            className="fa fa-external-link-square"
            aria-hidden="true"
            onClick={() => {
              this.toggleObjectPopup(row.id);
            }}
          ></i>
        </span>
      </div>
    );
  };

  handleChange = (stateName) => (event) => {
    this.setState({
      [stateName]:
        event.target.type === "number"
          ? parseInt(event.target.value)
          : event.target.value,
      errorText: "",
    });
    const { fromListingDate, toListingDate } = this.state;
    if (
      event.target.value === "" &&
      fromListingDate === null &&
      toListingDate === null
    ) {
      this.clearSearch();
    }
  };

  setDate = (fromListingDate, toListingDate) => {
    this.setState({ fromListingDate, toListingDate, errorText: "" });

    const { fromPrice, toPrice, platformId, searchClicked, id } = this.state;

    if (fromListingDate === null && toListingDate === null && searchClicked) {
      const isEmpty = [fromPrice, toPrice, platformId, id].filter(
        (i) => i !== ""
      );

      isEmpty.length === 0 && this.clearSearch();
    }
  };

  setFocusedInput = (focusedInput) => {
    this.setState({ focusedInput });
  };

  setWrapperRefForSortMenu = (node) => {
    this.filterRef = node;
  };

  detectChange = (event) => {
    if (
      this.filterRef &&
      event.target.className.includes("suggestion-input") &&
      this.filterRef.inputRef.value === ""
    ) {
      onFilterFunc("");
    }
  };

  toggleObjectPopup = (id) => {
    this.setState({ selectedId: id });
    document.body.style.overflow = id === "" ? "unset" : "hidden";
  };

  quarterfilter = (onFilter) => {
    const recentSearches = this.state.quarterList;
    const placeholder = "";
    const inputPosition = "center";

    return (
      <div className="qtr-filter">
        <SuggestionInputSearch
          onSubmitFunction={this.handleChangeQtr(onFilter)}
          recentSearches={recentSearches}
          placeholder={placeholder}
          inputPosition={inputPosition}
          autocompleteOnMatch={true}
          ref={this.setWrapperRefForSortMenu}
        />
      </div>
    );
  };

  handleChangeQtr = (onFilter) => (text) => {
    onFilter(text);
    onFilterFunc = onFilter;
  };

  handleSearchClick = () => {
    const { getObjectList } = this.props;
    const {
      fromListingDate,
      toListingDate,
      fromPrice,
      toPrice,
      platformId,
      id,
      realtorid,
      realtorName,
      platformType,
    } = this.state;
    const check = [
      fromListingDate,
      toListingDate,
      fromPrice,
      toPrice,
      platformId,
      id,
      realtorid,
      realtorName,
      platformType,
    ];
    const isEmpty = check.filter((i) => i === "" || !i);

    if (isEmpty.length === check.length) {
      this.setState({ errorText: "Please select some filters to search" });
    } else if (toPrice && toPrice < fromPrice) {
      this.setState({
        errorText: "Please add to value bigger than from value for price",
      });
    } else {
      let apiObj = {
        recordPerPage,
      };

      [
        "fromPrice",
        "toPrice",
        "platformId",
        "id",
        "realtorid",
        "realtorName",
        "platformType",
      ].forEach((param) => {
        if (this.state[param] !== "") {
          apiObj = {
            ...apiObj,
            [param]: this.state[param],
          };
        }
      });

      ["fromListingDate", "toListingDate"].forEach((param) => {
        if (this.state[param] !== "" && this.state[param] !== null) {
          let dateFormat = new Date(this.state[param]);
          dateFormat = dateFormat.toISOString();
          apiObj = {
            ...apiObj,
            [param]: dateFormat,
          };
        }
      });

      getObjectList(apiObj);
      this.setState({ showSpinner: true, searchClicked: true });
    }
  };

  clearSearch = () => {
    if (realtorid !== "") {
      clearUrl = true;
    }

    this.setState({
      fromPrice: "",
      toPrice: "",
      fromListingDate: null,
      toListingDate: null,
      platformId: "",
      id: "",
      realtorid: "",
      realtorName: "",
      platformType: "",
    });

    const { searchClicked } = this.state;

    if (searchClicked) {
      const { getObjectList } = this.props;
      getObjectList({ recordPerPage });
      this.setState({ showSpinner: true, searchClicked: false });
    }
  };

  imageFormatter = (cell) => (
    <img style={{ width: "60px", height: "60px" }} src={cell} alt="img" />
  );
}

const mapDispatchToProps = (dispatch) => ({
  getObjectList: (data) => {
    dispatch(getObjectList(data));
  },

  changeObjectStatus: (id) => {
    dispatch(changeObjectStatus(id));
  },

  clearReducer: (name) => {
    dispatch(clearReducer(name));
  },

  fetchQuarter: () => {
    dispatch(fetchQuarter());
  },
});

const mapStateToProps = (state) => ({
  objectListReducer: state.objectListReducer,
  changeObjectStatusReducer: state.changeObjectStatusReducer,
  quarterReducer: state.quarterReducer,
});

Objects.propTypes = {
  getObjectList: PropTypes.func,
  changeObjectStatus: PropTypes.func,
  clearReducer: PropTypes.func,
  fetchQuarter: PropTypes.func,
  objectListReducer: PropTypes.object,
  changeObjectStatusReducer: PropTypes.object,
  quarterReducer: PropTypes.object,
  history: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Objects);
