import React, { Component } from "react";
import backArrow from "../../assets/images/whitearrow.png";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getUsersDetails, changeUserStatus } from "../../actions/userActions";
import { clearReducer } from "../../actions/clearAction";
import UserAnswers from "./userAnswers";
import ModalComponent from "../../utils/modalComponent";
import * as userFunc from "./userFunctions";
import UserHeader from "./userHeader";
import FavoriteObjects from "./favoriteProperties";
import UserData from "./userData";
import { callRequestAPI } from "../../services/callApi";
import { BASE_URL } from "../../services/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let userId = "";

class UserDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userDetails: null,
      showEditUser: false,
      showModal: false,
      detailArray: [],
      userStatsArray: [],
      activetab: userFunc.tabConstants.USER_DATA,
      userStatsArrayValues: [],
      questionList: [],
      financeQuestionList: [],
      favoList: [],
      documentData: {
        financingconfirmation: [],
        schufacreditcheck: [],
      },
    };

    this.getId();
  }

  getId = () => {
    const { match } = this.props;
    userId = match.params.id;
  };

  componentDidMount() {
    const { getUsersDetails } = this.props;
    getUsersDetails(userId);
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const { userDetailReducer, userStatusReducer, clearReducer } = this.props;

    if (
      userDetailReducer !== prevProps.userDetailReducer &&
      userDetailReducer.status === "success"
    ) {
      const detailArray = userFunc.createDetailArray(userDetailReducer.data);
      const documentData = {
        financingconfirmation:
          userDetailReducer.data.details.financingconfirmation || [],
        schufacreditcheck:
          userDetailReducer.data.details.schufacreditcheck || [],
      };

      const list = userFunc.setAnswersArray(userDetailReducer.data.onboarding);

      const finList = userFunc.setAnswersArray(
        userDetailReducer.data.financeOnboarding
      );

      const favoList = userFunc.setFavProperties(
        userDetailReducer.data.favouriteList
      );
      this.setState({
        userDetails: userDetailReducer.data.details,
        detailArray: detailArray.userDetailArray,
        userStatsArray: detailArray.userStatsArray,
        questionList: list,
        financeQuestionList: finList,
        favoList,
        utmObject: detailArray.utmObject,
        documentData,
      });

      clearReducer("CLEAR_USER_DETAIL");
    }
    if (
      prevProps.userStatusReducer !== userStatusReducer &&
      userStatusReducer.status === "success" &&
      userStatusReducer.data
    ) {
      this.setState({ showModal: false, showEditUser: false });

      const { getUsersDetails } = this.props;
      getUsersDetails(userId);
      clearReducer("CLEAR_USER_STATUS");
      setTimeout(() => {
        window.alert("Operation performed successfully");
      }, 200);
    }
  }

  render() {
    const {
      userDetails,
      showEditUser,
      showModal,
      detailArray,
      activetab,
      userStatsArray,
      questionList,
      favoList,
      utmObject,
      documentData,
    } = this.state;

    const { userDetailReducer } = this.props;
    return (
      <div className="pcoded-content fade-in">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-header card" style={{ border: "none" }}>
          <div className="row align-items-end">
            <div className="col-lg-4">
              <div className="page-header-title">
                <Link to="/user">
                  <img src={backArrow} className="back-arrow" alt="img"></img>
                </Link>
                <div className="d-inline">
                  <h5 className="header-style">
                    {userDetails
                      ? `${userDetails.firstName} ${userDetails.lastName || ""}`
                      : ""}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <button className="login-user-btn" onClick={this.hanldeUserLogin}>
                Login to account
              </button>
            </div>
          </div>
        </div>

        <div className="pcoded-inner-content">
          <div className="main-body innerpage">
            <UserHeader
              userDetails={userDetails}
              showEditUser={showEditUser}
              toggleEditMode={this.toggleEditMode}
              toggleModal={this.toggleModal}
            />
            <div className="page-wrapper">
              <div className="page-body">
                <div className="card">
                  <div className="card-block">
                    <div className="nav-tabs-wrapper d-flex flex-column flex-md-row justify-content-between align-items-md-center align-items-start mb-4">
                      <ul
                        className="nav nav-tabs order-1 order-md-0"
                        id="myTab"
                        role="tablist"
                      >
                        {userFunc.userTabs.map((tab, index) => (
                          <li className="nav-item" key={index}>
                            <Link
                              className={
                                activetab === tab
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="home-tab"
                              role="tab"
                              name={tab}
                              onClick={() => this.changeActiveTab(tab)}
                            >
                              {tab}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {activetab === userFunc.tabConstants.USER_DATA && (
                      <UserData
                        detailArray={detailArray}
                        utmObject={utmObject}
                      />
                    )}
                    {activetab === userFunc.tabConstants.USER_DOCUMENT && (
                      <div style={{ border: "1px solid #cecece" }}>
                        <div
                          className="ml-4 tab-content mb-3"
                          id="myTabContent"
                        >
                          {["financingconfirmation", "schufacreditcheck"].map(
                            (type, index) => (
                              <React.Fragment key={index}>
                                <h5 className="mb-3 mt-3">
                                  {index === 0
                                    ? "Finance Document"
                                    : "Schufa credit check"}
                                </h5>
                                {documentData[type].length > 0 &&
                                  documentData[type].map((data) => (
                                    <tr key={data.id}>
                                      <td>
                                        <label className="mb-0 key-label">
                                          {data.name}
                                        </label>
                                      </td>
                                      <td>
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.getDownloadData(data.url);
                                          }}
                                          style={{ outline: "none" }}
                                        >
                                          Download
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                {documentData[type].length === 0 && (
                                  <p className="mb-0 key-label">
                                    No documents uploaded
                                  </p>
                                )}
                                {index === 0 && <hr />}
                              </React.Fragment>
                            )
                          )}
                        </div>
                      </div>
                    )}

                    {activetab === userFunc.tabConstants.ONBOARDING && (
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="mt-2 tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div className="card">
                            <div className="card-block">
                              <UserAnswers
                                userId={userId}
                                userDetailReducer={userDetailReducer}
                                questionList={questionList}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {activetab === userFunc.tabConstants.FINANCE_ONBOARDING && (
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="mt-2 tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div className="card">
                            <div className="card-block">
                              <UserAnswers
                                userId={userId}
                                userDetailReducer={userDetailReducer}
                                questionList={this.state.financeQuestionList}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {activetab === userFunc.tabConstants.USER_STAT && (
                      <div className="tab-content" id="myTabContent">
                        <div className="card">
                          <div className="card-header">
                            <h5>{userFunc.tabConstants.USER_STAT}</h5>
                          </div>

                          <div
                            className="ml-4 tab-content mb-3"
                            id="myTabContent"
                          >
                            {userStatsArray &&
                              userStatsArray.length > 0 &&
                              userStatsArray.map((property, index) => (
                                <tr key={index}>
                                  <td>
                                    <label className="mb-0 key-label">
                                      {property[0]}
                                    </label>
                                  </td>
                                  <td>
                                    <label className="mb-0 value-label">
                                      {property[1]}
                                    </label>
                                  </td>
                                </tr>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}
                    {activetab === userFunc.tabConstants.FAVO && (
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="mt-2 tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div className="card">
                            <div className="card-block">
                              <FavoriteObjects favoList={favoList} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <ModalComponent
            title={"Confirmation"}
            bodyMsg={`Are you sure you want to  ${userDetails.status === "ACTIVE" ? "deactivate" : "acivate"
              } the user?`}
            closeModal={() => this.toggleModal(false)}
            handleYes={this.handleDeactivate}
          />
        )}
      </div>
    );
  }

  showToasterSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  showToasterError = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  getDownloadData = async (url) => {
    const response = await callRequestAPI(
      `v1/users/presignUrl/qualifyDocuments`,
      { url },
      "postHeader"
    );

    if (response.status === 201 && response.data) {
      const link = document.createElement("a");
      link.href = response.data.data;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.showToasterSuccess("document downlod started.");
    } else {
      this.showToasterError(
        "Something went wrong. Not able to download the document."
      );
    }
  };

  hanldeUserLogin = async () => {
    const response = await callRequestAPI(
      `v1/users/password/${userId}`,
      null,
      "get"
    );

    if (response.status === 200 && response.data) {
      const token = response.data.data;

      const url = BASE_URL.includes("staging")
        ? `http://staging.immozy.de/admin-login/${token}/${userId}`
        : `https://app.immozy.de/admin-login/${token}/${userId}`;

      window.open(url, "_blank");
    } else {
      const errorMessage =
        response.message ||
        "Something has gone wrong. Please try again later or contact administrator.";
      window.alert(errorMessage);
    }
  };

  toggleEditMode = (modeName) => {
    this.setState({ [modeName]: !this.state[modeName] });
  };

  toggleModal = (value) => {
    this.setState({ showModal: value });
  };

  handleDeactivate = () => {
    const { changeUserStatus } = this.props;
    changeUserStatus(userId);
    this.setState({ showEditUser: false });
  };

  changeActiveTab = (name) => {
    this.setState({ activetab: name });
  };
}

const mapDispatchToProps = (dispatch) => ({
  getUsersDetails: (id) => {
    dispatch(getUsersDetails(id));
  },
  changeUserStatus: (id) => {
    dispatch(changeUserStatus(id));
  },
  clearReducer: (name) => {
    dispatch(clearReducer(name));
  },
});

const mapStateToProps = (state) => ({
  userDetailReducer: state.userDetailReducer,
  userStatusReducer: state.userStatusReducer,
});

UserDetail.propTypes = {
  userStatusReducer: PropTypes.object,
  changeUserStatus: PropTypes.func,
  clearReducer: PropTypes.func,
  getUsersDetails: PropTypes.func,
  userDetailReducer: PropTypes.object,
  match: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
