/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    getDateWithTime,
} from "../../utils/tableData";
import { getDuplicateObjectList } from "../../actions/objectActions";
import {
    cityFilter,
    headerStyle,
    stateFilter,
    statusComponent,
} from "../objects/objectListColumns";
import { textFilter, selectFilter } from "react-bootstrap-table2-filter";
import ModalComponent from "../../utils/modalComponent";
import LargeModalComponent from "../objects/largeModal";
import { clearReducer } from "../../actions/clearAction";
import { callRequestAPI } from "../../services/callApi";
import TableComponent from "../common/tableComponent";

const recordPerPage = 25000;

class DuplicateObjects extends Component {
    constructor(props) {
        super(props);

        this.state = {
            duplicateObjList: [],
            isLoading: true,
            showModal: false,
            selectedId: "",
        };
    }

    componentDidMount() {
        const { getDuplicateObjectList } = this.props;
        getDuplicateObjectList({ recordPerPage });
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        const { duplicateObjectListReducer } = this.props;
        const { isLoading } = this.state;

        if (
            prevProps.duplicateObjectListReducer !==
            duplicateObjectListReducer &&
            duplicateObjectListReducer.status === "success" &&
            duplicateObjectListReducer.data &&
            isLoading
        ) {
            const requestList = this.setProperties(
                duplicateObjectListReducer.data.data
            );
            this.setState({
                duplicateObjList: requestList,
                isLoading: false,
            });
            clearReducer("CLEAR_OBJECT_LIST");
        }
    }

    render() {
        const duplicateDocumentsColumns = [
            {
                dataField: "object_title_picture",
                text: "Image ",
                formatter: this.imageFormatter,
                headerStyle: {
                    verticalAlign: "top",
                    width: "270px",
                    textAlign: "center",
                },
            },
            ...column1,
            {
                dataField: "state",
                text: "State",
                sort: true,
                headerStyle: {
                    verticalAlign: "top",
                    width: "12%",
                    textAlign: "center",
                },
                formatter: statusComponent,
                filter: selectFilter({
                    className: "my-custom-select-filter",
                    options: stateFilter,
                }),
            },

            {
                dataField: "actions",
                text: "Actions",
                formatter: this.actionFormatter,
                headerStyle: { verticalAlign: "top", width: "10%" },
            },
        ];

        const { isLoading, duplicateObjList, selectedId } = this.state;

        return (
            <div className="pcoded-content fade-in">
                <div
                    className="pcoded-main-container"
                    style={{ marginTop: "none" }}
                >
                    <div className="pcoded-wrapper">
                        <div
                            className="page-header card"
                            style={{ border: "none" }}
                        >
                            <div className="row align-items-end">
                                <div className="col-lg-5">
                                    <div className="page-header-title">
                                        <i
                                            className="fa fa-paperclip"
                                            aria-hidden="true"
                                            style={{ background: "#932849" }}
                                        ></i>
                                        <div>
                                            <h5
                                                className="header-style"
                                                style={{
                                                    display: "inline-block",
                                                }}
                                            >
                                                Duplicate Objects
                                            </h5>
                                        </div>

                                        {/* {isLoading ? (
                                            <i
                                                className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                                                title="Loading data..."
                                            />
                                        ) : (
                                            ""
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="page-body">
                                    <div className="card">
                                        <div className="card-block">
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-12">
                                                    <TableComponent
                                                        data={duplicateObjList}
                                                        columns={duplicateDocumentsColumns}
                                                        hidePagination
                                                        isLoading={isLoading}
                                                        tableSearch
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showModal && (
                    <ModalComponent
                        title={"disable object"}
                        bodyMsg={
                            "Are you sure you want to disable this object?"
                        }
                        closeModal={() => this.closeModal()}
                        handleYes={this.disableObject}
                        btnText="No"
                    />
                )}

                {selectedId !== "" && (
                    <LargeModalComponent
                        objectId={selectedId}
                        closeModal={() => this.toggleObjectPopup("")}
                    />
                )}
            </div>
        );
    }

    setProperties = (propertyList) => {
        let list = [];

        propertyList.forEach((prop) => {
            const obj = {
                id: prop.id,
                ad_title: prop.ad_title,
                adrCity: prop.adr_city,
                postalCode: prop.adr_postal_code,
                quarter: prop.adr_quarter && prop.adr_quarter.replace("_", " "),
                price: prop.ad_price,
                areaLiving: prop.areaLiving,
                createdFormattedDate: getDateWithTime(prop.created_at),
                state: !prop.isenable
                    ? "Disabled"
                    : prop.active
                        ? "Active"
                        : "Expired",
                isenable: prop.isenable,
                object_title_picture: prop.object_title_picture,
            };

            list.push(obj);
        });

        return list;
    };

    toggleModal = (id, isEnable) => {
        if (!this.state.isLoading) {
            if (!isEnable) {
                this.changeEnableDisable(id);
            } else {
                this.setState({ disbaleId: id, showModal: true });
            }
        }
    };

    closeModal = () => {
        this.setState({ showModal: false, disbaleId: "" });
    };

    disableObject = () => {
        const { disbaleId } = this.state;
        this.changeEnableDisable(disbaleId);
    };

    imageFormatter(cell) {
        return (
            <img
                style={{ width: 250, maxHeight: 200, objectFit: "cover" }}
                src={cell}
                alt="img"
            />
        );
    }

    changeEnableDisable = async (id) => {
        const response = await callRequestAPI(
            `v4/objects/changestatus/${id}`,
            null,
            "putHeader"
        );

        if (response.status === 200 && response.data) {
            this.props.getDuplicateObjectList({ recordPerPage });
            this.setState({ showModal: false, isLoading: true });
        } else {
            this.setState({ showModal: false, isLoading: false });
        }
    };

    toggleObjectPopup = (id) => {
        this.setState({ selectedId: id });
        document.body.style.overflow = id === "" ? "unset" : "hidden";
    };

    actionFormatter = (cell, row) => {
        let iconClassName = "";
        if (!row.isenable) {
            iconClassName = "fa fa-toggle-off";
        } else if (row.state === "Active" || row.state === "Expired") {
            iconClassName = "fa fa-toggle-on";
        }
        return (
            <div>
                <span className="waves-effect waves-dark pcoded-micon">
                    <i
                        style={{
                            fontSize: "22px",
                            marginRight: "5px",
                            cursor: this.state.isLoading
                                ? "not-allowed"
                                : "pointer",
                        }}
                        className={iconClassName}
                        onClick={() => this.toggleModal(row.id, row.isenable)}
                        title={
                            row.state === "Active"
                                ? "Disable object"
                                : "Enable object"
                        }
                    ></i>
                    <i
                        title="Go to object details"
                        className="fa fa-external-link-square"
                        aria-hidden="true"
                        onClick={() => {
                            this.toggleObjectPopup(row.id);
                        }}
                    ></i>
                </span>
            </div>
        );
    };
}

const mapDispatchToProps = (dispatch) => ({
    getDuplicateObjectList: (data) => {
        dispatch(getDuplicateObjectList(data));
    },
    clearReducer: (name) => {
        dispatch(clearReducer(name));
    },
});

const mapStateToProps = (state) => ({
    duplicateObjectListReducer: state.duplicateObjectListReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateObjects);

const column1 = [
    {
        dataField: "ad_title",
        text: "Title",
        headerStyle: headerStyle,
    },

    {
        dataField: "adrCity",
        text: "City",
        sort: true,
        headerStyle: {
            verticalAlign: "top",
            width: "12%",
            textAlign: "center",
        },

        filter: selectFilter({
            className: "my-custom-select-filter",
            options: cityFilter,
        }),
    },
    {
        dataField: "postalCode",
        text: "Postcode",
        sort: true,
        headerStyle: {
            textAlign: "center",
            verticalAlign: "top",
            width: "10%",
        },
        filter: textFilter({
            placeholder: " ",
            className: "my-custom-text-filter",
        }),
    },
    {
        dataField: "price",
        text: "Price (in EUR)",
        sort: true,
        headerStyle: { verticalAlign: "top", width: "7%" },
    },
    {
        dataField: "createdFormattedDate",
        text: "Listed At",
        headerStyle: headerStyle,
    },
];
