export const INITIAL_STATE = {
  response: [],
};

export const requestDocListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_REQ_DOC_LIST_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "FETCH_REQ_DOC_LIST_PENDING":
      return {
        status: "pending",
      };

    case "CLEAR_REQ_DOC":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};

export const requestShowListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_REQ_SHOW_LIST_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "FETCH_REQ_SHOW_LIST_PENDING":
      return {
        status: "pending",
      };

    case "CLEAR_REQ_SHOW":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};
