import { combineReducers } from "redux";
import { forgetPasswordReducer, changePasswordReducer } from "./loginReducer";
import { profileInfoReducer, updateProfileReducer } from "./profileInfoReducer";
import {
    objectListReducer,
    objectDetailReducer,
    changeObjectStatusReducer,
    quarterReducer,
    duplicateObjectListReducer,
} from "./objectReducer";
import {
    userListReducer,
    userDetailReducer,
    favObjectReducer,
    userAnswerReducer,
    userStatusReducer,
} from "./userReducer";
import {
    questionListReducer,
    addQuestionReducer,
    deleteQuestionReducer,
    questionDetailReducer,
    updateQuestionReducer,
} from "./questionListReducer";
import {
    requestDocListReducer,
    requestShowListReducer,
} from "./documentReducer";

const rootReducer = combineReducers({
    forgetPasswordReducer,
    changePasswordReducer,
    objectListReducer,
    profileInfoReducer,
    updateProfileReducer,
    userListReducer,
    questionListReducer,
    objectDetailReducer,
    addQuestionReducer,
    deleteQuestionReducer,
    updateQuestionReducer,
    questionDetailReducer,
    userDetailReducer,
    userStatusReducer,
    favObjectReducer,
    userAnswerReducer,
    changeObjectStatusReducer,
    quarterReducer,
    requestDocListReducer,
    requestShowListReducer,
    duplicateObjectListReducer,
});

export default rootReducer;
