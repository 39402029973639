import React from "react";
import { Link } from "react-router-dom";
import { callRequestAPI } from "../../services/callApi";
import { compareDate, getDateWithTime } from "../../utils/tableData";
import ModalComponent from "../../utils/modalComponent";
import Skeleton from "react-loading-skeleton";

import "./icstyles.css";
import "react-loading-skeleton/dist/skeleton.css";


class ModulesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requestList: [],
      isLoading: true,
      editId: "",
      oldValue: "",
      showModal: false,
    };
  }

  componentDidMount() {
    this.getModulesList();
  }

  render() {
    const { requestList, isLoading, editId, showModal } = this.state;

    return (
      <div className='pcoded-content fade-in'>
        <div className='pcoded-main-container' style={{ marginTop: "none" }}>
          <div className='pcoded-wrapper'>
            <div className='page-header card' style={{ border: "none" }}>
              <div className='row align-items-end'>
                <div className='col-lg-5'>
                  <div className='page-header-title'>
                    <i
                      className='fa fa-paperclip'
                      aria-hidden='true'
                      style={{ background: "#932849" }}
                    ></i>
                    <div>
                      <h5
                        className='header-style'
                        style={{ display: "inline-block" }}
                      >
                        Immozy Center Modules
                      </h5>
                    </div>

                    {/* {isLoading ? (
                      <i
                        className='fa fa-cog fa-spin fa-3x fa-fw spinner-gear'
                        title='Loading data...'
                      />
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='pcoded-inner-content'>
            <div className='main-body'>
              <div className='page-wrapper'>
                <div className='page-body'>
                  <div className='card'>
                    <div className='card-block'>
                      <table className='module-table'>
                        <thead>
                          <tr>
                            <th>Module Name</th>
                            <th>Order Id</th>
                            <th>Created Date</th>
                            <th>Updated Date</th>
                            <th>Module Type</th>
                            <th>Active/Deactive</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading
                            ? (
                              <tr>
                                <td colSpan={7}>
                                  <Skeleton
                                    baseColor='#ddd4d7'
                                    width={"100%"}
                                    count={5}
                                    height={"50px"}
                                    className="mb-2"
                                  />
                                </td>
                              </tr>
                            ) : (
                              <>
                                {requestList.length === 0
                                  ? (
                                    <tr>
                                      <td colSpan={7}>No data found.</td>
                                    </tr>
                                  ) : (
                                    requestList.map((list, index) => (
                                      <tr key={list.id}>
                                        <td>
                                          <Link
                                            to={

                                              `module/${list.slug}/${list.id}`
                                            }
                                            style={{
                                              color: "#000",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {list.title}
                                          </Link>
                                        </td>
                                        <td style={{ width: "20%" }}>
                                          {editId === list.id ? (
                                            <input
                                              type={"number"}
                                              value={list.orderId}
                                              id={index}
                                              onChange={this.handleChange}
                                              step={"any"}
                                              style={{ width: "50%" }}
                                            />
                                          ) : (
                                            list.orderId
                                          )}
                                          <i
                                            className={
                                              editId !== list.id
                                                ? "fa fa-pencil ml-3"
                                                : "fa fa-times ml-2"
                                            }
                                            onClick={() => {
                                              editId !== list.id
                                                ? this.setState({
                                                  editId: list.id,
                                                  oldValue: list.orderId,
                                                })
                                                : this.cancelValue(list.orderId, index);
                                            }}
                                          ></i>
                                          {editId === list.id && (
                                            <i
                                              onClick={() =>
                                                this.savePriority(list.id, list.orderId)
                                              }
                                              className='fa fa-check ml-2'
                                            />
                                          )}
                                        </td>
                                        <td>{getDateWithTime(list.createdDate)}</td>
                                        <td>
                                          {compareDate(
                                            list.createdDate,
                                            list.updatedDate
                                          )}
                                        </td>
                                        <td style={{ textTransform: "capitalize" }}>
                                          <p className={`module-width ${list.type}`}>
                                            {list.type}
                                          </p>
                                        </td>
                                        <td>
                                          <i
                                            style={{
                                              fontSize: "25px",
                                              marginRight: "5px",
                                              cursor: isLoading
                                                ? "not-allowed"
                                                : "pointer",
                                            }}
                                            className={
                                              list.active
                                                ? "fa fa-toggle-on"
                                                : "fa fa-toggle-off"
                                            }
                                            onClick={() =>
                                              this.toggleStatus(list.active, list.id)
                                            }
                                          ></i>
                                        </td>
                                        <td>
                                          <Link
                                            to={
                                              list.slug === "general_messages"
                                                ? `msg-list/${list.id}`
                                                : `module/${list.slug}/${list.id}`
                                            }
                                            style={{ color: "#000" }}
                                          >
                                            <i
                                              className='fa fa-pencil-square-o'
                                              aria-hidden='true'
                                              title={"View general messages and edit"}
                                            ></i>
                                          </Link>
                                          <i
                                            className='fa fa-clone ml-2'
                                            aria-hidden='true'
                                            title={
                                              "Create a duplicate module from this"
                                            }
                                            onClick={() =>
                                              this.createDuplcateModule(list.id)
                                            }
                                          ></i>
                                          {list.isDuplicate && (
                                            <i
                                              className='feather icon-trash ml-2'
                                              onClick={() =>
                                                this.setState({
                                                  deleteId: list.id,
                                                  showModal: true,
                                                })
                                              }
                                              title='Delete duplicate module'
                                            ></i>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  )}</>)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          showModal && (
            <ModalComponent
              title={"Delete Module"}
              bodyMsg={"Are you sure you want to delete this module?"}
              closeModal={() => this.setState({ showModal: false, deleteId: "" })}
              handleYes={this.deleteModule}
              btnText='No'
            />
          )
        }
      </div >
    );
  }

  createDuplcateModule = async (id) => {
    const response = await callRequestAPI(
      `v1/center/duplicate/${id}`,
      null,
      "putHeader"
    );

    if (response.status === 200 && response.data) {
      const id = response.data.data.id;
      const name = response.data.data.slug;

      // eslint-disable-next-line react/prop-types
      this.props.history.push(`module/${name}/${id}`);
    } else {
      this.setState({ isLoading: false });
    }
  };

  getModulesList = async () => {
    const response = await callRequestAPI(`v1/center`, null, "get");

    if (response.status === 200 && response.data) {
      this.setState({ requestList: response.data.data, isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleChange = (event) => {
    const index = event.target.id;
    const value = event.target.value;
    const { requestList } = this.state;
    requestList[index].orderId = parseFloat(value);
    this.setState({ requestList });
  };

  cancelValue = (value, index) => {
    const { oldValue } = this.state;

    if (value !== oldValue) {
      const { requestList } = this.state;
      requestList[index].orderId = oldValue;
      this.setState({ requestList });
    }
    this.setState({ editId: "" });
  };

  savePriority = async (id, value) => {
    this.setState({ isLoading: true });
    const response = await callRequestAPI(
      `v1/center/order/${value}/${id}`,
      null,
      "putHeader"
    );

    if (response.status === 200) {
      this.getModulesList();
      this.setState({ isLoading: false, editId: "", oldValue: "" });
    } else {
      this.setState({ isLoading: false, editId: "", oldValue: "" });
    }
  };

  toggleStatus = async (isActive, id) => {
    this.setState({ isLoading: true });
    const response = await callRequestAPI(
      `v1/center/status/${!isActive}/${id}`,
      null,
      "putHeader"
    );

    if (response.status === 200) {
      const { requestList } = this.state;

      // eslint-disable-next-line
      requestList.map((listItem) => {
        if (listItem.id === id) {
          listItem.active = !isActive;
        }
      });
      this.setState({ isLoading: false, editId: "", oldValue: "" });
    } else {
      this.setState({ isLoading: false, editId: "", oldValue: "" });
    }
  };

  deleteModule = async () => {
    const { deleteId } = this.state;
    this.setState({ isLoading: true, showModal: false });
    window.scrollTo(0, 0);
    const response = await callRequestAPI(
      `v1/center/${deleteId}`,
      null,
      "delete"
    );

    if (response.status === 200 && response.data) {
      this.getModulesList();
    } else {
      this.setState({ isLoading: false });
    }
  };
}

ModulesList.propTypes = {};

export default ModulesList;
