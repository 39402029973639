import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "../../assets/css/tableStyle.css";
import TableComponent from "../common/tableComponent";

function FavoriteObjects(props) {
  const headerStyle = () => ({
    textAlign: "center",
  });

  const redirectComponent = (cell, row) => (
    <Link
      to={`/object-detail/${row.id}/user`}
      style={{ cursor: "pointer", color: "#000" }}
    >
      {cell}
      <i
        style={{ marginLeft: "5px" }}
        className="fa fa-link"
        aria-hidden="true"
      ></i>
    </Link>
  );

  const imageFormatter = (cell) => (
    <img style={{ width: "60px", height: "60px" }} src={cell} alt="img" />
  );

  const { favoList } = props;

  const columns = [
    {
      dataField: "sr",
      text: "Sr. No",
      headerStyle: {
        textAlign: "center",
        width: "5%",
      },
    },
    {
      dataField: "objectTitlePicture",
      text: "Image",
      sort: true,
      headerStyle: headerStyle,
      formatter: imageFormatter,
    },
    {
      dataField: "objectTitle",
      text: "Title",
      sort: true,
      formatter: redirectComponent,
      headerStyle: () => ({
        width: "25%",
        textAlign: "center",
        textOverflow: "ellipses",
        whiteSpace: "normal",
      }),
    },

    {
      dataField: "adrCity",
      text: "City",
      headerStyle: headerStyle,
    },
    {
      dataField: "rooms",
      text: "Rooms",
      sort: true,
      headerStyle: () => ({
        width: "10%",
        textAlign: "center",
      }),
    },
    {
      dataField: "adrPostalCode",
      text: "Postcode",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "price",
      text: "Price (in EUR)",
      sort: true,
      headerStyle: () => ({
        width: "15%",
        textAlign: "center",
      }),
    },
  ];

  return (
    <div className="pcoded-wrapper">
      <div className="row">
        {favoList.length === 0 ? (
          "No records found for favourites objects"
        ) : (
          <div className="col-xs-12 col-sm-12">
            <TableComponent
              data={favoList}
              columns={columns}
              hidePagination
              tableHeading={"Favorites List"}
              tableSearch
            />
          </div>
        )}
      </div>
    </div>
  );
}

FavoriteObjects.propTypes = {
  favoList: PropTypes.array,
};

export default FavoriteObjects;
