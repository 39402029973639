import React from "react";
import { textFilter, selectFilter } from "react-bootstrap-table2-filter";
import { getDateWithTime, sortDates } from "../../utils/tableData";

export const stateFilter = [
  { value: "Active", label: "Active" },
  { value: "Disabled", label: "Disabled" },
  { value: "Expired", label: "Expired" },
];

export const cityFilter = [
  { value: "Hamburg", label: "Hamburg" },
  { value: "Berlin", label: "Berlin" },
  { value: "Hannover", label: "Hannover" },
];

export const headerStyle = () => ({
  textAlign: "center",
  verticalAlign: "top",
});

export const statusComponent = (cell) => {
  let color = "orange";
  let bgColor = "rgb(255, 165, 0, .7)";

  if (cell === "Active") {
    color = "green";
    bgColor = "rgba(51, 170, 51, .7)";
  } else if (cell === "Expired") {
    color = "red";
    bgColor = "rgba(224, 74, 74, .7)";
  }
  return (
    <div>
      <label
        className="status-label"
        style={{
          border: `1px solid ${color}`,
          background: bgColor,
          width: "80px",
        }}
      >
        {cell}
      </label>
    </div>
  );
};

export const objColumns1 = [
  {
    dataField: "srNo",
    text: "Id",
    headerStyle: {
      textAlign: "center",
      width: "5%",
      verticalAlign: "top",
      paddingTop: "18px",
    },
  },

  {
    dataField: "adrCity",
    text: "City",
    sort: true,
    headerStyle: headerStyle,
    filter: selectFilter({
      className: "my-custom-select-filter",
      options: cityFilter,
    }),
  },
];

export const objColumns2 = [
  {
    dataField: "postalCode",
    text: "Postcode",
    sort: true,
    headerStyle: { textAlign: "center", verticalAlign: "top", width: "10%" },
    filter: textFilter({
      placeholder: " ",
      className: "my-custom-text-filter",
    }),
  },
  {
    dataField: "state",
    text: "State",
    sort: true,
    headerStyle: headerStyle,
    formatter: statusComponent,
    filter: selectFilter({
      className: "my-custom-select-filter",
      options: stateFilter,
    }),
  },
  {
    dataField: "price",
    text: "Price (in EUR)",
    sort: true,
    headerStyle: headerStyle,
  },
  {
    dataField: "realtor",
    text: "Realtor Company",
    sort: true,
    headerStyle: headerStyle,
  },
  {
    dataField: "createdFormattedDate",
    text: "Listed At",
    sort: true,
    sortFunc: sortDates,
    headerStyle: headerStyle,
  },
  {
    dataField: "updatedAt",
    text: "Updated At",
    sort: true,
    headerStyle: headerStyle,
  },
];

const getPlatforms = (platforms, onlyID = false) => {
  let list = [];
  let ids = [];
  platforms.forEach((item) => {
    if (item.active) {
      list.push(item.platform);
      ids.push(item.platform_id);
    }
  });
  return onlyID ? ids.toString() : list.toString();
};

const getPlatformURL = (platforms) => {
  let list = [];
  platforms.forEach((item) => {
    if (item.active) {
      list.push({ platform: item.platform, url: item.url });
    }
  });
  return list;
};

export const createDetailsObject = (propertyInfo) => {
  let detailsArray = [
    { Id: 0, label: "General Details", value: [] },
    { Id: 1, label: "Adress and Location", value: [] },
    { Id: 2, label: "Segmentation", value: [] },
    { Id: 3, label: "Offer Information", value: [] },
    { Id: 4, label: "Area Information", value: [] },
    { Id: 5, label: "Equipment", value: [] },
    { Id: 6, label: "Object Features", value: [] },
    { Id: 7, label: "Provider Details", value: [] },
  ];

  if (propertyInfo) {
    // General Details
    detailsArray[0].value.push({ key: "ID", value: propertyInfo.id });
    detailsArray[0].value.push({
      key: "Title",
      value: propertyInfo.ad_title,
    });
    detailsArray[0].value.push({
      key: "Original Id",
      value: propertyInfo.ad_platforms
        ? getPlatforms(propertyInfo.ad_platforms, true)
        : "",
    });
    detailsArray[0].value.push({
      key: "Listed At",
      value: getDateWithTime(propertyInfo.created_at),
    });
    detailsArray[0].value.push({
      key: "Updated At",
      value: getDateWithTime(propertyInfo.updated_at),
    });
    detailsArray[0].value.push({
      key: "Type",
      value: propertyInfo.object_type,
    });
    detailsArray[0].value.push({
      key: "Sub type",
      value: propertyInfo.object_type_sub,
    });

    // Adress and Location
    detailsArray[1].value.push({
      key: "City",
      value: propertyInfo.adr_city,
    });
    detailsArray[1].value.push({
      key: "Street",
      value: propertyInfo.adr_raw || "N/a",
    });
    detailsArray[1].value.push({
      key: "Postal Code",
      value: propertyInfo.adr_postal_code || "N/a",
    });
    detailsArray[1].value.push({
      key: "Quarter",
      value: propertyInfo.adr_quarter || "N/a",
    });
    // detailsArray[1].value.push({
    //   key: "House Number",
    //   value: propertyInfo.adr_house_number || "N/a",
    // });
    detailsArray[1].value.push({
      key: "Latitude",
      value: propertyInfo.adr_coord_lat_epsg4326 || "N/a",
    });
    detailsArray[1].value.push({
      key: "Longitude",
      value: propertyInfo.adr_coord_lon_epsg4326 || "N/a",
    });
    //object condition add here

    //Segmentation
    detailsArray[2].value.push({
      key: "Demand type",
      value: propertyInfo.demand_type || "N/a",
    });

    detailsArray[2].value.push({
      key: "Object type",
      value: propertyInfo.object_type || "N/a",
    });

    //Offer Information
    detailsArray[3].value.push({
      key: "Ad Platform",
      value: getPlatformURL(propertyInfo.ad_platforms) || "N/a",
    });
    detailsArray[3].value.push({
      key: "Platform ID",
      value: getPlatforms(propertyInfo.ad_platforms, true) || "N/a",
    });
    detailsArray[3].value.push({
      key: "Object Title",
      value: propertyInfo.ad_title || "N/a",
    });
    detailsArray[3].value.push({
      key: "Price",
      value: propertyInfo.ad_price || "N/a",
    });
    detailsArray[3].value.push({
      key: "Price Currency",
      value: propertyInfo.ad_price_currency || "N/a",
    });
    detailsArray[3].value.push({
      key: "Costs",
      value: propertyInfo.costs || "N/a",
    });
    //check
    detailsArray[3].value.push({
      key: "Costs per m2",
      value: propertyInfo.costs_m2 ? Math.round(propertyInfo.costs_m2) : "N/a",
    });
     detailsArray[3].value.push({
       key: "Ad price Ratio",
       value: propertyInfo.ad_price_ratio || "N/a",
     });
     detailsArray[3].value.push({
       key: "Costs market value",
       value: propertyInfo.costs_market_value || "N/a",
     });

    // Area Information
    detailsArray[4].value.push({
      key: "Living Area",
      value: propertyInfo.area_living
        ? `${propertyInfo.area_living} m²`
        : "N/a",
    });
    detailsArray[4].value.push({
      key: "Usable Area",
      value: propertyInfo.area_use || "N/a",
    });

    detailsArray[4].value.push({
      key: "Plot Area",
      value: propertyInfo.area_plot || "N/a",
    });

    //Equipment
    detailsArray[5].value.push({
      key: "Barrier free",
      value: propertyInfo.equip_barrier_free || "N/a",
    });
    detailsArray[5].value.push({
      key: "Has Lift",
      value: propertyInfo.equip_lift_bool ? "Yes" : "No",
    });
    detailsArray[5].value.push({
      key: "Guest Toilet",
      value: propertyInfo.equip_bath_guest_toilet ? "Yes" : "No",
    });
    detailsArray[5].value.push({
      key: "Kitched fitted",
      value: propertyInfo.equip_kitchen_fitted ? "Yes" : "No",
    });

    // Object Features
    detailsArray[6].value.push({
      key: "Rooms",
      value: propertyInfo.prop_rooms_amount || "N/a",
    });
    detailsArray[6].value.push({
      key: "Property Type",
      value: propertyInfo.property_type || "N/a",
    });
    detailsArray[6].value.push({
      key: "Costruction Year",
      value: propertyInfo.prop_construction_year || "N/a",
    });
    detailsArray[6].value.push({
      key: "Newly constructed",
      value: propertyInfo.prop_building_new ? "Yes" : "N/a",
    });
    detailsArray[6].value.push({
      key: "Contains floorplan",
      value: propertyInfo.contains_object_floorplan ? "Yes" : "N/a",
    });
    detailsArray[6].value.push({
      key: "Parking Space",
      value: propertyInfo.parking ? "Yes" : "N/a",
    });
    detailsArray[6].value.push({
      key: "Balcony",
      value: propertyInfo.equip_balcony ? "Yes" : "N/a",
    });
    detailsArray[6].value.push({
      key: "Garden",
      value: propertyInfo.equip_garden ? "Yes" : "N/a",
    });
    detailsArray[6].value.push({
      key: "Terrace",
      value: propertyInfo.equip_terrace ? "Yes" : "N/a",
    });
    //check
    detailsArray[6].value.push({
      key: "Energy Certificate",
      value: propertyInfo.energy_certificate || "N/a",
    });
    detailsArray[6].value.push({
      key: "Energy Efficiency Class",
      value: propertyInfo.energy_effenciency_class || "N/a",
    });
    detailsArray[6].value.push({
      key: "Heating Type",
      value: propertyInfo.obj_heatingtype || "N/a",
    });
    // misc->obj_data->details->Substantial Energy Sources:

    // Provider Details
    // detailsArray[7].value.push({
    //   key: "Logo",
    //   value: (
    //     <img
    //       style={{ maxWidth: "150px" }}
    //       src={propertyInfo.provider_company.realtorLogo}
    //       alt="logo"
    //     />
    //   ),
    // });
    detailsArray[7].value.push({
      key: "Provider Company",
      value: propertyInfo.provider_company_name || "N/a",
    });
    detailsArray[7].value.push({
      key: "Provider Email Address",
      value: propertyInfo.provider_email_address || "N/a",
    });
  }
  return detailsArray;
};
