import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { DateRangePicker } from "react-dates";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import { realtorStatsTabsOperationMapper } from "../../utils/constants";
import TableComponent from "../common/tableComponent";

const RealtorStats = (props) => {
  const [focusedInput, setFocusedInput] = useState(null);

  const {
    startDate,
    endDate,
    handleDateChange,
    isLoading,
    listLogs,
    activetab,
    handleSearch,
    userIdAwailable,
  } = props;

  // const setDate = (startDate, endDate) => {
  //   setstartDate(startDate);
  //   setendDate(endDate);
  // };

  const redirectComponent = (cell, row, redirectionPath) => (
    <Link
      target="_blank"
      style={{
        cursor: "pointer",
        color: "#000",
        textDecoration: "underline",
      }}
      to={redirectionPath + cell}
    >
      {cell}
      <i
        style={{ marginLeft: "5px" }}
        className="fa fa-link"
        aria-hidden="true"
      ></i>
    </Link>
  );

  let columns = [
    {
      dataField: "object_id",
      text: "Object Id",
      sort: false,
      headerStyle: { verticalAlign: "center" },
      formatter: (cell, row) => redirectComponent(cell, row, "/object-detail/"),
    },
    {
      dataField: "count",
      text: "Count",
      sort: true,
      headerStyle: { verticalAlign: "center" },
    },
    {
      dataField: "date",
      text: "Created Date",
      sort: true,
      headerStyle: { verticalAlign: "center" },
    },
  ];

  if (userIdAwailable) {
    columns = [
      {
        dataField: "user_id",
        text: "User Id",
        sort: false,
        headerStyle: { verticalAlign: "center" },
        formatter: (cell, row) => redirectComponent(cell, row, "/user-detail/"),
      },
      ...columns,
    ];
  }
  const headers = columns.map(({ dataField, text }) => ({
    label: text,
    key: dataField,
  }));

  const handleDateChangeFilter = () => {
    const params = {
      datefilterApplyOn: realtorStatsTabsOperationMapper[activetab],
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: moment(endDate).format("YYYY-MM-DD"),
    };
    handleSearch(params);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-end mb-4">
          <span className="mr-2 date-picker-span">
            <label className="filter-title">Select Dates</label>
            <DateRangePicker
              startDate={startDate}
              startDateId="12345"
              endDate={endDate}
              endDateId="7896"
              onDatesChange={({ startDate, endDate }) =>
                handleDateChange(startDate, endDate)
              }
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
              isOutsideRange={() => false}
              displayFormat="YYYY-MM-DD"
            />
          </span>
          <button
            id="search-btn"
            type="button"
            className="btn"
            onClick={handleDateChangeFilter}
          >
            Search
            <i
              className="fa fa-search"
              aria-hidden="true"
              style={{ marginLeft: "10px" }}
            ></i>
          </button>
        </div>
        <div className="mb-3">
          <CSVLink
            filename={activetab + ".csv"}
            className="text-decoration-none ml-2"
            separator={","}
            headers={headers}
            data={listLogs || []}
          >
            <button
              type="submit"
              disabled={isLoading}
              style={{ minWidth: "120px" }}
              className="btn btn-primary"
            >
              Export CSV
            </button>
          </CSVLink>
        </div>
      </div>
      <div>
        <TableComponent
          data={listLogs || []}
          isLoading={isLoading}
          keyField="object_id"
          columns={columns}
        />
      </div>
    </div>
  );
};

export default RealtorStats;

RealtorStats.propTypes = {
  listLogs: PropTypes.array,
  csvFileName: PropTypes.string,
  userIdAwailable: PropTypes.bool,
};
