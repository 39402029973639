import React, { useEffect, useState } from "react";
import Modal from "react-awesome-modal";
import { Link } from "react-router-dom";
import { callRequestAPI } from "../../services/callApi";
import { getDateWithTime } from "../../utils/tableData";
import ModalComponent from "../../utils/modalComponent";
import { mailHeaders } from "./mailUtils";
import TableComponent from "../common/tableComponent";

let deleteId = "";

export default function NewsLetterTemplateList() {
  const [isLoading, setisLoading] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showModal, setshowModal] = useState(false);

  useEffect(() => {
    getTemplateList();
  }, []);

  const handleOpenModal = (row) => {
    setIsModalOpen(true);
    setSelectedTemplate(row);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTemplate(null);
  };

  const deleteMailTemplate = async () => {
    setisLoading(true);
    const response = await callRequestAPI(
      `v1/mailtemplates/${deleteId}`,
      null,
      "delete"
    );

    if (response.status === 200) {
      setshowModal(false);
      getTemplateList();
    } else {
      setisLoading(false);
    }
  };

  const getTemplateList = async () => {
    setisLoading(true);
    const response = await callRequestAPI("v1/mailtemplates", null, "get");

    if (response.status === 200 && response.data) {
      const data = response.data.data.map((template) => ({
        rawdata: template.rawdata,
        id: template.id,
        sender_email: template.sender_email || "-",
        subject: template.subject || "-",
        title: template.title || "-",
        createddate: getDateWithTime(template.createddate),
        updateddate: getDateWithTime(template.updateddate),
        data: Buffer.from(template.data, "base64").toString("utf-8"),
      }));

      setTemplateList(data);
      setisLoading(false);
    } else {
      setisLoading(false);
    }
  };

  const duplicateMail = async (id) => {
    setisLoading(true);
    const response = await callRequestAPI(
      `v1/mailtemplates/duplicateTemplate/${id}`,
      {},
      "postHeader"
    );
    if (response.status === 201 && response.data) {
      getTemplateList();
    } else {
      setisLoading(false);
    }
  };

  const actionFormatter = (cell, row) => (
    <>
      <button
        type="button"
        className="btn btn-primary mr-2"
        onClick={() => handleOpenModal(row)}
        title={"View Mail Template"}
      >
        <i className="fa fa-eye" style={{ margin: "0" }} />
      </button>
      <Link
        to={`/mail-template/${row.id}`}
        type="button"
        className="btn btn-primary mr-2"
        title={"Edit Mail Template"}
      >
        <i className="fa fa-pencil" style={{ margin: "0" }} />
      </Link>
      <button
        type="button"
        className="btn btn-primary mr-2"
        onClick={() => duplicateMail(row.id)}
        title={"Duplicate Mail Template"}
      >
        <i className="fa fa-copy" style={{ margin: "0" }} />
      </button>
      <button
        type="button"
        className="btn btn-primary mr-2"
        onClick={() => {
          setshowModal(true);
          deleteId = row.id;
        }}
        title={"Delete Mail Template"}
      >
        <i className="fa fa-trash" style={{ margin: "0" }} />
      </button>
    </>
  );

  const headers = [
    ...mailHeaders,
    {
      dataField: "actions",
      text: "Actions",
      formatter: actionFormatter,
      headerStyle: {
        textAlign: "center",
        verticalAlign: "middle",
      },
    },
  ];

  return (
    <div className="pcoded-content fade-in">
      <div className="pcoded-main-container" style={{ marginTop: "none" }}>
        <div className="pcoded-wrapper">
          <div className="page-header card" style={{ border: "none" }}>
            <div className="row align-items-end">
              <div className="col-lg-5">
                <div className="page-header-title">
                  <i
                    className="feather icon-mail"
                    aria-hidden="true"
                    style={{ background: "#932849" }}
                  ></i>
                  <div>
                    <h5
                      className="header-style"
                      style={{ display: "inline-block" }}
                    >
                      Email Template List
                    </h5>
                  </div>
                  {isLoading && (
                    <i
                      className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                      title="Loading data..."
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-3"></div>
              <div className="col-lg-4 d-flex justify-content-end">
                <Link
                  className="btn btn-primary btn-sm mr-3"
                  to={"/mail-history"}
                >
                  View histroy
                </Link>
                <Link
                  className="btn btn-primary btn-sm"
                  to={"/mail-template/new"}
                >
                  Add New Template
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-body" style={{ marginBottom: "0" }}>
                <div className="card">
                  <div className="card-block">
                    <div className="row">
                      <div className="col-xs-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        <TableComponent
                          data={templateList}
                          columns={headers}
                          tableHeading={"Email Template List"}
                          isLoading={isLoading}
                          tableSearch
                        />
                        <div className="large-modal">
                          <Modal
                            visible={isModalOpen}
                            effect="fadeInUp"
                            onClickAway={handleCloseModal}
                            width="90%"
                          >
                            <div
                              className="popup-div"
                              dangerouslySetInnerHTML={{
                                __html: (selectedTemplate || {}).data,
                              }}
                            ></div>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <ModalComponent
          title={"Delete Mail Template"}
          bodyMsg={"Are you sure you want to Delete this mail template?"}
          closeModal={() => setshowModal(false)}
          handleYes={deleteMailTemplate}
          btnText="No"
        />
      )}
    </div>
  );
}
