import React, { Component } from "react";
import { callRequestAPI } from "../../services/callApi";
import { headerStyle } from "../objects/objectListColumns";
import EditRatesPopup from "./editRates";
import { rateCols } from "./ratesColumns";
import "./rates.css";
import TableComponent from "../common/tableComponent";

export default class BankRates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ratesList: [],
      isLoading: true,
      id: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getBankRates();
  }

  render() {
    const { isLoading, ratesList } = this.state;

    const actionFormatter = (cell, row) => (
      <div>
        <span
          className="waves-effect waves-dark pcoded-micon"
          style={{ fontSize: "20px", cursor: "pointer" }}
          title="Edit Rates"
          onClick={() => this.setState({ id: row.id, title: row.title })}
        >
          <i className="feather icon-edit-2"></i>
        </span>
      </div>
    );

    const bankRatesColumns = [
      ...rateCols,
      {
        dataField: "actions",
        text: "Actions",
        formatter: actionFormatter,
        headerStyle: headerStyle,
      },
    ];

    return (
      <div className="pcoded-content fade-in bank-rates-list">
        <div className="pcoded-main-container" style={{ marginTop: "none" }}>
          {/* Header bar */}
          <div className="pcoded-wrapper">
            <div className="page-header card" style={{ border: "none" }}>
              <div className="row align-items-end">
                <div className="col-lg-5">
                  <div className="page-header-title">
                    <i
                      className="fa fa-paperclip"
                      aria-hidden="true"
                      style={{ background: "#932849" }}
                    ></i>
                    <div>
                      <h5
                        className="header-style"
                        style={{
                          display: "inline-block",
                        }}
                      >
                        Bank Rates Management
                      </h5>
                    </div>

                    {/* {isLoading ? (
                      <i
                        className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                        title="Loading data..."
                      />
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Table area */}
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-body">
                  <div className="card">
                    <div className="card-block">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12">
                          {/* {!isLoading && bankRatesColumns.length > 0 && ( */}
                          <TableComponent
                            data={ratesList}
                            isLoading={isLoading}
                            columns={bankRatesColumns}
                            hidePagination
                            tableHeading={"Bank Rates"}
                            tableSearch
                          />
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.id !== "" && (
          <EditRatesPopup
            id={this.state.id}
            title={this.state.title}
            closeModal={() => this.setState({ id: "", title: "" })}
            updateRates={this.updateRates}
          />
        )}
      </div>
    );
  }

  getBankRates = async () => {
    const response = await callRequestAPI(`v1/bankrates`, null, "get");

    if (response.status === 200 && response.data) {
      let docList = [];
      response.data.data.forEach((prop) => {
        const obj = {
          id: prop.id,
          title: this.capitalize(prop.title.replaceAll("_", " ").toLowerCase()),
          ltv_0_4: prop.ltv_0_4,
          ltv_5_9: prop.ltv_5_9,
          ltv_10_14: prop.ltv_10_14,
          ltv_15_19: prop.ltv_15_19,
          ltv_20_24: prop.ltv_20_24,
          ltv_25_29: prop.ltv_25_29,
          ltv_30_34: prop.ltv_30_34,
          ltv_35_39: prop.ltv_35_39,
          ltv_40_or_plus: prop.ltv_40_or_plus,
        };

        docList.push(obj);
      });

      this.setState({
        ratesList: docList,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  updateRates = async (rates) => {
    const id = this.state.id;
    this.setState({ isLoading: true, id: "", title: "" });

    const response = await callRequestAPI(
      `v1/bankrates/${id}`,
      rates,
      "putHeader"
    );

    if (response.status === 200 && response.data) {
      this.getBankRates();
    } else {
      this.setState({ isLoading: false });
    }
  };
}
