export const INITIAL_STATE = {
  response: [],
};

export const objectListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_OBJECT_LIST_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data.data ? action.payload.data.data : [],
      };

    case "FETCH_OBJECT_LIST_PENDING":
      return {
        status: "pending",
      };

    case "CLEAR_OBJECT_LIST":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};

export const duplicateObjectListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "FETCH_DUPLICATE_LIST_FULFILLED":
            return {
                status: "success",
                data: action.payload.data.data ? action.payload.data : [],
            };

        case "FETCH_DUPLICATE_LIST_PENDING":
            return {
                status: "pending",
            };

        case "CLEAR_OBJECT_LIST":
            return {
                ...INITIAL_STATE,
            };

        default:
            return {
                ...state,
            };
    }
};

export const objectDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_OBJECT_DETAILS_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "FETCH_OBJECT_DETAILS_PENDING":
      return {
        status: "pending",
      };

    default:
      return {
        ...state,
      };
  }
};

export const changeObjectStatusReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CHANGE_OBJ_STATUS_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "CHANGE_OBJ_STATUS_PENDING":
      return {
        status: "pending",
      };

    case "CHANGE_OBJ_STATUS_FAILED":
      return {
        data: action.payload,
        status: "fail",
      };

    case "CLEAR_CHANGE_STATUS":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};

export const quarterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_QUARTER_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "FETCH_QUARTER_PENDING":
      return {
        status: "pending",
      };

    case "CLEAR_FETCH_QUARTER":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};
