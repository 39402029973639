import React from "react";
import Modal from "react-awesome-modal";
import PropTypes from "prop-types";

function ModalComponent(props) {
  return (
    <div>
      <Modal
        visible={true}
        className={"align-items-start"}
        effect="fadeInUp"
        width={props.width || "auto"}
        onClickAway={() => props.closeModal()}
      >
        <div
          style={{
            padding: "25px",
            textAlign: "center",
            // height: "182px",
            width: "446px",
          }}
        >
          <h5 style={{ textTransform: "uppercase" }}>{props.title}</h5>
          {props.bodyMsgIsElement
            ? props.bodyMsg
            : <p style={{ height: "55px" }}>{props.bodyMsg}</p>
          }


          {props.handleYes && (
            <button
              className="btn btn-primary btn-sm mr-3"
              onClick={() => props.handleYes()}
            >
              Yes
            </button>
          )}

          <button
            className="btn btn-primary btn-sm"
            onClick={() => props.closeModal()}
          >
            {props.btnText ? props.btnText : "Cancel"}
          </button>
        </div>
      </Modal>
    </div >
  );
}

ModalComponent.propTypes = {
  title: PropTypes.string,
  btnText: PropTypes.string,
  bodyMsg: PropTypes.any,
  bodyMsgIsElement: PropTypes.bool,
  width: PropTypes.string,
  handleYes: PropTypes.func,
  closeModal: PropTypes.func,
};

export default ModalComponent;
