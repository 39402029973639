import React, { useState } from "react";
import Navigation from "./navigation";
import { Link } from "react-router-dom";

import "../../assets/css/style.css";
import "../../assets/css/widget.css";
import "../../assets/css/pages.css";
import "../../assets/css/custom.css";
import "../../assets/css/animation.scss";
import "../../assets/icons/feather/css/feather.css";
import "../../assets/css/waves.min.css";

const MainLayout = (props) => {
  const [currentpath, setPath] = useState(getPath());
  return (
    <div>
      <div id="pcoded" className="pcoded fade-in">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
          <Navigation />

          <div className="pcoded-main-container" style={{ marginTop: "70px" }}>
            <div className="pcoded-wrapper">
              <nav
                className="pcoded-navbar"
                navbar-theme="theme1"
                active-item-theme="theme1"
                sub-item-theme="theme2"
                active-item-style="style0"
                pcoded-navbar-position="fixed"
              >
                <div className="nav-list">
                  <div className="slimScrollDiv slimStyle">
                    <div className="pcoded-inner-navbar main-menu pcodedinnerclass">
                      <ul
                        className="pcoded-item pcoded-left-item"
                        item-border="true"
                        item-border-style="solid"
                        subitem-border="false"
                      >
                        {menuItems.map((menu, index) => (
                          <li
                            className={
                              currentpath === menu.value ? "active" : ""
                            }
                            key={index}
                          >
                            <Link
                              to={menu.path}
                              className="waves-effect waves-dark"
                              onClick={() => setPath(menu.value)}
                            >
                              <span className="pcoded-micon">
                                <i className={menu.icon}></i>
                              </span>
                              <span className="pcoded-mtext">{menu.title}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="slimScrollBar sidenav"></div>
                    <div className="slimScrollRail raildiv"></div>
                  </div>
                </div>
              </nav>
              {/* start */}
              {/* eslint-disable-next-line react/prop-types */}
              {props.children}
              {/* end */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;

const getPath = () => {
  const path = window.location.pathname;
  for (let i = 0; i < menuItems.length; i++) {
    const element = menuItems[i];
    if (path === element.path) {
      return element.value;
    }
  }

  return "dashboard";
};

const menuItems = [
  {
    title: "Dashboard",
    path: "/",
    value: "dashboard",
    icon: "feather icon-home",
  },
  {
    title: "Users",
    path: "/user",
    value: "user",
    icon: "feather icon-users",
  },
  {
    title: "Objects",
    path: "/objects",
    value: "objects",
    icon: "feather icon-command",
  },
  {
    title: "Duplicate Management",
    path: "/objects/duplicates",
    value: "duplicates",
    icon: "feather icon-copy",
  },
  {
    title: "Realtor Managment",
    path: "/realtor",
    value: "realtor",
    icon: "feather icon-grid",
  },
  {
    title: "Bank Rates",
    path: "/bank-rates",
    value: "bankRates",
    icon: "feather icon-bar-chart-2",
  },
  {
    title: "Question Management",
    path: "/question",
    value: "question",
    icon: "feather icon-help-circle",
  },
  // {
  //   title: "Requested Documents",
  //   path: "/doclist",
  //   value: "doclist",
  //   icon: "feather icon-paperclip",
  // },
  // {
  //   title: "Requested Showings",
  //   path: "/showlist",
  //   value: "showlist",
  //   icon: "feather icon-image",
  // },
  {
    title: "Immozy Center",
    path: "/immozy-center",
    value: "immozyCenter",
    icon: "feather icon-info",
  },
  {
    title: "Newsletter Email",
    path: "/mail-template",
    value: "mail",
    icon: "feather icon-mail",
  },
  {
    title: "Settings",
    path: "/settings",
    value: "settings",
    icon: "feather icon-settings",
  },
];
