import React, { useEffect, useState } from "react";
import { callRequestAPI } from "../../services/callApi";
import { getDateWithTime } from "../../utils/tableData";
import Modal from "react-awesome-modal";
import { mailHistoryColumns } from "./mailUtils";
import { Link } from "react-router-dom";
import backArrow from "../../assets/images/whitearrow.png";
import TableComponent from "../common/tableComponent";

export default function MailHistory() {
  const [isLoading, setisLoading] = useState(true);
  const [historyList, sethistoryList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [stasticsData, setStasticsdata] = useState(null);

  useEffect(() => {
    isLoading && historyList.length === 0 && getHistory();
  });

  const getHistory = async () => {
    const response = await callRequestAPI(
      "v1/mailtemplates/templatehistory ",
      null,
      "get"
    );

    if (response.status === 200 && response.data) {
      const data = response.data.data.map((template) => ({
        city: template.city || "All",
        recipient_count: template.recipient_count || "-",
        title: template.title || "-",
        datetime: getDateWithTime(template.datetime),
        data: Buffer.from(template.data, "base64").toString("utf-8"),
      }));
      sethistoryList(data);
      setisLoading(false);
    } else {
      setisLoading(false);
    }
  };

  const getStats = async () => {
    setisLoading(true);
    const response = await callRequestAPI(
      "v1/mailtemplates/getStatistics",
      {},
      "postHeader"
    );

    if (response.status === 201 && response.data) {
      setisLoading(false);
      setStasticsdata(response.data.data);
    } else {
      setisLoading(false);
      console.log(response.message);
    }
  };

  const handleOpenModal = (row) => {
    setIsModalOpen(true);
    setSelectedTemplate(row);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTemplate(null);
  };

  const actionFormatter = (cell, row) => (
    <>
      <button
        type="button"
        className="btn btn-primary mr-2"
        onClick={() => handleOpenModal(row)}
        title={"View Mail Template"}
      >
        <i className="fa fa-eye" style={{ margin: "0" }} />
      </button>
    </>
  );

  const headers = [
    ...mailHistoryColumns,
    {
      dataField: "actions",
      text: "Actions",
      formatter: actionFormatter,
      headerStyle: {
        textAlign: "center",
        verticalAlign: "middle",
      },
    },
  ];

  return (
    <div className="pcoded-content fade-in">
      <div className="pcoded-main-container" style={{ marginTop: "none" }}>
        <div className="pcoded-wrapper">
          <div className="page-header card" style={{ border: "none" }}>
            <div className="row align-items-end">
              <div className="col-lg-5">
                <div className="page-header-title">
                  <Link to="/mail-template">
                    <img src={backArrow} className="back-arrow" alt="img"></img>
                  </Link>
                  <div>
                    <h5
                      className="header-style"
                      style={{ display: "inline-block" }}
                    >
                      Newsletter Mail History Log
                    </h5>
                  </div>
                  {/* {isLoading && (
                    <i
                      className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                      title="Loading data..."
                    />
                  )} */}
                </div>
              </div>
              <div className="col-lg-3"></div>
              <div className="col-lg-4 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={() => getStats()}
                  title={"It will be of last 2 weeks"}
                >
                  Get Mail Statistics
                  <i className="fa fa-bar-chart ml-3" style={{ margin: "0" }} />
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-body" style={{ marginBottom: "0" }}>
                <div className="card">
                  <div className="card-block">
                    <div className="row">
                      <div className="col-xs-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        <TableComponent
                          data={historyList}
                          columns={headers}
                          tableHeading={"Email Template List"}
                          isLoading={isLoading}
                          tableSearch
                        />
                        <div className="large-modal">
                          <Modal
                            visible={isModalOpen}
                            effect="fadeInUp"
                            onClickAway={handleCloseModal}
                            width="90%"
                          >
                            <div
                              className="popup-div"
                              dangerouslySetInnerHTML={{
                                __html: (selectedTemplate || {}).data,
                              }}
                            ></div>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {stasticsData && (
        <Modal
          visible={true}
          effect="fadeInUp"
          onClickAway={() => setStasticsdata(null)}
          width="420px"
        >
          <div className="p-5">
            <h5 className="mb-3">Lastest 2 weeks stats of mail sent:</h5>

            <table className="mail-stats-table">
              {[
                "Bounces",
                "Complaints",
                "DeliveryAttempts",
                "Rejects",
                "avgBounce",
                "avgComplaints",
                "avgDelivery",
                "avgReject",
              ].map((status, index) => (
                <tr key={index} className="mb-1">
                  <td>
                    <b>{status}</b>:
                  </td>
                  <td>{stasticsData[status]}</td>
                </tr>
              ))}
            </table>
          </div>
        </Modal>
      )}
      <div className="large-modal">
        <Modal
          visible={isModalOpen}
          effect="fadeInUp"
          onClickAway={handleCloseModal}
          width="90%"
        >
          <div
            className="popup-div"
            dangerouslySetInnerHTML={{
              __html: (selectedTemplate || {}).data,
            }}
          ></div>
        </Modal>
      </div>
    </div>
  );
}
