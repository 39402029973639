import callAPI from "../services/callApi";

export const getQuestionsList = () => ({
  type: "FETCH_QUESTIONS_LIST",
  payload: callAPI(`v1/questions`, "GET"),
});

export const deleteQuestion = (id) => ({
  type: "DELETE_QUESTION",
  payload: callAPI(`v1/questions/${id}`, "DELETE"),
});

export const addQuestion = (questionObj) => ({
  type: "ADD_QUESTION",
  payload: callAPI(`v1/questions`, "POST", questionObj),
});

export const updateQuestion = (id, questionObj) => ({
  type: "EDIT_QUESTION",
  payload: callAPI(`v1/questions/${id}`, "PUT", questionObj),
});

export const getQuestionDetails = (id) => ({
  type: "FETCH_QUESTION_DETAILS",
  payload: callAPI(`v1/questions/${id}`, "GET"),
});
