import { lang } from "../../utils/constants";
import { getDateWithTime } from "../../utils/tableData";

export const tabConstants = {
  USER_DATA: "User Data",
  USER_DOCUMENT: "User Document",
  ONBOARDING: "Onboarding Answers",
  FINANCE_ONBOARDING: "Finance Onboarding",
  USER_STAT: "User Stats",
  FAVO: "Favorites",
};

export const userTabs = Object.values(tabConstants);

export const createDetailArray = (userObj) => {
  let userStatsArray = [];
  let userDetailArray = [];
  let utmObject = [];
  // Array of user statistics
  const statResult = Object.keys(userObj.userStat).map(function (key) {
    try {
      let keyName = key.split("Count");
      keyName =
        keyName.length > 1 && !keyName[1].includes("Count")
          ? keyName[0] + " Count"
          : keyName[0];
      return [keyName, userObj.userStat[key] ? userObj.userStat[key] : 0];
    } catch (error) {
      return [key, "-"];
    }
  });
  userStatsArray.push(...statResult);

  // Array of user details dump
  let result = Object.keys(userObj.details).map(function (key) {
    try {
      if (key === "utm") {
        if (userObj.details[key] === null) {
          return [key, "Organic User"];
        } else {
          const result = Object.keys(userObj.details[key]).map(function (key) {
            const value = userObj.details.utm[key];
            return [key, value];
          });
          utmObject.push(...result);
        }
      }

      if (userObj.details[key] !== null) {
        if (
          key === "createdDate" ||
          key === "lastActiveDate" ||
          key === "lastLoginDate"
        ) {
          const value = getDateWithTime(userObj.details[key]);
          return [key, value];
        } else if (typeof userObj.details[key] === "boolean") {
          return [key, userObj.details[key] ? "Yes" : "No"];
        } else if (typeof userObj.details[key] === "object") {
          // eslint-disable-next-line
          return;
        }
      }
    } catch (error) {
      return [key, "-"];
    }

    return [key, userObj.details[key]];
  });
  result = result.filter((i) => i !== undefined);

  userDetailArray.push(...result);

  return { userDetailArray, userStatsArray, utmObject };
};

export const setAnswersArray = (list) => {
  let result = [];

  list.questions.forEach((q, i) => {
    let a = null;
    const selectedOptions = q.options.filter((i) => i.isSelected === true);

    if (
      q.questionId === "01d03868-e8ad-4c8b-920b-ad2b8f368dff" ||
      q.questionId === "fd20ac95-fb3c-4453-9b74-524a8a92fb59"
    ) {
      let answer = "";

      const inputTypeAns = selectedOptions.filter((i) => i.type === "INPUT");
      if (inputTypeAns.length > 0) {
        answer = "Value: " + (inputTypeAns[0].value || "Not given") + ", ";
      }
      //Checkbox for I own property ticked?
      const checkboxType = selectedOptions.filter((i) => i.type === "CHECKBOX");
      const label =
        q.questionId === "fd20ac95-fb3c-4453-9b74-524a8a92fb59"
          ? " I don't know yet ticked?: "
          : " Checkbox for I own property ticked?: ";

      if (checkboxType.length > 0) {
        const newAns = +checkboxType[0].isSelected ? "Yes" : "No";
        answer = answer + label + newAns;
      } else {
        answer = answer + label + " No";
      }

      a = {
        questionTitle: q.question[lang.EN],
        ans: answer,
        index: i + 1,
      };
    } else if (selectedOptions.length === 1) {
      if (selectedOptions[0].type === "INPUT") {
        a = {
          questionTitle: q.question[lang.EN],
          ans: selectedOptions[0].value,
          index: i + 1,
        };
      } else if (selectedOptions[0].type === "DOUBLE_RANGE") {
        a = {
          questionTitle: q.question[lang.EN],
          ans:
            "From: " +
            selectedOptions[0].value[0] +
            ", to: " +
            selectedOptions[0].value[1],
          index: i + 1,
        };
      } else {
        a = {
          questionTitle: q.question[lang.EN],
          ans: selectedOptions[0].option["en"],
          index: i + 1,
        };
      }
    } else if (selectedOptions.length > 1) {
      let ans = [];
      selectedOptions.forEach((s, i) =>
        ans.push(selectedOptions[i].option["en"])
      );

      a = {
        questionTitle: q.question[lang.EN],
        ans: ans,
        index: i + 1,
      };
    } else {
      if (q.questionId === "a2b308c4-91a8-4c30-9e67-1c379a3c9c39") {
        a = {
          questionTitle: q.question[lang.EN],
          ans: "Contact Form : Check User Data",
          index: i + 1,
        };
      } else {
        a = {
          questionTitle: q.question[lang.EN],
          ans: "Not given",
          index: i + 1,
        };
      }
    }
    result.push(a);
  });
  return result;
};

export const setFavProperties = (propertyList) => {
  let list = [];
  propertyList.forEach((prop, index) => {
    const a = {
      sr: index + 1,
      id: prop.id,
      objectTitle: prop.objectTitle,
      image: prop.objectTitlePicture,
      adrCity: prop.adrCity,
      adrPostalCode: prop.adrPostalCode,
      price: prop.priceFirst,
      rooms: prop.propRoomsAmount || "n/a",
      objectTitlePicture: prop.objectTitlePicture,
    };
    list.push(a);
  });

  return list;
};
