import { applyMiddleware, createStore, compose } from "redux";
import promise from "redux-promise-middleware";
import rootReducer from "../reducers/rootReducer";

import thunk from "redux-thunk";

const isProdcution = false;

const composeEnhancers =
  !isProdcution &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const middleware = applyMiddleware(thunk, promise);

const enhancer = composeEnhancers(middleware);

export function configureStore(initialState = {}) {
  const store = createStore(rootReducer, initialState, enhancer);
  return store;
}

export const store = configureStore();
