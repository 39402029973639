import React from 'react';
import PropTypes from "prop-types";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { defaultSorted, SearchBar } from '../../utils/tableData';
import BootstrapTable from 'react-bootstrap-table-next';
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const BootstrapTableComp = (props) => (
    <BootstrapTable
        striped
        rowStyle={{ textAlign: "center" }}
        noDataIndication={
            props.isLoading ?
                <Skeleton
                    baseColor='#ddd4d7'
                    width={"100%"}
                    count={5}
                    height={"50px"}
                    className="mb-2"
                /> : "No data found."
        }
        filter={filterFactory()}
        {...props}
    />
)

const TableComponent = (tableProps) => {
    const { data, columns, defaultSortedOption, paginationOptions, isLoading, tableHeading,
        tableSearch, hidePagination, keyField, showTotalNearHeading } = tableProps;
    return (
      <ToolkitProvider
        bootstrap4
        keyField={keyField || "id"}
        data={data}
        columns={columns}
        defaultSorted={defaultSortedOption || defaultSorted}
        tabIndexCell
        search={tableSearch}
      >
        {(prop) => (
          <div>
            <div
              className="row"
              style={{ marginBottom: "1%", alignItems: "center" }}
            >
              <div
                className="col-xs-12 col-sm-12 col-sm-12 col-md-6"
                style={{ margintop: "1%" }}
              >
                {tableHeading && (
                  <h5 style={{ display: "inline-block" }}>
                    {`${tableHeading}${
                      showTotalNearHeading ? " : " + data.length : ""
                    }`}
                  </h5>
                )}
              </div>
              <div
                className="col-xs-12 col-sm-12 col-md-6"
                style={{ float: "left" }}
              >
                <div
                  id="DataTables_Table_0_filter"
                  style={{ float: "right" }}
                  className="dataTables_filter"
                >
                  {tableSearch && (
                    <>
                      <label className="mr-1">Search: </label>
                      <SearchBar {...prop.searchProps} />
                    </>
                  )}
                </div>
              </div>
            </div>
            {!hidePagination ? (
              <PaginationProvider
                pagination={paginationFactory(paginationOptions)}
              >
                {({ /*  paginationProps, */ paginationTableProps }) => (
                  <BootstrapTableComp
                    isLoading={isLoading}
                    {...prop.baseProps}
                    {...paginationTableProps}
                  />
                )}
              </PaginationProvider>
            ) : (
              <BootstrapTableComp isLoading={isLoading} {...prop.baseProps} />
            )}
          </div>
        )}
      </ToolkitProvider>
    );
};

TableComponent.propTypes = {
    keyField: PropTypes.string,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    paginationOptions: PropTypes.object,
    defaultSortedOption: PropTypes.array,
    isLoading: PropTypes.bool,
    hidePagination: PropTypes.bool,
    tableSearch: PropTypes.bool,
    showTotalNearHeading: PropTypes.bool,
    tableHeading: PropTypes.string,
};

export default TableComponent;