import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { callRequestAPI } from "../../services/callApi";
import { ConvertToSnakeCase } from "../../utils/utility";
import ModalComponent from "../../utils/modalComponent";
import Skeleton from "react-loading-skeleton";

let id = "";

export default function HouseTypeSettings(props) {
  const [housingList, sethousingList] = useState([]);
  const [enableBtn, setenableBtn] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { isLoading, setisLoading, showToasterSuccess, showToasterError } =
    props;

  useEffect(
    () => {
      !isLoading && getHousingListData();
    },
    // eslint-disable-next-line
    []
  );

  const getHousingListData = async () => {
    setisLoading(true);
    const response = await callRequestAPI(
      `v1/scoreweighting/listHousingType`,
      {},
      "get"
    );

    if (response.status === 200 && response.data && response.data.data) {
      const data = response.data.data[0].housing_type;
      id = response.data.data[0].id;

      sethousingList({
        apartment: data.apartment,
        double_or_townhouse: data.double_or_townhouse,
        detached_house: data.detached_house,
      });
      setisLoading(false);
    } else {
      setisLoading(false);
      showToasterError("something went wrong");
    }
  };

  const applyChanges = async () => {
    setisLoading(true);
    setShowModal(false);

    const payload = { housing_type: housingList };
    const response = await callRequestAPI(
      `v1/scoreweighting/updateHouseType/${id}`,
      payload,
      "putHeader"
    );
    if (response.status === 200) {
      setisLoading(false);
      setenableBtn(false);
      showToasterSuccess("House Type changes applied successfully.");
    } else {
      setisLoading(false);
    }
  };

  const handleChange = (scoreLabel, field, value) => {
    sethousingList({
      ...housingList,
      [scoreLabel]: {
        ...housingList[scoreLabel],
        [field]: parseInt(value),
      },
    });
    !enableBtn && setenableBtn(true);
  };

  return (
    <div>
      {isLoading
        ? (
          <Skeleton
            height={"300px"}
            baseColor='#ddd4d7'
          />
        ) : (
          <>
            <div className="w-100 d-flex justify-content-end mb-3">
              <button
                className="btn btn-primary"
                onClick={() => setShowModal(true)}
                disabled={!enableBtn}
              >
                Apply Changes
              </button>
            </div>
            <div className="d-flex justify-content-start align-items-stretch row">
              {Object.keys(housingList).map((houseLabel) => (
                <div
                  key={houseLabel}
                  className="col-xl-4 col-lg-6 col-md-6 col-sm-6  col-xs-6 mb-2"
                >
                  <div
                    style={{
                      border: "1px solid #dddddd",
                      borderRadius: "10px",
                      padding: "15px",
                      height: "100%",
                    }}
                  >
                    <div
                      className="row"
                      style={{
                        borderBottom: "1px solid #dddddd",
                        paddingBottom: "12px",
                      }}
                    >
                      <div className="col-md-6 col-sm-6 col-6">
                        <span className="font-weight-bold">
                          {ConvertToSnakeCase(houseLabel)}
                        </span>
                      </div>
                      <div
                        className={
                          houseLabel === "financial"
                            ? "col-md-6  col-sm-6 col-6"
                            : "col-md-3  col-sm-3 col-3"
                        }
                      >
                        <span className="font-weight-bold">Score</span>
                      </div>
                    </div>
                    <br />
                    {Object.keys(housingList[houseLabel]).map((field, index) => (
                      <div className="row mb-2" key={index}>
                        <div className="col-md-6 col-sm-6 col-6">
                          {ConvertToSnakeCase(field)}
                        </div>
                        <div
                          className={
                            houseLabel === "financial"
                              ? "col-md-6  col-sm-6 col-6"
                              : "col-md-3  col-sm-3 col-3"
                          }
                        >
                          <input
                            onChange={({ target }) =>
                              handleChange(houseLabel, field, target.value)
                            }
                            type="number"
                            className="full-width form-control"
                            placeholder={"Not Added"}
                            value={housingList[houseLabel][field]}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {showModal && (
              <ModalComponent
                title={"Confirmation"}
                bodyMsg={
                  "This will effect all score calculations for user portal. Are you sure you want to update?"
                }
                closeModal={() => setShowModal(false)}
                handleYes={() => applyChanges()}
              />
            )}
          </>
        )}
    </div>
  );
}

HouseTypeSettings.propTypes = {
  isLoading: PropTypes.bool,
  setisLoading: PropTypes.func,
  showToasterSuccess: PropTypes.func,
  showToasterError: PropTypes.func,
};
