import React from "react";
import PropTypes from "prop-types";
import "../../assets/css/tableStyle.css";
import TableComponent from "../common/tableComponent";

function UserAnswers(props) {
  const { questionList } = props;
  return (
    <div className="pcoded-wrapper user-detail-page">
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          <TableComponent
            data={questionList}
            columns={columns}
            hidePagination
            tableHeading={"Onboarding Answers"}
            tableSearch
          />
        </div>
      </div>
    </div>
  );
}

const columns = [
  {
    dataField: "index",
    text: "Sr. No",
    headerStyle: {
      width: "5%",
    },
  },
  {
    dataField: "questionTitle",
    text: "Question",
  },
  {
    dataField: "ans",
    text: "Answer",
  },
];

UserAnswers.propTypes = {
  questionList: PropTypes.array,
};

export default UserAnswers;
