import React from "react";
import PropTypes from "prop-types";

function ChangePassword(props) {
  const fieldTitles = ["Current Password", "New Password", "Confirm Password"];
  const fieldNames = ["password", "newPassword", "confirmPassword"];

  return (
    <div className="card-block">
      <div className="form-group form-primary width-in">
        {fieldNames.map((field, index) => (
          <div key={index}>
            <label className="float-label mt-2">{fieldTitles[index]}</label>
            <input
              type={!props.show.includes(field) ? "password" : "text"}
              name={field}
              className="form-control"
              onChange={props.handleInputChange}
              onKeyDown={props.onKeyDown}
            />
            <span onClick={() => props.setShowPwd(field)}>
              {props.show.includes(field) ? (
                <i className={`eye-con-${index} fa fa-eye-slash`}></i>
              ) : (
                <i className={`eye-con-${index} fa fa-eye`}></i>
              )}
            </span>
          </div>
        ))}
      </div>

      <div className="form-group">
        <button
          type="button"
          data-modal="saveuser"
          className="btn btn-primary btn-sm mr-2"
          onClick={props.submitPassword}
          onKeyDown={props.onKeyDown}
        >
          Save
        </button>
        <button
          data-modal="saveuser"
          className="btn btn-primary btn-sm"
          onClick={props.cancelPassword}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

ChangePassword.propTypes = {
  cancelPassword: PropTypes.func,
  handleInputChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  submitPassword: PropTypes.func,
  show: PropTypes.array,
  setShowPwd: PropTypes.func,
};

export default ChangePassword;
