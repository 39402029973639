import React, { useEffect } from "react";
import { useState } from "react";
import {
  compareDate,
  getDateWithTime,
  tableOptions,
} from "../../utils/tableData";
import { callRequestAPI } from "../../services/callApi";
import ModalComponent from "../../utils/modalComponent";
import { Link } from "react-router-dom";
import {
  headerStyle,
  partnerFilter,
  // realtorTableData,
  stateFilter,
} from "./realtorUtils";
import { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableComponent from "../common/tableComponent";

let realtorId = "";
const recordPerPage = 50;
let deleteId = "";

const tabNames = {
  IMMOZY_REALTORS: "Immozy Realtors",
  SUGGESTED_REALTORS: "Suggested Realtors",
};
const tabs = Object.values(tabNames);

const RealtorManagement = () => {
  const [realtorList, setRealtorList] = useState([]);
  const [suggestedRealtorList, setSuggestedRealtorList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [activetab, setactiveTab] = useState(tabs[0]);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  useEffect(
    () => {
      if (activetab === tabs[0]) {
        realtorList.length === 0 && getRealtorList();
      } else if (activetab === tabs[1]) {
        suggestedRealtorList.length === 0 && getSuggestedRealtorList();
      }
    },
    //eslint-disable-next-line
    [activetab]
  );

  const redirectComponent = (cell, row) => (
    <Link
      target="_blank"
      style={{
        cursor: "pointer",
        color: "#000",
        textDecoration: "underline",
      }}
      to={`/realtor/${row.id}`}
    >
      {cell}
    </Link>
  );
  const raltorComponent = (cell, row) =>
    cell !== "-" ? (
      <Link
        target="_blank"
        style={{
          cursor: "pointer",
          color: "#000",
          textDecoration: "underline",
        }}
        to={`/objects?realtor=${row.id}`}
      >
        {cell}
        <i
          style={{ marginLeft: "5px" }}
          className="fa fa-link"
          aria-hidden="true"
        ></i>
      </Link>
    ) : (
      <span>{cell}</span>
    );

  const actionFormatter = (cell, row) => {
    let iconClassName = "";
    if (row.active === "Enabled") {
      iconClassName = "fa fa-toggle-on";
    } else {
      iconClassName = "fa fa-toggle-off";
    }
    return (
      <div
        className="d-flex align=-items-center"
        style={{ placeContent: "center" }}
      >
        <span className="waves-effect waves-dark pcoded-micon">
          <i
            style={{
              fontSize: "22px",
              marginRight: "5px",
            }}
            className={iconClassName}
            onClick={() => toggleModal(row.id, row.active)}
            title={
              row.active === "Enabled" ? "Disable Realtor" : "Enable Realtor"
            }
          ></i>
        </span>
        <i
          title="Go to Realtor details"
          className="fa fa-external-link-square pointer ml-2"
          aria-hidden="true"
          onClick={() => window.open(`/realtor/${row.id}`, "_blank")}
        ></i>
        <i
          className="fa fa-trash ml-2"
          title={"Delete Realtor"}
          style={{ fontSize: "19px" }}
          onClick={() => {
            setshowDeleteModal(true);
            deleteId = row.id;
          }}
        />
      </div>
    );
  };

  const suggestedactionFormatter = (cell, row) => (
    <div
      className="d-flex align=-items-center"
      style={{ placeContent: "center" }}
    >
      <Link
        to={{
          pathname: "/realtor/new",
          state: {
            data: row,
          },
        }}
        className="btn btn-primary btn-sm"
      >
        Add as Realtor
      </Link>
    </div>
  );

  const toggleModal = (id, active) => {
    if (active === "Enabled") {
      setshowModal(true);
      realtorId = id;
    } else {
      handleRealtorStatusChange(id, false);
    }
  };

  const showToasterSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showToasterError = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const realtorColumns = [
    {
      dataField: "agent_id",
      text: "Agent Id",
      sort: true,
      headerStyle: headerStyle,
      formatter: redirectComponent,
    },
    {
      dataField: "match_email_address",
      text: "Match Mail Address",
      headerStyle: headerStyle,
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "domain",
      text: "Domain",
      headerStyle: headerStyle,
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "provider_company_name",
      text: "Company Name",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "total_offer",
      text: "Total Offers",
      sort: true,
      headerStyle: headerStyle,
      formatter: raltorComponent,
    },
    {
      dataField: "active_offer",
      text: "Active Offers",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "active",
      text: "Enabled Status",
      sort: true,
      headerStyle: headerStyle,
      filter: selectFilter({
        className: "my-custom-select-filter",
        options: stateFilter,
      }),
    },
    {
      dataField: "partner_level",
      text: "Partner Level",
      sort: true,
      headerStyle: headerStyle,
      filter: selectFilter({
        className: "my-custom-select-filter",
        options: partnerFilter,
      }),
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: actionFormatter,
      headerStyle: headerStyle,
    },
  ];

  const suggestedRealtorColumns = [
    {
      dataField: "agent_id",
      text: "Agent Id",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "match_email_address",
      text: "Match Email Address",
      sort: true,
      headerStyle: headerStyle,
      // formatter: redirectComponent,
    },
    {
      dataField: "provider_company_name",
      text: "Company Name",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "count",
      text: "Total Offer count",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: suggestedactionFormatter,
      headerStyle: headerStyle,
    },
  ];

  const deleteRealtor = async () => {
    setisLoading(true);
    const response = await callRequestAPI(
      `v1/realtors/realtors_new/${deleteId}`,
      null,
      "delete"
    );

    if (response.status === 200) {
      setshowDeleteModal(false);
      showToasterSuccess("Realtor deleted successfully.");
      getRealtorList();
    } else {
      setisLoading(false);
    }
  };

  const getRealtorList = async () => {
    setisLoading(true);
    const response = await callRequestAPI(
      `v1/realtors/realtors_new?currentPage=1&recordPerPage=${recordPerPage}`,
      null,
      "get"
    );

    if (response.status === 200 && response.data && response.data.data) {
      const data = response.data.data;
      let list = [];

      data.forEach((prop) => {
        const obj = {
          id: prop.id,
          agent_id: prop.agent_id ? parseInt(prop.agent_id) : null,
          contact_person_name: prop.contact_person_name || "-",
          phone: prop.phone || "-",
          provider_company_name: prop.provider_company_name || "-",
          domain: prop.domain || "-",
          match_email_address: prop.match_email_address || "-",
          createddate: getDateWithTime(prop.createddate),
          updateddate: prop.updateddate
            ? compareDate(prop.createdDate, prop.updateddate)
            : "-",
          website_url: prop.website_url || "-",
          adr_city: prop.adr_city || "-",
          active: prop.active ? "Enabled" : "Disabled",
          street: prop.street || "-",
          partner_level: prop.partner_level,
          data_source: prop.data_source,
          active_offer: prop.active_offer || "-",
          total_offer: prop.total_offer || "-",
          is_info_complited: prop.is_info_complited ? "Yes" : "No",
        };
        list.push(obj);
      });
      setRealtorList(list);
      setisLoading(false);
    } else {
      setisLoading(false);
    }
  };

  const getSuggestedRealtorList = async () => {
    setisLoading(true);

    const response = await callRequestAPI(
      `v1/realtors/suggetions?currentPage=1&recordPerPage=${recordPerPage}`,
      null,
      "get"
    );

    if (response.status === 200 && response.data && response.data.data) {
      setisLoading(false);

      const data = response.data.data;
      let list = [];

      data.forEach((prop, index) => {
        // if (index < 50) {
        const obj = {
          id: index,
          agent_id: prop.agent_id ? parseInt(prop.agent_id) : "-",
          match_email_address: prop.match_email_address
            ? prop.match_email_address.toString()
            : "-",
          count: prop.count || "-",
          provider_company_name: prop.provider_company_name || "-",
        };
        list.push(obj);
        // }
      });
      setSuggestedRealtorList(list);
    } else {
      setisLoading(false);
    }
  };

  const handleRealtorStatusChange = async (id, active) => {
    setisLoading(true);
    const response = await callRequestAPI(
      `v1/realtors/realtors_new/${id}/${!active}`,
      {},
      "putHeader"
    );

    if (response.status === 200 && response.data) {
      showToasterSuccess("Realtor active status updated successfully.");

      getRealtorList();
    } else {
      setisLoading(false);
      showToasterError("Something went wrong. Pleasde try again later.");
    }
  };

  const options = {
    ...tableOptions,
    sizePerPage: 100,
  };

  return (
    <div className="pcoded-content fade-in">
      <div className="pcoded-main-container" style={{ marginTop: "none" }}>
        <div className="pcoded-wrapper">
          <div className="page-header card" style={{ border: "none" }}>
            <div className="row align-items-end">
              <div className="col-lg-5">
                <div className="page-header-title">
                  <i
                    className="feather icon-grid"
                    aria-hidden="true"
                    style={{ background: "#932849" }}
                  ></i>
                  <div>
                    <h5
                      className="header-style"
                      style={{ display: "inline-block" }}
                    >
                      Realtor Management
                    </h5>
                  </div>

                  {/* {isLoading ? (
                    <i
                      className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                      title="Loading data..."
                    />
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
              <div className="col-lg-7">
                <div className="page-header-breadcrumb">
                  <Link
                    className="btn btn-primary btn-sm mr-3"
                    to="/realtor-mail"
                  >
                    Realtor Email Log
                  </Link>
                  <Link className="btn btn-primary btn-sm" to="/realtor/new">
                    Add New Realtor
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-body">
                <div className="card">
                  <div className="card-block">
                    <div className="nav-tabs-wrapper d-flex flex-column flex-md-row justify-content-between align-items-md-center align-items-start mb-4">
                      <ul
                        className="nav nav-tabs order-1 order-md-0"
                        id="myTab"
                        role="tablist"
                      >
                        {tabs.map((tab, index) => (
                          <li className="nav-item" key={index}>
                            <span
                              className={`cursor-pointer ${activetab === tab
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              id="home-tab"
                              role="tab"
                              name={tab}
                              onClick={() => {
                                setactiveTab(tab);
                                localStorage.setItem(
                                  "lastTabOfSettingPage",
                                  tab
                                );
                              }}
                            >
                              {tab}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="mt-2 tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div className="col-xs-12 col-sm-12 realtor-container">
                          <TableComponent
                            data={
                              activetab === tabNames.IMMOZY_REALTORS
                                ? realtorList
                                : suggestedRealtorList
                            }
                            columns={
                              activetab === tabNames.IMMOZY_REALTORS
                                ? realtorColumns
                                : suggestedRealtorColumns
                            }
                            tableHeading={
                              activetab === tabNames.IMMOZY_REALTORS
                                ? "Immozy Realtor List"
                                : "Suggested Realtor List"
                            }
                            isLoading={isLoading}
                            tableSearch
                            paginationOptions={options}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <ModalComponent
          title={"Confirmation"}
          bodyMsg={"Are you sure you want to Disable this realtor?"}
          closeModal={() => setshowModal(false)}
          handleYes={() => {
            setshowModal(false);
            handleRealtorStatusChange(realtorId, true);
          }}
          btnText="No"
        />
      )}
      {showDeleteModal && (
        <ModalComponent
          title={"Delete Realtor"}
          bodyMsg={"Are you sure you want to Delete this realtor?"}
          closeModal={() => setshowDeleteModal(false)}
          handleYes={deleteRealtor}
          btnText="No"
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default RealtorManagement;
