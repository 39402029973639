import * as AWS from "aws-sdk";

/**
 * getS3UploadParams - generates s3-upload parameters
 * @param file - file
 * @param id - ID
 * @param bucket - bucket
 */
export const getS3UploadParams = (file, fileName, bucket) => ({
  Body: file,
  Bucket: bucket,
  ContentType: file.type,
  Key: fileName,
});

/**
 * updateAwsConfig - updates aws configuration
 * @param awsCreds - credentials retrieved from server
 */
export const updateAwsConfig = (awsCreds) => {
  AWS.config.update({
    accessKeyId: awsCreds.credentials.AccessKeyId,
    secretAccessKey: awsCreds.credentials.SecretAccessKey,
    sessionToken: awsCreds.credentials.SessionToken,
    region: awsCreds.region,
  });
  return AWS;
};

/**
 * manageUpload - returns a managedUpload object
 * @param params - aws s3 upload parameters
 */
export const manageUpload = (params) =>
  new AWS.S3.ManagedUpload({
    partSize: 5 * 1024 * 1024,
    params: params,
  });

// upload file to s3
export const uploadImage = (uploadToS3) =>
  new Promise((resolve, reject) => {
    uploadToS3
      .on("httpUploadProgress", (progress) => {
        this.updateUploadStatus(
          Math.round((progress.loaded / progress.total) * 100),
          true,
          false
        );
      })
      .send((errS3, done) => {
        if (!errS3) {
          resolve(done.Location);
        } else {
          reject(errS3);
        }
      });
  });
