import React from "react";
import { Route, Redirect } from "react-router-dom";
import MainLayout from "../components/dashboard/layout";

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, ...rest }) => (
  // Show the component only when the user is logged in
  // Otherwise, redirect the user to /signin page
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("token") ? (
        <div>
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        </div>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

export default PrivateRoute;
