/* axios with abort */

import axios from "axios";
import { BASE_URL } from "./baseUrl";

export const getToken = () => {
  localStorage.getItem("token");
};

export const callRequestAPI = async (url, data, type = "post") => {
  let response = "";
  let apiUrl = `${BASE_URL}${url}`;
  const headers = {
    Authorization: localStorage.getItem("token"),
    "Content-Type": "application/json",
  };

  try {
    if (type === "post") {
      response = await axios.post(apiUrl, data);
    }
    if (type === "put") {
      response = await axios.put(apiUrl);
    }
    if (type === "get") {
      response = await axios.get(apiUrl, {
        params: data,
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      });
    }
    if (type === "putHeader") {
      response = await axios.put(apiUrl, data, { headers: headers });
    }
    if (type === "postHeader") {
      response = await axios.post(apiUrl, data, { headers: headers });
    }
    if (type === "delete") {
      response = await axios.delete(apiUrl, { headers: headers });
    }
  } catch (err) {
    redirecttoLogin(err);
    response =
      err.response && err.response.data
        ? err.response.data
        : { message: "something went wrong" };
  }
  return response;
};

const callAPI = (
  apiURL,
  apiMethod,
  apiData = null,
  resolveDataAsReponse = false,
  contentType = "application/json"
) => {
  let headers = {
    Authorization: localStorage.getItem("token"),
    "Content-Type": contentType,
  };
  let url = `${BASE_URL}${apiURL}`;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: apiMethod,
      data: apiData,
      headers,
    })
      .then((response) => {
        if (resolveDataAsReponse) {
          resolve(apiData);
        } else {
          resolve(response);
        }
      })
      .catch((err) => {
        redirecttoLogin(err);
        if (!axios.isCancel(err) && err.response) {
          reject(err.response);
        }
      });
  });
};

export default callAPI;

function redirecttoLogin(err) {
  if (
    err.response &&
    err.response.data &&
    err.response.data.message === "Login required"
  ) {
    if (window.confirm("Your login session has expired. Please login again")) {
      localStorage.removeItem("token");
      window.location = "/login";
    }
  }
}
