import { headerStyle } from "../objects/objectListColumns";

export const rateCols = [
    {
        dataField: "title",
        text: "Title",
        headerStyle: {
            textAlign: "center",
            verticalAlign: "top",
            width: "10%",
        },
    },
    {
        dataField: "ltv_0_4",
        text: "LTV 0 4",
        headerStyle: headerStyle,
    },
    {
        dataField: "ltv_5_9",
        text: "LTV 5 9",
        headerStyle: headerStyle,
    },
    {
        dataField: "ltv_10_14",
        text: "LTV 10 14",
        headerStyle: headerStyle,
    },
    {
        dataField: "ltv_15_19",
        text: "LTV 15 19",
        headerStyle: headerStyle,
    },
    {
        dataField: "ltv_20_24",
        text: "LTV 20 24",
        headerStyle: headerStyle,
    },
    {
        dataField: "ltv_25_29",
        text: "LTV 25 29",
        headerStyle: headerStyle,
    },
    {
        dataField: "ltv_30_34",
        text: "LTV 30 34",
        headerStyle: headerStyle,
    },
    {
        dataField: "ltv_35_39",
        text: "LTV 35 39",
        headerStyle: headerStyle,
    },
    {
        dataField: "ltv_40_or_plus",
        text: "LTV 40 or plus",
        headerStyle: headerStyle,
    },
];
