export const INITIAL_STATE = {
  response: [],
};

export const forgetPasswordReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FORGOT_PASSWORD_FULFILLED":
      return {
        status: "success",
        data: action.payload,
      };

    case "FORGOT_PASSWORD_PENDING":
      return {
        status: "pending",
      };

    case "FORGOT_PASSWORD_REJECTED":
      return {
        status: "fail",
        data: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export const changePasswordReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "RESET_PASSWORD_FULFILLED":
      return {
        status: "success",
        data: action.payload,
      };

    case "RESET_PASSWORD_PENDING":
      return {
        status: "pending",
      };

    case "RESET_PASSWORD_REJECTED":
      return {
        status: "fail",
        data: action.payload,
      };

    case "CLEAR_RESET_PASSWORD":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};
