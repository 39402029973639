import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  lang,
  inputTypes,
  questionCategories,
  stages,
  optionStructure,
  objStructure,
} from "../../utils/constants";
import { addQuestion } from "../../actions/onboardingActions";
import backArrow from "../../assets/images/whitearrow.png";
import ModalComponent from "../../utils/modalComponent";

import "./quest.css";

const initialState = {
  questionTitle: { ...objStructure },
  category: questionCategories[0],
  selectedStage: stages[0].key,
  options: optionStructure,
};

class AddQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      ...initialState,
    };
  }

  componentDidUpdate(prevProps) {
    const { addQuestionReducer } = this.props;

    if (
      prevProps.addQuestionReducer !== addQuestionReducer &&
      addQuestionReducer.status === "success"
    ) {
      this.setState({
        ...initialState,
      });
      this.setState({ showModal: true });
    }

    if (
      prevProps.addQuestionReducer !== addQuestionReducer &&
      addQuestionReducer.status === "fail"
    ) {
      alert("An error has occured while adding the question");
    }
  }

  render() {
    const {
      questionTitle,
      options,
      selectedStage,
      category,
      showModal,
    } = this.state;

    return (
      <div className="pcoded-content fade-in">
        <div className="pcoded-main-container" style={{ marginTop: "none" }}>
          <div className="pcoded-wrapper">
            <div className="page-header card" style={{ border: "none" }}>
              <div className="row align-items-end">
                <div className="col-lg-4">
                  <div className="page-header-title">
                    <Link to="/question">
                      <img
                        src={backArrow}
                        className="back-arrow"
                        alt="img"
                      ></img>
                    </Link>
                    <div>
                      <h5
                        style={{ display: "inline-block" }}
                        className="header-style"
                      >
                        Add New Question
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="page-header-breadcrumb">
                    <button
                      id="add_question_div1"
                      type="button"
                      className="btn add_option_div btn-add-cst"
                      onClick={() => this.submitQuestion()}
                      disabled={true}
                      title="This function is not allowed right now"
                    >
                      Save Question
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="page-body">
                    <div className="card">
                      <div className="card-block add-q">
                        <div className="form-group row">
                          <div className="col-sm-12 margin-10 title-style">
                            Question Title
                          </div>
                          <div className="col-sm-12">
                            <span
                              className="input-group-prepend"
                              style={{ width: "75%" }}
                            >
                              <label className="input-group-text1">
                                {lang.EN}
                              </label>
                            </span>

                            <textarea
                              id="userName-2c-1"
                              name="en"
                              placeholder="Enter the question in English language"
                              value={questionTitle.en}
                              className="required form-control question-text"
                              onChange={this.handleQuestionChange}
                              onKeyDown={this.onKeyDown}
                            ></textarea>

                            <span
                              className="input-group-prepend"
                              style={{ width: "75%", marginTop: "10px" }}
                            >
                              <label className="input-group-text1">de</label>
                            </span>

                            <textarea
                              id="userName-2c-2"
                              placeholder="Enter the question in German language"
                              name={lang.GR}
                              value={questionTitle.de}
                              className="required form-control question-text"
                              onChange={this.handleQuestionChange}
                              onKeyDown={this.onKeyDown}
                            ></textarea>
                          </div>

                          <div style={{ width: "100%" }}>
                            <hr style={{ width: "100%" }} />
                            <div className="col-sm-12 margin-10 title-style">
                              Options
                            </div>
                            <div className="col-sm-12">
                              {options &&
                                options.map((op, i) => (
                                  <div
                                    className="input-group option_subdiv"
                                    style={{ width: "75%" }}
                                    key={i}
                                  >
                                    <span className="input-group-prepend">
                                      <label className="input-group-text1">
                                        {lang.EN}
                                      </label>
                                    </span>
                                    <input
                                      type="text"
                                      name="en"
                                      className="form-control"
                                      placeholder={`Option ${i + 1
                                        } in English language`}
                                      value={op.option.en}
                                      onChange={this.handleOptionChange(
                                        i,
                                        lang.EN
                                      )}
                                      onKeyDown={this.onKeyDown}
                                    />
                                    <div className="col-12 margin-5"></div>
                                    <span className="input-group-prepend">
                                      <label className="input-group-text1">
                                        {/* {lang.de} */}
                                        de
                                      </label>
                                    </span>
                                    <input
                                      type="text"
                                      name="ge"
                                      className="form-control"
                                      placeholder={`Option ${i + 1
                                        } in German language`}
                                      value={op.option.de}
                                      onChange={this.handleOptionChange(
                                        i,
                                        lang.GR
                                      )}
                                      onKeyDown={this.onKeyDown}
                                    />
                                    <span>
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                        onClick={() => this.removeOption(i)}
                                      ></i>
                                    </span>

                                    <div className="row col-sm-12 margin-10">
                                      <span
                                        style={{
                                          marginRight: "10px",
                                          marginTop: "3px",
                                        }}
                                      >
                                        {`Answer input type for option ${i + 1
                                          }`}
                                      </span>
                                      <div className="col-sm-5">
                                        <select
                                          style={{
                                            width: "100%",
                                            padding: "5px",
                                            textTransform: "lowercase",
                                          }}
                                          name={"type"}
                                          onChange={this.handleOptionDropdown(
                                            i
                                          )}
                                        >
                                          {inputTypes.map((type, index) => (
                                            <option
                                              key={index}
                                              value={type}
                                              selected={op.type === type}
                                            >
                                              {type}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              <button
                                id="add_question_div"
                                type="button"
                                className="btn add_option_div btn-add-cst"
                                onClick={() => this.addOption()}
                              >
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                                Add option
                              </button>
                              <hr style={{ width: "100%" }} />

                              <div className="row col-sm-12 margin-10">
                                <span
                                  className="title-style"
                                  style={{
                                    marginRight: "10px",
                                    marginTop: "3px",
                                  }}
                                >
                                  Question Category
                                </span>
                                <div className="col-sm-5">
                                  <select
                                    style={{ width: "100%", padding: "5px" }}
                                    name="category"
                                    onChange={this.handleDropdown}
                                  >
                                    {questionCategories.map((type, index) => (
                                      <option
                                        key={index}
                                        value={type}
                                        selected={type === category}
                                      >
                                        {type}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div className="row col-sm-12 margin-10">
                                <span
                                  className="title-style"
                                  style={{
                                    marginRight: "10px",
                                    marginTop: "3px",
                                  }}
                                >
                                  Stage for Question
                                </span>
                                <div className="col-sm-5">
                                  <select
                                    style={{ width: "100%", padding: "5px" }}
                                    name="selectedStage"
                                    onChange={this.handleDropdown}
                                  >
                                    {stages.map((stage, index) => (
                                      <option
                                        value={stage.key}
                                        key={index}
                                        selected={selectedStage === stage.key}
                                      >
                                        {stage.value}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <ModalComponent
            title={"Success"}
            bodyMsg={"Question added sucessfully."}
            closeModal={() => this.closeModal()}
            btnText="OK"
          />
        )}
      </div>
    );
  }

  // add new option
  addOption = () => {
    const { options } = this.state;
    let obj = { option: { ...objStructure }, type: inputTypes[0], tag: "" };
    options.push(obj);
    this.setState({ options });
  };

  removeOption = (index) => {
    const { options } = this.state;
    options.splice(index, 1);
    this.setState({ options });
  };

  handleQuestionChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      questionTitle: {
        ...prevState.questionTitle,
        [key]: value,
      },
    }));
  };

  handleOptionChange = (index, lang) => (event) => {
    const { options } = this.state;
    options[index].option[lang] = event.target.value;
    this.setState({ options });
  };

  // handling for input type for options
  handleOptionDropdown = (index) => (event) => {
    const { options } = this.state;
    options[index].type = event.target.value;
    this.setState({ options });
  };

  // for category and stage of question
  handleDropdown = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  submitQuestion = () => {
    const { category, selectedStage, questionTitle, options } = this.state;

    const errorMsg = this.validateFields(questionTitle, options);

    if (errorMsg !== "") {
      alert(errorMsg);
    } else {
      const { addQuestion } = this.props;
      const questObject = {
        category,
        questionstageid: selectedStage,
        question: questionTitle,
        options,
        type: "Inputfield",
      };

      addQuestion(questObject);
    }
  };

  validateFields = (questionTitle, options) => {
    let errMsg = "";

    //Check for empty question
    if (questionTitle.de.trim() === "" || questionTitle.en.trim() === "") {
      errMsg = "Please fill the question in both languages";
      return errMsg;
    }

    //Check for empty options
    const emptyOptions = options.filter(
      (o) => o.option.en.trim() === "" || o.option.de.trim() === ""
    );
    if (emptyOptions.length > 0) {
      errMsg = "Please fill all options in both languages";
    }

    return errMsg;
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.submitQuestion();
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
    // eslint-disable-next-line react/prop-types
    this.props.history.push("/question");
  };
}

AddQuestion.propTypes = {
  addQuestion: PropTypes.func,
  addQuestionReducer: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  addQuestion: (questObj) => {
    dispatch(addQuestion(questObj));
  },
});

const mapStateToProps = (state) => ({
  addQuestionReducer: state.addQuestionReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);
