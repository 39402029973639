import React from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import PrivateRoute from "./privateRouter";
import PublicRoute from "./publicRoute";

import Dashboard from "../components/dashboard/dashboard";
import UserManagement from "../components/user/userManagement";
import UserDetail from "../components/user/userDetail";
import Questions from "../components/onboarding/questionManagement";
import AddQuestion from "../components/onboarding/addQuestion";
import QuestionDetails from "../components/onboarding/questionDetail";
import Objects from "../components/objects/objectsList";
import ObjectDetail from "../components/objects/objectDetails";
import Profile from "../components/dashboard/profile";
import RealtorManagement from "../components/realtor-management/realtorManagement";
import Login from "../components/auth/login";
import ForgotPassword from "../components/auth/forgetPassword";
import ResetPassword from "../components/auth/resetPassword";
import ModulesList from "../components/immozy-center/modulesList";
import GeneralMessagesList from "../components/immozy-center/modules/generalMessagesList";
import MessageEditor from "../components/immozy-center/modules/messageEditor";
import BankRates from "../components/bankRates/bankRates";
import DuplicateObjects from "../components/duplicates/duplicateObjects";
import RealtorDetails from "../components/realtor-management/realtorDetails";
import Settings from "../components/settings/settings";
import MailTemplateList from "../components/mail-template/newsLetterTemplateLists";
import MailTemplateEditor from "../components/mail-template/mailTemplateEditor";
import MailSender from "../components/mail-template/mailSender";
import RealtorMailLog from "../components/realtor-management/realtorMailLog";
import MailHistory from "../components/mail-template/mailHistory";
// import DocumentList from "../components/requests/documentList.js";
// import ModuleEditor from "../components/immozy-center/modules/moduleEditor";
// import ShowingList from "../components/requests/showingList";

const router = () => (
  <BrowserRouter>
    <Switch>
      <PublicRoute exact path="/login" component={Login} />
      <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
      <PublicRoute
        exact
        path="/reset-password/:token"
        component={ResetPassword}
      />
      <PrivateRoute exact path="/" component={Dashboard} />
      <PrivateRoute exact path="/user" component={UserManagement} />
      <PrivateRoute exact path="/user-detail/:id" component={UserDetail} />
      <PrivateRoute exact path="/objects" component={Objects} />
      <PrivateRoute
        exact
        path="/objects/duplicates"
        component={DuplicateObjects}
      />
      <PrivateRoute exact path="/question" component={Questions} />
      {/* <PrivateRoute exact path="/doclist" component={DocumentList} />
      <PrivateRoute exact path="/showlist" component={ShowingList} /> */}
      <PrivateRoute exact path="/immozy-center" component={ModulesList} />
      <PrivateRoute exact path="/realtor" component={RealtorManagement} />
      <PrivateRoute exact path="/realtor/:id" component={RealtorDetails} />
      <PrivateRoute
        exact
        path={["/realtor-mail/", "/realtor-mail/:id"]}
        component={RealtorMailLog}
      />
      <PrivateRoute exact path="/settings" component={Settings} />
      <PrivateRoute exact path="/mail-template" component={MailTemplateList} />
      <PrivateRoute exact path="/mail-history" component={MailHistory} />
      <PrivateRoute exact path="/mail-users/:id" component={MailSender} />
      <PrivateRoute
        exact
        path="/mail-template/:slug"
        component={MailTemplateEditor}
      />
      <PrivateRoute
        exact
        path="/msg-list/message/:id/:moduleId"
        component={MessageEditor}
      />
      <PrivateRoute
        exact
        path="/msg-list/:id"
        component={GeneralMessagesList}
      />
      {/* <PrivateRoute exact path="/module/:name/:id" component={ModuleEditor} /> */}
      <PrivateRoute
        exact
        path="/question-detail/:id"
        component={QuestionDetails}
      />
      <PrivateRoute exact path="/add-question" component={AddQuestion} />
      <PrivateRoute exact path="/profile" component={Profile} />
      <PrivateRoute exact path="/bank-rates" component={BankRates} />
      <PrivateRoute
        exact
        path={["/object-detail/:id", "/object-detail/:id/:from"]}
        component={ObjectDetail}
      />
    </Switch>
  </BrowserRouter>
);

export default router;
