import React from "react";
import appLogo from "../../assets/images/LOGO_beta.png";
import { Link } from "react-router-dom";

const Navigation = () => (
  <nav
    className="navbar header-navbar pcoded-header iscollapsed"
    style={{ padding: "0px" }}
    header-theme="themelight1"
    pcoded-header-position="fixed"
  >
    <div className="navbar-wrapper">
      <div className="navbar-logo" logo-theme="theme6">
        <img className="mt-2 img-fluid" src={appLogo} alt="Theme-Logo" />

        <Link
          className="mobile-menu"
          style={{ textDecoration: "none" }}
          id="mobile-collapse"
          to="/"
        >
          <i
            className="feather icon-menu icon-toggle-right"
            style={{ fontSize: "25px" }}
          ></i>
        </Link>
        <Link className="mobile-options waves-effect waves-light" to="/">
          <i className="feather icon-more-horizontal"></i>
        </Link>
      </div>
      <div className="navbar-container">
        <ul className="nav-left"></ul>
        <ul className="nav-right" style={{ margin: "15px" }}>
          <Link to="/profile">
            <i
              className="fa fa-user-circle-o"
              style={{ fontSize: "35px", color: "#932849" }}
            ></i>
          </Link>
        </ul>
      </div>
    </div>
  </nav>
);

export default Navigation;
