/* eslint-disable react/prop-types */
import React, { Component } from "react";
import * as awsService from "../../../services/aws";
import { Link } from "react-router-dom";
import { callRequestAPI } from "../../../services/callApi";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import backArrow from "../../../assets/images/whitearrow.png";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-dates/lib/css/_datepicker.css";
import "../../../assets/css/tableStyle.css";

let image = "";
let id = "";
let module_id = "";

class MessageEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: { en: null, de: null },
      editorStateHeader: { en: null, de: null },
      isEdit: false,
      isLoading: false,
      title: { en: '', de: '' },
      description: "",
      imageUrl: "",
      startDate: null,
      expireDate: null,
      active: false,
      profileLoading: false,
      urgent: false,
      type: "half",
      isError: "",
      errorMsg: "",
      orderId: "",
      currentLang: 'en'
    };
  }

  componentDidMount() {
    const { match } = this.props;
    module_id = match.params.moduleId;
    this.setEditOrNew();
  }

  componentWillUnmount() {
    id = "";
  }

  render() {
    const {
      isEdit,
      isLoading,
      editorState,
      editorStateHeader,
      profileLoading,
      imageUrl,
      expireDate,
      startDate,
      focusedInput,
      isError,
      errorMsg,
      orderId,
      currentLang
    } = this.state;

    return (
      <div className='pcoded-content fade-in'>
        <div className='pcoded-main-container' style={{ marginTop: "none" }}>
          <div className='pcoded-wrapper'>
            <div className='page-header card' style={{ border: "none" }}>
              <div className='row align-items-end'>
                <div className='col-lg-5'>
                  <div className='page-header-title'>
                    <Link to={`/msg-list/${module_id}`}>
                      <img
                        src={backArrow}
                        className='back-arrow'
                        alt='img'
                      ></img>
                    </Link>
                    <div>
                      <h5
                        className='header-style'
                        style={{
                          display: "inline-block",
                        }}
                      >
                        {isEdit ? "Edit Message" : "Add New Message"}
                      </h5>
                    </div>

                    {isLoading || profileLoading ? (
                      <i
                        className='fa fa-cog fa-spin fa-3x fa-fw spinner-gear'
                        title='Loading data...'
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='pcoded-inner-content'>
            <div className='main-body'>
              <div className='page-wrapper'>
                <div className='page-body'>
                  <div className='card'>
                    <div className='card-block'>
                      <div>
                        {errorMsg !== "" && (
                          <p className='img-err'>{errorMsg}</p>
                        )}


                        {/* Message Editor */}
                        <div >

                          <div className='d-flex mb-2' style={{ alignItems: 'baseline' }}>
                            <p className='mr-3'>Selected Language : {currentLang === 'en' ? 'English' : "German"}</p>
                            <button className='btn btn-primary btn-sm mt-2' onClick={() => this.setState({ currentLang: currentLang === 'en' ? 'de' : 'en' })}>switch</button>
                          </div>
                          {/* Title section */}
                          <h6 className='mt-2 mb-2'>Message Title</h6>
                          <div className='d-flex mb-4 mt-2 msg-title'>
                            <Editor
                              editorState={editorStateHeader[currentLang]}
                              toolbarClassName='toolbar-class'
                              wrapperClassName='wrapper-class'
                              editorClassName='editor-class'
                              onEditorStateChange={this.onEditorStateChange('editorStateHeader')}
                              placeholder={"Enter your message here..."}
                              toolbar={{
                                options: [
                                  "inline",
                                  "blockType",
                                  "fontSize",
                                  "list",
                                  "textAlign",
                                  "colorPicker",
                                  "link",
                                  "emoji",
                                ],
                              }}
                            />
                          </div>

                          {/* Body section */}
                          <h6 className='mt-2 mb-2'>Message body</h6>
                          <div className='d-flex mb-4 mt-2'>
                            <Editor
                              editorState={editorState[currentLang]}
                              toolbarClassName='toolbar-class'
                              wrapperClassName='wrapper-class'
                              editorClassName='editor-class'
                              onEditorStateChange={this.onEditorStateChange('editorState')}
                              placeholder={"Enter your message here..."}
                              toolbar={{
                                options: [
                                  "inline",
                                  "blockType",
                                  "fontSize",
                                  "list",
                                  "textAlign",
                                  "colorPicker",
                                  "link",
                                  "emoji",
                                ],
                              }}
                            />
                          </div>
                          <div className='d-flex mt-2 mb-4 ml--15 '>
                            <h6 className='col-sm-2'>Order Id:</h6>
                            <input
                              value={orderId}
                              name='orderId'
                              type={"number"}
                              step={"any"}
                              onChange={this.handleChange}
                              className='width-div w-300'
                            />
                          </div>
                          <div className='ml--15 mt-2 mb-4'>
                            <h6 className='d-flex'>
                              <p className='col-sm-2'>Is message Active:</p>
                              <input
                                name='active'
                                type={"checkbox"}
                                onChange={this.handleChange}
                                checked={this.state.active}
                                style={{
                                  marginTop: "5px",
                                }}
                              ></input>
                            </h6>
                          </div>
                          <div className='ml--15 mt-2 mb-4'>
                            <h6 className='d-flex'>
                              <p className='col-sm-2'> Is message Urgent:</p>
                              <input
                                name='urgent'
                                type={"checkbox"}
                                onChange={this.handleChange}
                                checked={this.state.urgent}
                                style={{
                                  marginTop: "5px",
                                }}
                              ></input>
                            </h6>
                          </div>
                          <div className='d-flex mt-2 mb-4 ml--15 '>
                            <h6 className='col-sm-2'>Module type:</h6>
                            <select
                              className='width-div w-300 select-msg'
                              onChange={this.handleChange}
                              value={this.state.type}
                              name='type'
                            >
                              <option name='type' value='half'>
                                Half
                              </option>
                              <option name='type' value='full'>
                                Full
                              </option>
                            </select>
                          </div>

                          {/* Image scetion */}
                          <div className='ml--15 '>
                            <div className='d-flex mt-2 mb-4 '>
                              <h6 className='col-sm-2'>Upload Image:</h6>
                              <input
                                id='profile'
                                name='imageUrl'
                                className='image-ip w-300'
                                type='file'
                                autoComplete='no'
                                accept='image/*'
                                onChange={this.handleImage}
                                disabled={isLoading || profileLoading}
                              />

                              {imageUrl !== "" && (
                                <i
                                  className='feather icon-trash ml-2 trash'
                                  onClick={() =>
                                    !isLoading &&
                                    !profileLoading &&
                                    this.removeImage()
                                  }
                                  title='Remove this image'
                                  style={{
                                    opacity:
                                      isLoading || profileLoading ? "0.5" : "1",
                                  }}
                                ></i>
                              )}
                              {profileLoading && (
                                <i
                                  className='fa fa-cog fa-spin fa-3x fa-fw spinner-gear'
                                  title='uploading image'
                                />
                              )}
                              {isError !== "" && (
                                <p className='ml-2 img-err'>{isError}</p>
                              )}
                            </div>
                            {imageUrl !== "" && (
                              <div className='d-flex mt-2 mb-4'>
                                <h6 className='col-sm-2'>Image Preview</h6>
                                <div className='img-prev'>
                                  <img src={imageUrl} alt='img' />
                                </div>
                              </div>
                            )}
                          </div>
                          {/* Date Section */}
                          <div className='d-flex mt-2 mb-4 ml--15 '>
                            <h6 className='col-sm-2'>Select Date:</h6>

                            <DateRangePicker
                              startDate={startDate}
                              endDate={expireDate}
                              endDatePlaceholderText={"Expiry Date"}
                              onDatesChange={({ startDate, endDate }) =>
                                this.setDate(startDate, endDate)
                              }
                              focusedInput={focusedInput}
                              onFocusChange={(focusedInput) =>
                                this.setFocusedInput(focusedInput)
                              }
                              isOutsideRange={() => false}
                              displayFormat='YYYY-MM-DD'
                              openDirection='up'
                            />
                          </div>
                        </div>
                        <button
                          className='btn btn-primary btn-sm mt-2'
                          onClick={() => this.addNewMessage()}
                          disabled={profileLoading}
                        >
                          Save Message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  removeImage = () => {
    this.setState({ imageUrl: "" });
    image = "";
  };

  setEditOrNew = () => {
    const pathName = window.location.pathname;

    if (!pathName.includes("new")) {
      this.setState({
        isEdit: true,
        isLoading: true,
      });
      const { match } = this.props;
      id = match.params.id;

      this.getMessageDetails(id);
    } else {
      this.setState({
        editorState: EditorState.createEmpty(),
      });
    }
  };

  getMessageDetails = async (id) => {
    const response = await callRequestAPI(
      `v1/generalmessages/${id}`,
      null,
      "get"
    );

    if (response.status === 200 && response.data) {
      const { msgObj, convertedData } = this.setMessage(response);

      this.setState({
        ...msgObj,
        isLoading: false,
        editorState: convertedData.editorState,
        editorStateHeader: convertedData.editorStateHeader
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  setMessage = (response) => {
    const data = response.data.data;

    const convertedData = {
      editorStateHeader: null,
      editorState: null
    };

    [{ label: 'editorStateHeader', value: data.title }, { label: 'editorState', value: data.description }].forEach((field) => {

      if (field.value) {
        const value = field.value;
        const contentBlock = { en: htmlToDraft(value['en']), de: htmlToDraft(value['de']) };

        const contentStateEn = ContentState.createFromBlockArray(
          contentBlock.en.contentBlocks
        );
        const contentStateDe = ContentState.createFromBlockArray(
          contentBlock.de.contentBlocks
        );
        const editorStateEn = EditorState.createWithContent(contentStateEn);
        const editorStateDe = EditorState.createWithContent(contentStateDe);

        convertedData[field.label] = {
          en: editorStateEn, de: editorStateDe
        }
      }
    });

    const msgObj = {
      imageUrl: data.imageUrl,
      urgent: data.urgent,
      active: data.active,
      type: data.type,
      startDate: data.startDate ? moment(new Date(data.startDate)) : null,
      expireDate: data.expireDate ? moment(new Date(data.expireDate)) : null,
      orderId: data.orderId,
    };
    return { msgObj, convertedData };
  };

  handleChange = (event) => {
    if (event.target) {
      const fieldType = event.target.type;

      this.state.errorMsg !== "" && this.setState({ errorMsg: "" });
      if (fieldType === "text" || fieldType === "number") {
        this.setState({ [event.target.name]: event.target.value });
      }
      if (fieldType === "checkbox") {
        this.setState({ [event.target.name]: event.target.checked });
      }

      if (fieldType === "select-one") {
        this.setState({ type: event.target.value });
      }
    }
  };

  handleChangeFortext = (stateName) => (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (stateName) {
      this.setState((prevState) => ({
        [stateName]: {
          ...prevState[stateName],
          [name]: value,
        },
      }));
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  setDate = (startDate, expireDate) => {
    this.setState({ startDate, expireDate });
  };

  setFocusedInput = (focusedInput) => {
    this.setState({ focusedInput });
  };

  onEditorStateChange = (name) => (editor) => {
    // const currentContent = this.state.editorState.getCurrentContent();
    // const currentContentLength = currentContent.getPlainText("").length;
    const { currentLang } = this.state;


    this.setState((prevState) => ({
      [name]: {
        ...prevState[name],
        [currentLang]: editor,
      },
    }));
  };

  validateAndCraeteApiObj = () => {
    const {
      //title,
      editorState,
      editorStateHeader,
      imageUrl,
      startDate,
      expireDate,
      active,
      urgent,
      type,
      orderId,
    } = this.state;

    const title = {
      en: editorStateHeader['en'] && draftToHtml(
        convertToRaw(editorStateHeader['en'].getCurrentContent())
      ),
      de: editorStateHeader['de'] && draftToHtml(
        convertToRaw(editorStateHeader['de'].getCurrentContent())
      )
    };

    const description = {
      en: editorState['en'] && draftToHtml(
        convertToRaw(editorState['en'].getCurrentContent())
      ),
      de: editorState['de'] && draftToHtml(
        convertToRaw(editorState['de'].getCurrentContent())
      )
    };

    let emptyFields = [];

    const validationFields = [
      { name: "message title", value: title },
      { name: "message body", value: description },
      { name: "start date", value: startDate },
      { name: "expiry date", value: expireDate },
      { name: "order id", value: orderId },
    ];

    validationFields.forEach((element) => {
      if (!element.value || element.value === "") {
        emptyFields.push(element.name);
      }
      if (element.name === "message body" && (element.value.en === `<p></p>\n` || element.value.de === `<p></p>\n`)) {
        const currentContent = this.state.editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText("").length;
        currentContentLength === 0 && emptyFields.push(element.name);
      }
    });

    if (emptyFields.length > 0) {
      this.setState({ errorMsg: `* Please provide ${emptyFields}` });
      window.scrollTo(0, 0);
      return null;
    } else {
      const msgObj = {
        moduleid: module_id,
        title,
        description,
        imageUrl,
        startDate: startDate.toISOString(),
        expireDate: expireDate.toISOString(),
        active,
        urgent,
        type,
        orderId: parseFloat(orderId),
      };
      return msgObj;
    }
  };

  addNewMessage = async () => {
    const { isEdit } = this.state;

    const msgObj = this.validateAndCraeteApiObj();
    if (msgObj) {
      const url = isEdit ? `v1/generalmessages/${id}` : `v1/generalmessages`;
      const apiType = isEdit ? "putHeader" : "postHeader";

      const response = await callRequestAPI(url, msgObj, apiType);

      if (
        (!isEdit && response.status === 201 && response.data) ||
        (isEdit && response.status === 200 && response.data)
      ) {
        this.setState({ isLoading: false });
        if (!isEdit) {
          window.location.pathname = `/msg-list/message/${response.data.data.id}/${module_id}`;
        }
        alert(`Message ${isEdit ? "updated" : "added"} successfully.`);
      } else {
        this.setState({ isLoading: false, errorMsg: response.message });
        alert(response.message);
      }
    }
  };

  handleImage = (e) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    image = e.target.files[0];

    if (image.size / 1024 / 1024 > 1) {
      this.setState({
        isError: "Please select an attachment which is less than 1 MB",
      });
    } else if (!allowedTypes.includes(image.type)) {
      this.setState({
        isError: "Only JPEG, JPG and PNG formats are supported",
      });
    } else {
      this.setState({
        imageUrl: URL.createObjectURL(e.target.files[0]),
        profileLoading: true,
      });

      this.state.isError !== "" && this.setState({ isError: "" });

      this.uploadFile(e.target.files[0]);
    }
  };

  // call request to upload file to s3
  uploadFile = async (file) => {
    const response = await callRequestAPI(
      `v1/profile/s3/centerImages`,
      null,
      "get"
    );

    if (response.status === 200) {
      const awsCreds = response.data;
      awsService.updateAwsConfig(awsCreds.data);

      const promises = [];
      if (!this.state.isUploaded) {
        const timestamp = new Date().getTime();
        const fileName = `immozy-center-images/message_image_${timestamp}.png`;

        const params = awsService.getS3UploadParams(
          file,
          fileName,
          "immozy-center-images"
        );

        const uploadToS3 = awsService.manageUpload(params);
        promises.push(awsService.uploadImage(uploadToS3));
      }
      Promise.all(promises).then((value) => {
        this.setState({
          profileLoading: false,
          imageUrl: value[0],
        });
      });
    } else {
      this.setState({
        profileLoading: false,
      });
    }
  };

  // get image uploaded status
  updateUploadStatus = (progress, isUploading, isUploaded) => {
    this.setState({
      isUploading: isUploading,
      isUploaded: isUploaded,
      progress: progress,
    });
  };
}

export default MessageEditor;
