import React, { Component } from "react";
import { Link } from "react-router-dom";
import appLogo from "../../assets/images/LOGO_beta.png";
import { callRequestAPI } from "../../services/callApi";

import "./auth.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isError: false,
      errorMessage: "",
      showSpinner: false,
      showPwd: false,
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ isError: false, errorMessage: "" });
  };

  submitLogin = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    if (username.trim() === "" && password.trim() === "") {
      this.setState({
        isError: true,
        errorMessage: "Please fill the username and password",
      });
    } else if (username.trim() === "") {
      this.setState({
        isError: true,
        errorMessage: "Please fill the username",
      });
    } else if (password.trim() === "") {
      this.setState({
        isError: true,
        errorMessage: "Please fill the password",
      });
    } else {
      this.loginUser(username, password);
      this.setState({ showSpinner: true });
    }
  };

  loginUser = async (email, password) => {
    const loginResponse = await callRequestAPI(`v1/auth/login`, {
      email,
      password,
    });

    // login successfull
    if (loginResponse.status === 200) {
      this.setState({ showSpinner: false });
      this.redirectToDashboard(loginResponse.data.data);
    } else {
      //login failed, show error message
      const errorMessage = loginResponse.message;
      this.setState({ isError: true, errorMessage, showSpinner: false });
    }
  };

  redirectToDashboard = (authData) => {
    const token = authData.token;
    localStorage.setItem("token", token);

    window.location.pathname = "/";
  };

  render() {
    const { isError, errorMessage, showSpinner, showPwd } = this.state;
    return (
      <div>
        <div>
          <section className="login-block">
            <div className="container-fluid center-div">
              <div className="row">
                <div className="col-sm-12">
                  <form className="md-float-material form-material">
                    <div className="auth-box card">
                      <div className="card-block">
                        <div className="row m-b-20">
                          <div className="col-md-12">
                            <span className="row mb-1 d-block">
                              <img src={appLogo} alt="logo.png" />
                              <p
                                style={{ fontSize: "19px", display: "inline" }}
                              >
                                Welcome to Immozy Admin
                                {showSpinner && (
                                  <i className="ml-2 fa fa-cog fa-spin fa-3x fa-fw spinner-gear" />
                                )}
                              </p>
                            </span>
                          </div>
                        </div>

                        <div className="form-group form-primary margin-t-30">
                          <input
                            type="text"
                            name="username"
                            className="form-control input-login-style"
                            required=""
                            placeholder="User name"
                            onChange={this.handleChange}
                          />
                          <span className="form-bar"></span>
                        </div>
                        <div className="form-group form-primary margin-t-30 position-relative">
                          <input
                            type={showPwd ? "text" : "password"}
                            name="password"
                            className="form-control input-login-style"
                            required=""
                            placeholder="Password"
                            onChange={this.handleChange}
                          />
                          <span
                            onClick={() => this.setState({ showPwd: !showPwd })}
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                            }}
                          >
                            {showPwd ? (
                              <i className="pwd-icon fa fa-eye-slash"></i>
                            ) : (
                              <i className="pwd-icon fa fa-eye"></i>
                            )}
                          </span>

                          {isError && (
                            <div className="col-sm-12">
                              <label className="error-msg-label">
                                {errorMessage}
                              </label>
                            </div>
                          )}
                          <span className="form-bar"></span>
                        </div>
                        <div className="row m-t-25 text-left">
                          <div className="col-12">
                            <div className="forgot-phone text-right float-right">
                              <Link
                                to="/forgot-password"
                                className="text-right f-w-600"
                              >
                                Forgot password?
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="row m-t-30">
                          <div className="col-md-12">
                            <button
                              type="submit"
                              className="btn btn-primary btn-md btn-block waves-effect text-center m-b-20"
                              onClick={this.submitLogin}
                            >
                              Sign In
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Login;
