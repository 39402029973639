import React, { useEffect, useState } from "react";
import { callRequestAPI } from "../../services/callApi";
import PropTypes from "prop-types";
import ModalComponent from "../../utils/modalComponent";
import Skeleton from "react-loading-skeleton";

const recordPerPage = 700;
const cities = {
  HAMBURG: "Hamburg",
  BERLIN: "Berlin",
  HANNOVER: "Hannover",
  LEIPZIG: "Leipzig",
};
const headers = ["Area", "Postal Code", " City Center Score", "Action"];
const addPincodeFields = [
  { name: "area", label: "Area" },
  { name: "city", label: "City" },
  { name: "citycenterscore", label: "Citycenterscore" },
  { name: "postalcode", label: "Postal Code" },
];
const options = Object.values(cities);

export default function PincodeSettings(props) {
  const [pincodeList, setPincodeList] = useState([]);
  const [city, setcity] = useState(cities.HAMBURG);
  const [editId, seteditId] = useState("");
  const [valuechanged, setvaluechanged] = useState(false);
  const [showAdPincode, setshowAdPincode] = useState(false);
  const [newPincodeObj, setnewPincodeObj] = useState({
    city: city,
    citycenterscore: 0,
    postalcode: "",
    area: "",
  });
  const [errorMsg, showErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deletingEntry, setDeletingEntry] = useState(false);

  const { isLoading, setisLoading, showToasterSuccess, showToasterError } =
    props;

  useEffect(
    () => {
      !isLoading && getPincodeList(city);
    },
    //eslint-disable-next-line
    [city]
  );

  const getPincodeList = async () => {
    setisLoading(true);
    const response = await callRequestAPI(
      `v1/postalscore?currentPage=1&recordPerPage=${recordPerPage}&city=${city}`,
      null,
      "get"
    );

    if (response.status === 200 && response.data) {
      setPincodeList(response.data.data.data);
      setisLoading(false);
      seteditId(false);
    } else {
      setisLoading(false);
      seteditId(false);
    }
  };

  const handleChange = (event) => {
    setcity(event.target.value);
    setnewPincodeObj({
      ...newPincodeObj,
      city: event.target.value,
    });
  };

  const handleInputChange = (event) => {
    const id = parseInt(event.target.id);
    let list = [...pincodeList];
    list[id][event.target.name] = event.target.value;
    setPincodeList(list);
    !valuechanged && setvaluechanged(true);
  };

  const hanldeAddPincodeChange = (event) => {
    setnewPincodeObj({
      ...newPincodeObj,
      [event.target.name]: event.target.value,
    });
    errorMsg !== "" && showErrorMessage("");
  };

  const saveChange = async (data) => {
    setisLoading(true);

    if (data.citycenterscore && data.postalcode) {
      const payload = {
        area: data.area,
        city: data.city,
        citycenterscore: data.citycenterscore
          ? parseInt(data.citycenterscore)
          : 0,
        postalcode: data.postalcode ? parseInt(data.postalcode) : 0,
      };

      const response = await callRequestAPI(
        `v1/postalscore/${data.Id}`,
        payload,
        "putHeader"
      );

      if (response.status === 200 && response.data) {
        setvaluechanged(false);
        seteditId("");
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } else {
      showToasterError("Please add proper values to save the changes.");
      setisLoading(false);
    }
  };

  const cancelChange = () => {
    seteditId("");
    valuechanged && getPincodeList();
  };

  const deletePincodeList = async (id) => {
    setDeletingEntry(true);
    const response = await callRequestAPI(`v1/postalscore/${id}`, {}, "delete");

    if (response.status === 200 && response.data) {
      setDeletingEntry(false);
      seteditId("");
      setShowModal(false);
      setDeleteId("");
      showToasterSuccess("Pincode deleted successfully");
      getPincodeList();
    } else {
      setDeletingEntry(false);
      setDeleteId("");
      setShowModal(false);
      showToasterError("Something went wrong. Please try again. ");
      setisLoading(false);
    }
  };

  const insertNewPincode = async () => {
    const empty = Object.values(newPincodeObj).filter((i) => i === "");
    if (empty.length === 0) {
      const payload = {
        area: newPincodeObj.area,
        postalcode: parseInt(newPincodeObj.postalcode),
        citycenterscore: parseInt(newPincodeObj.citycenterscore),
        city: newPincodeObj.city,
      };
      const response = await callRequestAPI(
        `v1/postalscore/addpostalcityscore`,
        payload,
        "postHeader"
      );
      if (response.status === 201 && response.data) {
        setshowAdPincode(false);
        getPincodeList();
        showToasterSuccess("New Postal Code added successfully.");
      } else {
        setisLoading(false);
      }
    } else {
      showErrorMessage("Please fill all the fields.");
    }
  };
  const modalBodyMessage = () => (
    <p style={{ height: "55px" }} className="d-flex justify-content-center align-items-center">
      {deletingEntry
        ? <i className="ml-2 fa fa-cog fa-spin fa-3x fa-fw spinner-gear" />
        : "Are you sure you, want to delete this postal code?"}
    </p>
  );
  return (
    <div>

      {showModal && (
        <ModalComponent
          title={"Confirmation"}
          bodyMsgIsElement
          bodyMsg={modalBodyMessage()}
          closeModal={() => {
            setShowModal(false)
            setDeleteId("");
          }}
          handleYes={() => deletePincodeList(deleteId)}
        />
      )}
      {" "}
      {isLoading
        ? (
          <Skeleton
            height={"300px"}
            baseColor='#ddd4d7'
          />
        ) : (
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center pincode-add-div">
                <span className="font-weight-bold mt-2 mb-2">
                  Showing Data for City :{" "}
                </span>
                <select
                  className="form-control font-weight-bold col-md-6 ml-3"
                  value={city}
                  onChange={handleChange}
                >
                  {options.map((op, i) => (
                    <option value={op} key={i}>
                      {op}
                    </option>
                  ))}
                </select>{" "}
              </div>
              <div className="w-100 d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  onClick={() => setshowAdPincode(!showAdPincode)}
                >
                  Add New Pincode
                </button>
              </div>
            </div>
            {showAdPincode && (
              <div
                className="pincode-add-div mt-4 mb-4"
                style={{ border: "1px solid #d8d8d8", padding: "6px 20px 15px" }}
              >
                <div className="text-right">
                  <span
                    onClick={() => setshowAdPincode(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa fa-times"></i>
                  </span>
                </div>
                <h5>Add New Pincode</h5>
                <div
                  className="d-flex align-items-center"
                  style={{ flexWrap: "wrap" }}
                >
                  {addPincodeFields.map((field, index) => (
                    <div
                      className="d-flex align-items-center mr-3"
                      style={{ flex: "40%" }}
                      key={index}
                    >
                      <label style={{ width: "120px" }} className="mr-2">
                        {field.label}
                      </label>
                      <input
                        className="form-control mt-2 mb-2"
                        style={{ maxWidth: "200px" }}
                        onChange={hanldeAddPincodeChange}
                        type="text"
                        name={field.name}
                        value={newPincodeObj[field.name]}
                        disabled={field.name === "city"}
                      />
                    </div>
                  ))}
                </div>
                {errorMsg !== "" && (
                  <p style={{ fontSize: "13px", color: "red" }}>{errorMsg}</p>
                )}
                <button
                  className="btn btn-primary btn-sm mt-3"
                  onClick={() => insertNewPincode()}
                >
                  Add Pincode
                </button>
              </div>
            )}
            <div
              className="row mb-4 mt-4"
              style={{ borderBottom: "1px solid #dddada" }}
            >
              {headers.map((data) => (
                <div className="col-md-2 font-weight-bold" key={data}>
                  {data}
                </div>
              ))}
            </div>
            {pincodeList.length > 0 &&
              pincodeList.map((data, index) => (
                <div
                  className="row mb-2 pb-2"
                  key={index}
                  style={{ borderBottom: "1px solid #dddada" }}
                >
                  <div className="col-md-2">{data.area}</div>
                  <div className={"col-md-2"}>
                    {editId === data.Id ? (
                      <input
                        type="number"
                        className="full-width form-control"
                        placeholder={"Not Added"}
                        value={data.postalcode}
                        onChange={handleInputChange}
                        id={index}
                        name="postalcode"
                      />
                    ) : (
                      <p>{data.postalcode}</p>
                    )}
                  </div>
                  <div className={"col-md-2"}>
                    {editId === data.Id ? (
                      <input
                        type="number"
                        className="full-width form-control"
                        placeholder={"Not Added"}
                        value={data.citycenterscore}
                        onChange={handleInputChange}
                        id={index}
                        name="citycenterscore"
                      />
                    ) : (
                      <p>{data.citycenterscore}</p>
                    )}
                  </div>
                  <div className={" d-flex align-items-center"}>
                    <button
                      className="btn btn-primary action-button mr-2"
                      title={`Delete Pincode`}
                      onClick={() => {
                        setDeleteId(data.Id)
                        setShowModal(true)
                      }}
                    >
                      <i className="fa fa-trash m-0" aria-hidden="true"></i>
                    </button>
                    <button
                      className="btn btn-primary action-button mr-2"
                      onClick={() => seteditId(data.Id)}
                      title={`Edit Pincode`}
                    >
                      <i className="fa fa-pencil m-0" aria-hidden="true"></i>
                    </button>
                    {editId === data.Id && (
                      <>
                        <button
                          className="btn action-button btn-primary mr-2"
                          title={`Save Change`}
                          onClick={() => saveChange(data)}
                          disabled={!valuechanged}
                        >
                          <i className="fa fa-save m-0" aria-hidden="true"></i>
                        </button>
                        <button
                          className="btn action-button btn-primary mr-2"
                          title={`Cancel Change`}
                          onClick={() => cancelChange()}
                        >
                          <i className="fa fa-times m-0" aria-hidden="true"></i>
                        </button>
                        {isLoading && (
                          <i className="ml-2 fa fa-cog fa-spin fa-3x fa-fw spinner-gear" />
                        )}
                      </>
                    )}
                    {/* <button className="btn btn-primary mr-2">
                  <i
                    className="fa fa-info-circle  m-0"
                    aria-hidden="true"
                    title={`Last updated: ${getDateWithTime(data.updateddate)}`}
                  ></i>
                </button> */}
                  </div>
                </div>
              ))}
          </div>
        )}
    </div>
  );
}

PincodeSettings.propTypes = {
  isLoading: PropTypes.bool,
  setisLoading: PropTypes.func,
  showToasterSuccess: PropTypes.func,
  showToasterError: PropTypes.func,
};
