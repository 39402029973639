export const INITIAL_STATE = {
  response: [],
};

export const questionListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_QUESTIONS_LIST_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "FETCH_QUESTIONS_LIST_PENDING":
      return {
        status: "pending",
      };

    default:
      return {
        ...state,
      };
  }
};

export const deleteQuestionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "DELETE_QUESTION_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "DELETE_QUESTION_REJECTED":
      return {
        status: "fail",
        data: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export const addQuestionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ADD_QUESTION_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "ADD_QUESTION_REJECTED":
      return {
        status: "fail",
        data: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export const questionDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_QUESTION_DETAILS_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "FETCH_QUESTION_DETAILS_REJECTED":
      return {
        status: "fail",
        data: action.payload,
      };

    case "CLEAR_FETCH_QUESTION_DETAILS": {
      return {
        ...INITIAL_STATE,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export const updateQuestionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "EDIT_QUESTION_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "EDIT_QUESTION_REJECTED":
      return {
        status: "fail",
        data: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
