import callAPI from "../services/callApi";

export const getUsersList = (recordPerPage = 3000) => ({
  type: "FETCH_USER_LIST",
  payload: callAPI(`v1/users?recordPerPage=${recordPerPage}`, "GET"),
});

export const getUsersDetails = (id) => ({
  type: "FETCH_USER_DETAIL",
  payload: callAPI(`v1/users/details/${id}`, "GET"),
});

export const getFavoList = (id) => ({
  type: "FETCH_FAV_LIST",
  payload: callAPI(`v1/users/favourites/${id}`, "GET"),
});

export const getUserAnswers = (id) => ({
  type: "GET_USER_ANS",
  payload: callAPI(`v1/users/answers/${id}`, "GET"),
});

export const changeUserStatus = (id) => ({
  type: "USER_STATUS",
  payload: callAPI(`v1/users/status/${id}`, "PUT"),
});

export const changeUserType = (id, type) => ({
  type: "USER_STATUS",
  payload: callAPI(`v1/profile/tmpusertype/${id}/${type}`, "PUT"),
});
