import callAPI from "../services/callApi";

export const resetPassword = (token, password) => ({
  type: "RESET_PASSWORD",
  payload: callAPI(`v1/password/reset`, "POST", {
    token,
    password,
  }),
});

export const forgetPassword = (email) => ({
  type: "FORGOT_PASSWORD",
  payload: callAPI(`v1/password/forgot`, "POST", {
    email,
  }),
});

export const changePassword = (password, newPassword) => ({
  type: "RESET_PASSWORD",
  payload: callAPI(`v1/profile/password/change`, "PUT", {
    password,
    newPassword,
  }),
});
