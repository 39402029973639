import React, { useEffect, useState } from "react";
import DynamicScoreManagement from "./dynamicScoreManagement";
import HouseTypeSettings from "./houseTypeSettings";
import ImmozyqualifySettings from "./immozyqualifySettings";
import OutliersSettings from "./outliersSettings";
import PincodeSettings from "./pincodeSettings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./settings.css";
import "react-loading-skeleton/dist/skeleton.css";

const tabNames = {
  OBJ_SETTINGS: "Outliers",
  SCORE_MANAGEMENT: "Score Weightage",
  HOUSING_MANAGEMENT: "Housing Type",
  PINCODE_SETTINGS: "Postal Code",
  IMMOZY_QUALIFY: "Immozy Qualify",
};
const tabs = Object.values(tabNames);

const Settings = () => {
  const [activetab, setactiveTab] = useState(tabs[0]);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    const lastTab = localStorage.getItem("lastTabOfSettingPage");
    if (!!lastTab && lastTab !== "" && tabs.includes(lastTab)) {
      setisLoading(false);
      setactiveTab(lastTab);
    } else {
      setactiveTab(tabs[0]);
      localStorage.setItem("lastTabOfSettingPage", tabs[0]);
    }
    return () => {
      localStorage.setItem("lastTabOfSettingPage", "");
    };
  }, []);

  const showToasterSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showToasterError = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className="pcoded-content fade-in">
      <div className="pcoded-inner-content">
        <div className="page-header card" style={{ border: "none" }}>
          <div className="row align-items-end">
            <div className="col-lg-4">
              <div className="page-header-title">
                <i className="feather icon-settings bg-c-blue"></i>
                <div className="d-flex">
                  <h5 className="header-style">Admin Settings</h5>
                  {/* {isLoading && (
                    <i className="ml-2 fa fa-cog fa-spin fa-3x fa-fw spinner-gear" />
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-body">
                <div className="card">
                  <div className="card-block" style={{ padding: "2rem" }}>
                    <div className="nav-tabs-wrapper d-flex flex-column flex-md-row justify-content-between align-items-md-center align-items-start mb-4">
                      <ul
                        className="nav nav-tabs order-1 order-md-0"
                        id="myTab"
                        role="tablist"
                      >
                        {tabs.map((tab, index) => (
                          <li className="nav-item" key={index}>
                            <span
                              className={`cursor-pointer ${activetab === tab
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              id="home-tab"
                              role="tab"
                              name={tab}
                              onClick={() => {
                                setactiveTab(tab);
                                localStorage.setItem(
                                  "lastTabOfSettingPage",
                                  tab
                                );
                              }}
                            >
                              {tab}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="mt-2 tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        {activetab === tabNames.OBJ_SETTINGS && (
                          <OutliersSettings
                            isLoading={isLoading}
                            setisLoading={setisLoading}
                            showToasterSuccess={showToasterSuccess}
                            showToasterError={showToasterError}
                          />
                        )}
                        {activetab === tabNames.SCORE_MANAGEMENT && (
                          <DynamicScoreManagement
                            isLoading={isLoading}
                            setisLoading={setisLoading}
                            showToasterSuccess={showToasterSuccess}
                            showToasterError={showToasterError}
                          />
                        )}
                        {activetab === tabNames.HOUSING_MANAGEMENT && (
                          <HouseTypeSettings
                            isLoading={isLoading}
                            setisLoading={setisLoading}
                            showToasterSuccess={showToasterSuccess}
                            showToasterError={showToasterError}
                          />
                        )}
                        {activetab === tabNames.IMMOZY_QUALIFY && (
                          <ImmozyqualifySettings
                            isLoading={isLoading}
                            setisLoading={setisLoading}
                            showToasterSuccess={showToasterSuccess}
                            showToasterError={showToasterError}
                          />
                        )}
                        {activetab === tabNames.PINCODE_SETTINGS && (
                          <PincodeSettings
                            isLoading={isLoading}
                            setisLoading={setisLoading}
                            showToasterSuccess={showToasterSuccess}
                            showToasterError={showToasterError}
                          />
                        )}
                        <ToastContainer
                          position="top-right"
                          autoClose={3000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
