import React from "react";
import { Route } from "react-router-dom";

// eslint-disable-next-line react/prop-types
const PublicRoute = ({ component: Component, ...rest }) => (
  // restricted = false meaning public route
  // restricted = true meaning restricted route
  <Route
    {...rest}
    render={(props) => (
      // isLogin() && restricted ?
      // <Redirect to="/" /> :
      <Component {...props} />
    )}
  />
);

export default PublicRoute;
