import React from "react";
import { Search } from "react-bootstrap-table2-toolkit";
import moment from "moment";

export const { SearchBar } = Search;

const sizePerPageList = [
  {
    text: "10",
    value: 10,
  },
  {
    text: "20",
    value: 20,
  },
  {
    text: "50",
    value: 50,
  },
  {
    text: "100",
    value: 100,
  },
];

export const defaultSorted = [
  {
    dataField: "id",
    order: "desc",
  },
];

export const customTotal = (from, to, size) =>
  size > 0 && (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

export const tableOptions = {
  // pageStartIndex: 0,
  // hideSizePerPage: true,
  sizePerPageList: sizePerPageList,
  alwaysShowAllBtns: true,
  prePageText: "Previous",
  nextPageText: "Next",
  withFirstAndLast: true,
  showTotal: true,
  paginationTotalRenderer: customTotal,
};

export const sortDates = (a, b, order) => {
  if (order === "desc") {
    return moment(a, "YYYY/MM/DD") - moment(b, "YYYY/MM/DD");
  }
  return moment(b, "YYYY/MM/DD") - moment(a, "YYYY/MM/DD");
};

export const sorting = (a, b, order) => {
  let aLower = a.toLowerCase();
  let bLower = b.toLowerCase();
  if (order === "asc") {
    if (aLower < bLower) {
      return -1;
    }
    if (aLower > bLower) {
      return 1;
    }
    return 0;
  }
  if (order === "desc") {
    if (aLower > bLower) {
      return -1;
    }
    if (aLower < bLower) {
      return 1;
    }
    return 0;
  }
};

export const getDate = (dateString) => moment(dateString).format("YYYY-MM-DD");

export const getDateWithTime = (dateString) =>
  moment(dateString).format("YYYY-MM-DD HH:mm:ss");

export const getUTCDate = (dateString) =>
  moment.utc(dateString).format("YYYY-MM-DD");

export const compareDate = (createdAt, updatedAt) => {
  const a = new Date(createdAt);
  const b = new Date(updatedAt);
  if (a.getTime() === b.getTime()) {
    return "-";
  }
  return getDateWithTime(updatedAt);
};

export const customSort = (order) => {
  if (!order) return <span>&nbsp;&nbsp;↑↓</span>;
  else if (!order || order === "asc")
    return (
      <span>
        &nbsp;&nbsp;↑
        <font color="red">↓</font>
      </span>
    );
  else if (order === "desc")
    return (
      <span>
        &nbsp;&nbsp;
        <font color="red">↑</font>↓
      </span>
    );
  return null;
};
