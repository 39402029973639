import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import EmailEditor from "react-email-editor";
import { callRequestAPI } from "../../services/callApi";
import backArrow from "../../assets/images/whitearrow.png";

let id = "new";

export default function MailTemplateEditor() {
  const emailEditorRef = useRef(null);
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [sender, setsender] = useState("");
  const [senderlabel, setsenderlabel] = useState("Immozy");
  const [subject, setsubject] = useState("");
  const [title, setTitle] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [showMessage, setshowMessage] = useState("");
  const [showTestSection, setShowTestSection] = useState(false);

  useEffect(() => {
    id = window.location.pathname
      ? window.location.pathname.replace("/mail-template/", "")
      : "new";
  });

  const saveData = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;

      let position = Number(html.search("font-family:"));
      let fontFamilyString = html.substring(
        position,
        html.indexOf(";", position)
      );

      const newHtml = html.replace(
        `<title></title>`,
        `<link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet">
      `
      );
      const changedFont = newHtml.replaceAll(
        fontFamilyString,
        `font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif`
      );

      if (id === "new") {
        addTemplate({
          title: title,
          data: Buffer.from(changedFont, "utf-8").toString("base64"),
          rawdata: design,
          sender_email: sender,
          subject: subject,
          senderlabel:
            senderlabel && senderlabel !== "" ? senderlabel : "Immozy",
        });
      } else {
        sendTemplate({
          title: title,
          data: Buffer.from(changedFont, "utf-8").toString("base64"),
          rawdata: design,
          sender_email: sender,
          subject: subject,
          senderlabel:
            senderlabel && senderlabel !== "" ? senderlabel : "Immozy",
        });
      }
    });
  };

  const sendTemplate = async (data) => {
    setisLoading(true);
    const response = await callRequestAPI(
      `v1/mailtemplates/${id}`,
      data,
      "putHeader"
    );

    if (response.status === 200 && response.data) {
      setisLoading(false);
    } else {
      setisLoading(false);
    }
  };

  const addTemplate = async (data) => {
    setisLoading(true);
    const response = await callRequestAPI(
      `v1/mailtemplates/addmailtemplate`,
      data,
      "postHeader"
    );

    if (response.status === 201 && response.data) {
      setisLoading(false);
      id = response.data.data.id;
      history.push(`/mail-template/${id}`);
    } else {
      setisLoading(false);
      window.alert(
        response.message ||
        "Not able to save the template. Something went wrong. "
      );
    }
  };

  const getTemplateList = async () => {
    setisLoading(true);

    const response = await callRequestAPI(
      `v1/mailtemplates/${id}`,
      null,
      "get"
    );

    if (response.status === 200 && response.data) {
      setisLoading(false);
      const data = response.data.data;
      setsender(data.sender_email || "");
      setsubject(data.subject || "");
      setTitle(data.title || "");
      setsenderlabel(data.senderlable || "Immozy");
      console.log("data.rawdata", data.data);
      return data.rawdata;
    } else {
      setisLoading(false);
      return null;
    }
  };

  const sendTestMail = async () => {
    setisLoading(true);
    const response = await callRequestAPI(
      `v1/mailtemplates/sendtestmail/${id}/${email}`,
      null,
      "putHeader"
    );

    if (response.status === 200 && response.data) {
      setEmail("");
      setisLoading(false);
      setshowMessage("Mail has been sent successfully");
      setTimeout(() => {
        setshowMessage("");
      }, 5000);
    } else {
      setisLoading(false);
      setshowMessage(
        "Something went wrong. Please check your mail address or try again later."
      );
    }
  };

  const onReady = async () => {
    if (id !== "new") {
      emailEditorRef.current.editor.loadDesign(await getTemplateList());
    }
  };

  return (
    <div className="pcoded-content fade-in">
      <div className="pcoded-main-container" style={{ marginTop: "none" }}>
        <div className="pcoded-wrapper">
          <div className="page-header card" style={{ border: "none" }}>
            <div className="row align-items-end">
              <div className="col-lg-5">
                <div className="page-header-title">
                  <Link to="/mail-template">
                    <img src={backArrow} className="back-arrow" alt="img"></img>
                  </Link>
                  <div>
                    <h5
                      className="header-style"
                      style={{ display: "inline-block" }}
                    >
                      Email Template
                    </h5>
                  </div>
                  {isLoading && (
                    <i
                      className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                      title="Loading data..."
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-3"></div>
              <div className="col-lg-4 d-flex justify-content-end">
                <button
                  className="btn btn-primary btn-sm  mr-3"
                  onClick={() => setShowTestSection(true)}
                  disabled={id === "new"}
                  style={{ opacity: id === "new" ? "0.5" : "1" }}
                >
                  Open Test Mail Sender
                </button>
                {id !== "new" && (
                  <Link
                    className="btn btn-primary btn-sm"
                    to={{
                      pathname: `/mail-users/${id}`,
                      state: {
                        templateName: title,
                        sender,
                        subject,
                        senderlabel,
                      },
                    }}
                  >
                    Go to Send Newsletter Page
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-body" style={{ marginBottom: "0" }}>
                {showTestSection && (
                  <div className="card">
                    <div className="card-header-right cusdetail">
                      <div className="text-right">
                        <span
                          onClick={() => setShowTestSection(false)}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-times"></i>
                        </span>
                      </div>
                    </div>
                    <div className="card-block">
                      <div className="row">
                        <div className="col-xs-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                          {/* <h4>Test Mail Template</h4> */}
                          <span className="d-flex justify-content-start align-items-center mt-4">
                            {/* <label style={{ margin: '0' }} className="mr-3">Email Address</label> */}
                            <input
                              className="form-control mr-2"
                              style={{ maxWidth: "400px" }}
                              placeholder="Email"
                              onChange={({ target }) => setEmail(target.value)}
                              type="text"
                              value={email}
                            />
                            <button
                              className="btn btn-primary btn-sm"
                              disabled={email === ""}
                              onClick={sendTestMail}
                            >
                              Send Test Mail
                            </button>
                          </span>

                          <p className="mt-3" style={{ fontStyle: "italic" }}>
                            Note: Please save the template changes before
                            sending test mail. The last saved template will be
                            sent in mail.{" "}
                          </p>
                          {showMessage !== "" && (
                            <p
                              className="mt-2 mb-2"
                              style={{
                                fontSize: "13px",
                                color: showMessage.includes("success")
                                  ? "green"
                                  : "red",
                              }}
                            >
                              {showMessage}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="card">
                  <div className="card-block">
                    <div className="row">
                      <div className="w-100">
                        <div>
                          <div>
                            <div className="col-xs-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                              <div className="d-flex align-items-center justify-content-between">
                                <h4>E-Mail Details</h4>
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={saveData}
                                >
                                  Save Changes
                                </button>
                              </div>

                              <div className="mt-4 mb-4">
                                <div className="d-flex align-items-center mt-2 mb-2">
                                  <label
                                    className="mb-0 mr-2"
                                    style={{
                                      fontWeight: "500",
                                      width: "130px",
                                    }}
                                  >
                                    Sender:{" "}
                                  </label>
                                  <input
                                    className="form-control mr-2"
                                    style={{ maxWidth: "400px" }}
                                    placeholder="sender email address of immozy domain"
                                    onChange={({ target }) =>
                                      setsender(target.value)
                                    }
                                    type="text"
                                    value={sender}
                                  />
                                  <label
                                    className="mb-0 mr-2 ml-3"
                                    style={{
                                      fontWeight: "500",
                                      width: "130px",
                                    }}
                                  >
                                    Sender Label:{" "}
                                  </label>
                                  <input
                                    className="form-control mr-2"
                                    style={{ maxWidth: "400px" }}
                                    placeholder="sender label that will appear on mail"
                                    onChange={({ target }) =>
                                      setsenderlabel(target.value)
                                    }
                                    type="text"
                                    value={senderlabel}
                                  />
                                </div>
                                <div className="d-flex align-items-center  mt-2 mb-2">
                                  <label
                                    className="mb-0 mr-2"
                                    style={{
                                      fontWeight: "500",
                                      width: "130px",
                                    }}
                                  >
                                    Subject:{" "}
                                  </label>
                                  <input
                                    className="form-control mr-4"
                                    style={{ maxWidth: "400px" }}
                                    placeholder="subject line for email"
                                    onChange={({ target }) =>
                                      setsubject(target.value)
                                    }
                                    type="text"
                                    value={subject}
                                  />
                                  <label
                                    className="mb-0 mr-2"
                                    style={{
                                      fontWeight: "500",
                                      width: "130px",
                                    }}
                                  >
                                    Template Name:{" "}
                                  </label>
                                  <input
                                    className="form-control mr-2"
                                    style={{ maxWidth: "400px" }}
                                    placeholder="Name of template"
                                    onChange={({ target }) =>
                                      setTitle(target.value)
                                    }
                                    type="text"
                                    value={title}
                                  />
                                </div>
                              </div>
                              <div className="d-flex align-items-center"></div>
                            </div>
                            <EmailEditor
                              style={{ height: "calc(100vh - 250px)" }}
                              ref={emailEditorRef}
                              // onLoad={onLoad}
                              onReady={onReady}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
