import React from "react";
import PropTypes from "prop-types";
import Modal from "react-awesome-modal";
import ObjectDetails from "./objectDetails";

function LargeModalComponent(props) {
  return (
    <div className="large-modal">
      <Modal
        visible={true}
        effect="fadeInUp"
        onClickAway={() => props.closeModal()}
        width="90%"
      >
        <ObjectDetails
          objectId={props.objectId}
          closeModal={props.closeModal}
        />
      </Modal>
    </div>
  );
}

LargeModalComponent.propTypes = {
  objectId: PropTypes.string,
  closeModal: PropTypes.func,
  onFavoChanged: PropTypes.func,
};

export default LargeModalComponent;
