import React, { Component } from "react";
import PropTypes from "prop-types";
import { forgetPassword } from "../../actions/authActions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./auth.css";

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      isError: false,
      errorMessage: "",
      showSpinner: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { forgetPasswordReducer } = this.props;
    if (
      prevProps.forgetPasswordReducer !== forgetPasswordReducer &&
      forgetPasswordReducer.status === "success"
    ) {
      alert("An email has been sent to reset your password.");
      this.setState({ showSpinner: false });
    }

    if (
      prevProps.forgetPasswordReducer !== forgetPasswordReducer &&
      forgetPasswordReducer.status === "fail" &&
      forgetPasswordReducer.data
    ) {
      const msg =
        forgetPasswordReducer.data.data &&
        forgetPasswordReducer.data.data.message
          ? forgetPasswordReducer.data.data.message
          : "Some issue has occured in sending mail";
      this.setState({
        errorMessage: msg,
        isError: true,
        showSpinner: false,
      });
    }
  }

  handleEmail = (event) => {
    this.setState({ email: event.target.value });
    this.setState({ isError: false, errorMessage: "" });
  };

  handleForgotPassword = () => {
    const { forgetPassword } = this.props;
    const { email } = this.state;
    localStorage.setItem("userName", email);
    if (email.trim() === "") {
      this.setState({ errorMessage: "Please enter email id", isError: true });
    } else {
      forgetPassword(email);
      this.setState({ showSpinner: true });
    }
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.handleForgotPassword();
    }
  };

  render() {
    const { email, isError, errorMessage, showSpinner } = this.state;
    return (
      <div>
        <section className="login-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <form className="md-float-material form-material">
                  <div className="auth-box card">
                    <div className="card-block">
                      <div className="row">
                        <div className="col-md-12">
                          <span className="row ml-2 mb-1 d-block">
                            <p
                              className="p-b-5 p-t-5"
                              style={{ fontSize: "19px" }}
                            >
                              Enter your registered email address to reset
                              password
                              {showSpinner && (
                                <i
                                  className="ml-2 fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                                  title="Loading data..."
                                />
                              )}
                            </p>
                          </span>
                        </div>
                      </div>

                      <div className="form-group form-primary">
                        <input
                          type="text"
                          name="email-address"
                          className="form-control .input-login-style"
                          required=""
                          value={email}
                          onChange={this.handleEmail}
                          placeholder="Your Email Address"
                          onKeyDown={this.onKeyDown}
                        />
                        {isError && (
                          <div className="col-sm-12">
                            <label className="error-msg-label">
                              {errorMessage}
                            </label>
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <button
                            type="button"
                            className="btn btn-primary btn-md btn-block waves-effect text-center m-b-20"
                            onClick={() => this.handleForgotPassword()}
                          >
                            Send Email
                          </button>
                        </div>
                      </div>
                      <p className="f-w-600 text-right">
                        Back to <Link to="/login">Login.</Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  forgetPasswordReducer: PropTypes.object,
  forgetPassword: PropTypes.func,
};

const mapStateToProps = (state) => ({
  forgetPasswordReducer: state.forgetPasswordReducer,
});

const mapDispatchToProps = (dispatch) => ({
  forgetPassword: (email) => {
    dispatch(forgetPassword(email));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
