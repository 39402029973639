import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-awesome-modal";
import { callRequestAPI } from "../../services/callApi";

function EditRatesPopup(props) {
    const [ratesData, setratesData] = useState([]);

    useEffect(
        () => {
            getDetails();
        },
        // eslint-disable-next-line
        [props.id]
    );

    const getDetails = async () => {
        const response = await callRequestAPI(
            `v1/bankrates/${props.id}`,
            null,
            "get"
        );

        if (response.status === 200 && response.data) {
            const rates = response.data.data;
            let rateArray = [];
            for (let [key, value] of Object.entries(rates)) {
                if (key.includes("ltv")) {
                    rateArray.push({ title: key, value: value });
                    console.log(`${key}: ${value}`);
                }
            }
            setratesData(rateArray);
        }
    };

    const handleOnChange = (event) => {
        const value =
            event.target.value !== ""
                ? parseFloat(event.target.value)
                : event.target.value;

        setratesData(
            ratesData.map((item) =>
                item.title === event.target.name ? { ...item, value } : item
            )
        );
    };

    const handleUpdate = (rates) => {
        const updatedObj = {
            ltv_0_4: rates[0].value,
            ltv_5_9: rates[1].value,
            ltv_10_14: rates[2].value,
            ltv_15_19: rates[3].value,
            ltv_20_24: rates[4].value,
            ltv_25_29: rates[5].value,
            ltv_30_34: rates[6].value,
            ltv_35_39: rates[7].value,
            ltv_40_or_plus: rates[8].value,
        };
        props.updateRates(updatedObj);
    };

    return (
        <div className="edit-rates-modal">
            <Modal
                visible={true}
                effect="fadeInUp"
                onClickAway={() => props.closeModal()}
                width="500px"
            >
                <div className="rate-popup-container">
                    <p className="rate-title">
                        Edit Rates for {props.title.replace("_", " ")}
                    </p>
                    {ratesData && ratesData.length > 0 && (
                        <>
                            {ratesData.map((rateObj, index) => (
                                <div
                                    key={index}
                                    className="d-flex align-items-center rate-input-div"
                                >
                                    <div className="rate-label">
                                        {rateObj.title.replaceAll("_", " ")}
                                    </div>
                                    <input
                                        value={rateObj.value}
                                        type="number"
                                        onChange={handleOnChange}
                                        name={rateObj.title}
                                    />
                                </div>
                            ))}
                            <div className="d-flex place-content-center mt-4 rate-btn">
                                <button
                                    className="btn btn-primary btn-sm mr-3"
                                    onClick={() => handleUpdate(ratesData)}
                                >
                                    Save Rates
                                </button>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => props.closeModal()}
                                >
                                    Close
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        </div>
    );
}

EditRatesPopup.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    closeModal: PropTypes.func,
    updateRates: PropTypes.func,
};

export default EditRatesPopup;
