import React from "react";
import PropTypes from "prop-types";
import { getUTCDate } from "../../utils/tableData";
import { Link } from "react-router-dom";
import user from "../../assets/images/user-icon.png";

function UserHeader(props) {
  const { userDetails, showEditUser, toggleEditMode, toggleModal } = props;

  return (
    <div className="page-wrapper">
      <div className="card">
        <div className="card-header-right cusdetail">
          <div className="text-right">
            <span
              style={{
                cursor: "pointer",
                color: "#932849",
                textDecoration: "none",
              }}
              title="Activate/Deactivate user"
              onClick={() => toggleEditMode("showEditUser")}
            >
              <i className="feather icon-edit-2">
                <label className="ml-1">Edit Status</label>
              </i>
            </span>
          </div>
        </div>
        <div className="card-block">
          {userDetails && (
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-2 customerdetailcard">
                <img
                  src={userDetails.avatar || user}
                  alt="User"
                  style={{
                    height: "104px",
                    maxWidth: "155px",
                  }}
                  // onError={(e) => (e.target.src = user)}
                />
              </div>
              <div className="col-sm-12 col-md-5 customerdetailcard">
                <div>
                  <label>Full Name</label>
                  <span>
                    {(userDetails.firstName || "N/a") +
                      " " +
                      (userDetails.lastName || " ")}
                  </span>
                </div>
                <div>
                  <label>Email</label>
                  <span>{userDetails.email}</span>
                </div>
              </div>
              <div className="col-sm-12 col-md-5 customerdetailcard">
                <div>
                  <label>Registration Date</label>
                  <span>{getUTCDate(userDetails.createdDate)}</span>
                </div>
                <div>
                  <label>Onboarding status</label>
                  <span>
                    {userDetails.isOnBoardingCompleted
                      ? "Completed"
                      : "Pending"}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showEditUser && (
        <div className="card">
          <div className="card-header">
            <h5>Edit User Status</h5>
            <div className="margin-20"></div>
            <div>
              <Link
                className="btn btn-primary btn-sm"
                style={{
                  minWidth: "100px",
                  marginTop: "-7px",
                }}
                onClick={() => toggleModal(true)}
              >
                {userDetails.status === "ACTIVE"
                  ? "Deactivate User"
                  : "Acivate User"}
              </Link>
              <Link
                className="btn btn-primary btn-sm"
                onClick={() => toggleEditMode("showEditUser")}
                style={{
                  minWidth: "100px",
                  marginLeft: "15px",
                  marginTop: "-7px",
                }}
              >
                Cancel
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

UserHeader.propTypes = {
  userDetails: PropTypes.object,
  showEditUser: PropTypes.bool,
  toggleEditMode: PropTypes.func,
  toggleModal: PropTypes.func,
};

export default UserHeader;
