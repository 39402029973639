import React, { useEffect, useState } from "react";
import { callRequestAPI } from "../../services/callApi";
import { Link, useHistory, useLocation } from "react-router-dom";
import backArrow from "../../assets/images/whitearrow.png";
import { getDateWithTime } from "../../utils/tableData";
import ModalComponent from "../../utils/modalComponent";
import { BASE_URL } from "../../services/baseUrl";
import {
  realtorMenuItems,
  realtorDetaifields,
  dataSourceFilter,
  partnerFilter,
  realtorMatchFields,
} from "./realtorUtils";
import RealtorStats from "./realtorStats";
import moment from "moment";
import { realtorStatsTabsOperationMapper } from "../../utils/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let id = "";

const tabNames = {
  REALTOR_DETAILS: "Details",
  REALTOR_LIST_STATS: "Property list stats",
  REALTOR_CLICK_STATS: "Property click stats",
  REALTOR_INTEREST_STATS: "Property Interest stats",
};

const tabs = [
  tabNames.REALTOR_DETAILS,
  tabNames.REALTOR_LIST_STATS,
  tabNames.REALTOR_CLICK_STATS,
  tabNames.REALTOR_INTEREST_STATS,
];

const initialData = {
  provider_company_name: "",
  contact_person_name: "",
  street: "",
  count: "0",
  agent_id: "",
  postal_code: "",
  adr_city: "",
  match_email_address: "",
  phone: "",
  website_url: "",
  privacy_link: "",
  imprint: "",
  domain: "",
  active: false,
  data_source: dataSourceFilter[0].value,
  partner_level: partnerFilter[0].value,
};

function RealtorDetails(props) {
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);

  const [isLoading, setisLoading] = useState(true);
  const [realtorInfo, setrealtorInfo] = useState({ ...initialData });
  const [showModal, setshowModal] = useState(false);

  const [activetab, setactiveTab] = useState(tabNames.REALTOR_DETAILS);

  const location = useLocation();
  const history = useHistory();

  useEffect(
    () => {
      if (isLoading) {
        // if (location.state && location.state.params && location.state.params.id) {
        //   id = location.state.params;
        //   getDetails();
        // } else
        if (
          window.location.pathname &&
          !window.location.pathname.includes("new")
        ) {
          id = window.location.pathname.replace("/realtor/", "");
          getDetails();
        } else {
          if (location.state && location.state.data) {
            Object.keys(location.state.data).forEach((key) => {
              setrealtorInfo((prevState) => ({
                ...prevState,
                [key === "count" ? "total_offer" : key]:
                  location.state.data[key],
              }));
            });
            setrealtorInfo((prevState) => ({
              ...prevState,
              data_source: dataSourceFilter[1].value,
            }));
          }
          setisLoading(false);
          id = "";
        }
      }
    },
    // eslint-disable-next-line
    [props.id]
  );

  const showToasterSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showToasterError = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getDetails = async (params = null) => {
    setisLoading(true);
    const response = await callRequestAPI(
      `v1/realtors/realtors_new/${id}`,
      params,
      "get"
    );
    if (response.status === 200 && response.data && response.data.data) {
      setrealtorInfo(response.data.data);
      setisLoading(false);
    } else {
      setisLoading(false);
    }
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setrealtorInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    setisLoading(true);

    let payload = {
      agent_id: realtorInfo.agent_id,
      provider_company_name: realtorInfo.provider_company_name,
      contact_person_name: realtorInfo.contact_person_name,
      street: realtorInfo.street,
      postal_code: realtorInfo.postal_code,
      email_address: realtorInfo.email_address,
      match_email_address: realtorInfo.match_email_address,
      phone: realtorInfo.phone,
      realtor_city: realtorInfo.realtor_city,
      website_url: realtorInfo.website_url,
      privacy_link: realtorInfo.privacy_link,
      imprint: realtorInfo.imprint,
      active: realtorInfo.active || false,
      data_source: realtorInfo.data_source,
      partner_level: realtorInfo.partner_level,
      domain: realtorInfo.domain,
    };

    if (checkValidation()) {
      showToasterError(
        "Please fill all the required fields to save realtor details."
      );
      setisLoading(false);
    } else {
      if (id === "") {
        // add Realtor
        payload.active = true;
        addImmozyRealtor(payload);
      } else {
        // update Realtor
        updateRealtorDetails(payload);
      }
    }
  };

  const checkValidation = () => {
    let isError = false;
    const checkvaliationArray = [
      "provider_company_name",
      "contact_person_name",
      "email_address",
      "street",
      "postal_code",
      "realtor_city",
      "phone",
      "website_url",
      "privacy_link",
      "imprint",
    ];
    checkvaliationArray.forEach((field) => {
      if (!realtorInfo[field] || realtorInfo[field] === "") {
        isError = true;
        console.log(field);
      }
    });
    return isError;
  };

  const addImmozyRealtor = async (payload) => {
    const response = await callRequestAPI(
      "v1/realtors/realtors_new",
      payload,
      "postHeader"
    );

    if (response.status === 201 && response.data) {
      history.push(`/realtor/${response.data.data.id}`, {
        params: response.data.data.id,
      });
      showToasterSuccess(`Realtor added successfully.`);
      setTimeout(() => {
        window.location.reload();
      }, [1000]);
    } else {
      setisLoading(false);
      showToasterError(response.message || "Something went wrong.");
    }
  };

  const updateRealtorDetails = async (payload) => {
    const response = await callRequestAPI(
      `v1/realtors/realtors_new/${id}`,
      payload,
      "putHeader"
    );
    if (response.status === 200 && response.data) {
      getDetails();
      showToasterSuccess(`Realtor updated successfully.`);
    } else {
      setisLoading(false);
      showToasterError(response.message || "Something went wrong.");
    }
  };

  const toggleModal = () => {
    if (realtorInfo.active) {
      setshowModal(true);
    } else {
      handleRealtorStatusChange(id, realtorInfo.active);
    }
  };

  const handleRealtorStatusChange = async (id, active) => {
    setisLoading(true);
    const response = await callRequestAPI(
      `v1/realtors/realtors_new/${id}/${!active}`,
      {},
      "putHeader"
    );

    if (response.status === 200 && response.data) {
      getDetails();
      showToasterSuccess("Status updated successfully.");
    } else {
      setisLoading(false);
    }
  };

  const handleDateChange = (startDate, endDate) => {
    setstartDate(startDate);
    setendDate(endDate);
  };

  const handleTabChange = (tab) => {
    setactiveTab(tab);
    const param = {
      datefilterApplyOn: realtorStatsTabsOperationMapper[tab],
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: moment(endDate).format("YYYY-MM-DD"),
    };
    getDetails(param);
  };

  return (
    <div className="pcoded-content fade-in">
      <div className="pcoded-main-container" style={{ marginTop: "none" }}>
        <div className="pcoded-wrapper">
          <div className="page-header card" style={{ border: "none" }}>
            <div className="row align-items-end">
              <div className="col-lg-5">
                <div className="page-header-title">
                  <Link to="/realtor">
                    <img src={backArrow} className="back-arrow" alt="img"></img>
                  </Link>
                  <div>
                    <h5
                      className="header-style"
                      style={{ display: "inline-block" }}
                    >
                      Realtor Details
                    </h5>
                  </div>

                  {isLoading ? (
                    <i
                      className="fa fa-cog fa-spin fa-3x fa-fw spinner-gear"
                      title="Loading data..."
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {id !== "" && (
                <div className="col-lg-7 text-right">
                  {BASE_URL.includes("app") && (
                    <button
                      className="copy-btn"
                      onClick={() => `https://app.immozy.de/realtor/${id}`}
                      title={"copy link"}
                    >
                      <i className="fa fa-copy mr-3 pointer"></i>
                    </button>
                  )}

                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/realtor-mail/${id}`}
                    className="page-header-breadcrumb"
                  >
                    <button
                      className="btn btn-primary mr-3"
                      style={{ color: "#fff" }}
                    >
                      Mail log
                    </button>
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      BASE_URL.includes("staging")
                        ? `http://staging.immozy.de/realtor/${id}`
                        : `https://app.immozy.de/realtor/${id}`
                    }
                    className="page-header-breadcrumb"
                    style={{ color: "#fff" }}
                    title="See how active properties will look on user portal"
                  >
                    <button
                      className="btn btn-primary"
                      style={{ color: "#fff" }}
                    >
                      Realtor Portal link
                    </button>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-body">
                <div className="card">
                  <div className="card-block">
                    <div className="nav-tabs-wrapper d-flex flex-column flex-md-row justify-content-between align-items-md-center align-items-start mb-4">
                      <ul
                        className="nav nav-tabs order-1 order-md-0"
                        id="myTab"
                        role="tablist"
                      >
                        {tabs.map((tab, index) => (
                          <li className="nav-item" key={index}>
                            <span
                              className={
                                activetab === tab
                                  ? "nav-link cursor-pointer active"
                                  : "nav-link cursor-pointer"
                              }
                              id="home-tab"
                              role="tab"
                              name={tab}
                              onClick={() => handleTabChange(tab)}
                            >
                              {tab}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="mt-2 tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        {activetab === tabNames.REALTOR_DETAILS && (
                          <>
                            <div className="row">
                              <div className="col-xs-12 col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                {id !== "" && (
                                  <div className="d-flex align-items-center mb-3">
                                    <p className="realtor-data-label mb-0">
                                      Active:
                                    </p>

                                    <span className="waves-effect waves-dark pcoded-micon">
                                      <span className="mr-2 ml-3">
                                        {realtorInfo.active ? "Yes" : "No"}
                                      </span>
                                      <i
                                        style={{
                                          fontSize: "22px",
                                          marginRight: "5px",
                                          cursor: isLoading
                                            ? "not-allowed"
                                            : "pointer",
                                        }}
                                        className={
                                          realtorInfo.active
                                            ? "fa fa-toggle-on"
                                            : "fa fa-toggle-off"
                                        }
                                        onClick={() => toggleModal()}
                                        title={
                                          !realtorInfo.active
                                            ? "Enable Realtor "
                                            : "Disable Realtor"
                                        }
                                      ></i>
                                    </span>
                                  </div>
                                )}
                                {/* <div className="d-flex align-items-center mb-3">
                                  <p className="realtor-data-label mb-0">
                                    Contact Details Added:
                                  </p>
                                  <label
                                    className="mr-2 ml-3 realtor-input-box"
                                    style={{
                                      border: `1px solid ${
                                        realtorInfo.is_info_complited
                                          ? "green"
                                          : "red"
                                      }`,
                                      background: realtorInfo.is_info_complited
                                        ? "rgba(51, 170, 51, .7)"
                                        : "#d88888",
                                      color: "#fff",
                                    }}
                                  >
                                    {realtorInfo.is_info_complited
                                      ? "Yes"
                                      : "No"}
                                  </label>
                                </div> */}
                                {realtorMatchFields.map((dataField, index) => (
                                  <div
                                    className="d-flex align-items-center mt-2 mb-2"
                                    key={index}
                                  >
                                    <p className="realtor-data-label">
                                      {dataField.label}:
                                    </p>
                                    <input
                                      className="mb-0 ml-3 realtor-input-box"
                                      name={dataField.name}
                                      value={
                                        dataField.name.includes("date")
                                          ? id !== ""
                                            ? getDateWithTime(
                                                realtorInfo[dataField.name]
                                              )
                                            : ""
                                          : realtorInfo[dataField.name] || ""
                                      }
                                      onChange={handleOnChange}
                                      placeholder="Not Added"
                                      disabled={dataField.disable}
                                    />
                                    {index === 6 && <hr />}
                                  </div>
                                ))}
                                {realtorMenuItems.map((dataField, index) => (
                                  <div
                                    className="d-flex align-items-center mt-2 mb-2"
                                    key={index}
                                  >
                                    <p className="realtor-data-label">
                                      {dataField.label}:
                                    </p>
                                    <select
                                      className="mb-0 ml-3 realtor-input-box"
                                      value={realtorInfo[dataField.name]}
                                      name={dataField.name}
                                      onChange={handleOnChange}
                                    >
                                      {dataField.options.map((op, i) => (
                                        <option
                                          key={i}
                                          // selected={
                                          //   op.value ===
                                          //   realtorInfo[dataField.name]
                                          // }
                                          value={op.value}
                                        >
                                          {op.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                ))}
                              </div>
                              <div className="col-xs-12 col-md-12 col-sm-12 col-lg-12 col-xl-5">
                                {realtorDetaifields.map((dataField, index) => (
                                  <div
                                    className="d-flex align-items-center mt-2 mb-2"
                                    key={index}
                                  >
                                    <p className="realtor-data-label">
                                      {dataField.label}:
                                    </p>
                                    <input
                                      className="mb-0 ml-3 realtor-input-box"
                                      name={dataField.name}
                                      value={
                                        dataField.name.includes("date")
                                          ? id !== ""
                                            ? getDateWithTime(
                                                realtorInfo[dataField.name]
                                              )
                                            : ""
                                          : realtorInfo[dataField.name] || ""
                                      }
                                      onChange={handleOnChange}
                                      placeholder="Not Added"
                                      disabled={dataField.disable}
                                    />
                                    {index === 6 && <hr />}
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="mt-3">
                              <button
                                type="button"
                                className="btn btn-primary mr-2 ml-2"
                                onClick={handleSubmit}
                                style={{ height: "42px" }}
                              >
                                {id === ""
                                  ? "Add as Immozy Realtor"
                                  : "Update Changes"}
                              </button>
                              {id !== "" &&
                              realtorInfo.total_offer &&
                              parseInt(realtorInfo.total_offer) !== 0 ? (
                                <Link
                                  to={`/objects?realtor=${realtorInfo.id}`}
                                  className="btn btn-primary mr-4"
                                  target="_blank"
                                  style={{ height: "42px" }}
                                >
                                  Go to Realtor's properties
                                </Link>
                              ) : (
                                <span>{""}</span>
                              )}
                            </div>
                          </>
                        )}
                        {activetab === tabNames.REALTOR_LIST_STATS && (
                          <RealtorStats
                            startDate={startDate}
                            endDate={endDate}
                            handleDateChange={handleDateChange}
                            listLogs={realtorInfo.listLogs}
                            activetab={activetab}
                            isLoading={isLoading}
                            handleSearch={getDetails}
                          />
                        )}
                        {activetab === tabNames.REALTOR_CLICK_STATS && (
                          <RealtorStats
                            startDate={startDate}
                            endDate={endDate}
                            handleDateChange={handleDateChange}
                            listLogs={realtorInfo.detailsLogs}
                            userIdAwailable
                            activetab={activetab}
                            isLoading={isLoading}
                            handleSearch={getDetails}
                          />
                        )}
                        {activetab === tabNames.REALTOR_INTEREST_STATS && (
                          <RealtorStats
                            startDate={startDate}
                            endDate={endDate}
                            handleDateChange={handleDateChange}
                            listLogs={realtorInfo.interestLogs}
                            userIdAwailable
                            activetab={activetab}
                            isLoading={isLoading}
                            handleSearch={getDetails}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>

      {showModal && (
        <ModalComponent
          title={"Confirmation"}
          bodyMsg={"Are you sure you want to disable this realtor?"}
          closeModal={() => setshowModal(false)}
          handleYes={() => {
            setshowModal(false);
            handleRealtorStatusChange(id, true);
          }}
          btnText="No"
        />
      )}
    </div>
  );
}

export default RealtorDetails;
