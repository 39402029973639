export const INITIAL_STATE = {
  response: [],
};

export const userListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_USER_LIST_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : null,
      };

    case "FETCH_USER_LIST_PENDING":
      return {
        status: "pending",
      };

    case "CLEAR_USER_LIST":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};

export const userDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_USER_DETAIL_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : null,
      };

    case "FETCH_USER_DETAIL_PENDING":
      return {
        status: "pending",
      };

    case "CLEAR_USER_DETAIL":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};

export const favObjectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_FAV_LIST_FULFILLED":
      return {
        status: "success",
        data: action.payload.data ? action.payload.data : [],
      };

    case "FETCH_FAV_LIST_PENDING":
      return {
        status: "pending",
      };

    default:
      return {
        ...state,
      };
  }
};

export const userAnswerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_USER_ANS_FULFILLED":
      return {
        status: "success",
        data: action.payload.data ? action.payload.data : [],
      };

    case "GET_USER_ANS_PENDING":
      return {
        status: "pending",
      };

    default:
      return {
        ...state,
      };
  }
};

export const userStatusReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "USER_STATUS_FULFILLED":
      return {
        status: "success",
        data: action.payload.data ? action.payload.data : [],
      };

    case "USER_STATUS_PENDING":
      return {
        status: "pending",
      };

    case "CLEAR_USER_STATUS":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};
